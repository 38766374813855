import * as React from 'react'

import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverArrow } from '@/shared/ui/Popover'
import { Tooltip, TooltipTrigger, TooltipContent, TooltipArrow } from '@/shared/ui/Tooltip'
import { cn } from '@/shared/utils/cn'
import { useMediaQuery } from '@/shared/hooks/useMediaQuery'

const HoverContext = React.createContext<boolean | undefined>(undefined)
const useHover = () => React.useContext(HoverContext)

export type HintRootProps = React.ComponentProps<typeof PopoverRoot> & React.ComponentProps<typeof Tooltip>

const HintRoot = ({ delayDuration = 150, ...props }: HintRootProps) => {
    const canHover = useMediaQuery('(hover: hover)')

    return (
        <HoverContext.Provider value={canHover}>
            {!canHover ? <PopoverRoot {...props} /> : <Tooltip delayDuration={delayDuration} {...props} />}
        </HoverContext.Provider>
    )
}

export type HintTriggerProps = React.ComponentProps<typeof PopoverTrigger> & React.ComponentProps<typeof TooltipTrigger>

const HintTrigger = (props: HintTriggerProps) => {
    const canHover = useHover()

    return !canHover ? <PopoverTrigger {...props} /> : <TooltipTrigger {...props} />
}

export type HintContentProps = React.ComponentProps<typeof PopoverContent> & React.ComponentProps<typeof TooltipContent>

const HintContent = ({ side, className, onOpenAutoFocus, ...props }: HintContentProps) => {
    const canHover = useHover()

    const actualClassName = cn('px-3 py-1.5 text-sm', className)

    return !canHover ? (
        <PopoverContent
            side={side ?? 'top'}
            className={actualClassName}
            onOpenAutoFocus={(e) => {
                e.preventDefault()
                onOpenAutoFocus?.(e)
            }}
            {...props}
        />
    ) : (
        <TooltipContent side={side ?? 'top'} className={actualClassName} {...props} />
    )
}

export type HintArrowProps = React.ComponentProps<typeof PopoverArrow> & React.ComponentProps<typeof TooltipArrow>

const HintArrow = (props: HintArrowProps) => {
    const canHover = useHover()

    return !canHover ? <PopoverArrow {...props} /> : <TooltipArrow {...props} />
}

export { HintRoot, HintTrigger, HintContent, HintArrow }
