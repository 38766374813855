import React, { useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import { ReactComponent as Ellipsis } from '@/assets/images/ellipsis.svg'
import { Badge, BadgeVariant } from '@/components/Badge'
import './WarehousesRow.scss'
import { ReactComponent as DownloadIcon } from '@/assets/images/download2.svg'
import { Hint } from '@/components/Hint'
import useOnclickOutside from 'react-cool-onclickoutside'
import dayjs from 'dayjs'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

interface WarehousesRowProps {
    name: string
    date?: string
    status?: string
    loading?: boolean
    disabled?: boolean
    deleteFile: (documentId: string) => void
    downloadFile: (documentId: string) => void
    id: string
    sfDocumentId: string
    expirationDate?: string
}

export default function WarehousesRow({
    name,
    date,
    status,
    disabled,
    loading,
    deleteFile,
    downloadFile,
    sfDocumentId,
    id,
    expirationDate,
}: WarehousesRowProps) {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [isDelete, setIsDelete] = useState(false)
    // function formatDate(dateStr: Date): string {
    //     const newDate = new Date(dateStr)
    //     let month = '' + (newDate.getMonth() + 1),
    //         day = '' + newDate.getDate(),
    //         year = newDate.getFullYear().toString()
    //     if (month.length < 2) month = '0' + month
    //     if (day.length < 2) day = '0' + day
    //     year = year.slice(-2)

    //     return [month, day, year].join('/')
    // }

    const setStatusColor = (param: any) => {
        if (param === 'Confirmed') {
            return {
                variant: BadgeVariant.green,
                status: 'Confirmed',
            }
        } else if (param === 'Pending') {
            return {
                variant: BadgeVariant.yellow,
                status: 'Pending',
            }
        } else if (param === 'Rejected') {
            return {
                variant: BadgeVariant.danger,
                status: 'Rejected',
            }
        } else if (param === 'Expired') {
            return {
                variant: BadgeVariant.danger,
                status: 'Expired',
            }
        }
    }

    const deleteRef = useOnclickOutside(() => {
        setIsDelete(false)
    })

    const analyticsProps = { name, sfDocumentId, status, expirationDate }

    const handleDelete = (fileId: string) => {
        setIsDelete(!isDelete)
        deleteFile(fileId)
        sendCustomEvent('file_deleted', analyticsProps)
    }
    const handleDownloadFile = (fileId: string) => {
        setIsDelete(!isDelete)
        downloadFile(fileId)
        sendCustomEvent('file_downloaded', analyticsProps)
    }

    return (
        <>
            {name ? (
                <>
                    <div className="warehouses-row">
                        <div>
                            <ul className="warehouses-row__list">
                                {name && (
                                    <Tooltip
                                        content={name}
                                        direction="left"
                                        distance={20}
                                        background="white"
                                        tipContentHover
                                        className="tooltip__box"
                                    >
                                        <li className="warehouses-row__item">{name}</li>
                                        {expirationDate && (
                                            <li className="warehouses-row__item font-light text-sm pt-1">
                                                Expire: {dayjs.utc(expirationDate).format('MM/DD/YYYY')}
                                            </li>
                                        )}

                                        {status && (
                                            <li className="warehouses-row__item">
                                                <Badge variant={setStatusColor(status)?.variant} className="mt-1">
                                                    {setStatusColor(status)?.status}
                                                </Badge>
                                            </li>
                                        )}
                                    </Tooltip>
                                )}
                            </ul>
                        </div>
                        {!loading && (
                            <div className="warehouses-row__dots">
                                <Ellipsis onClick={() => setIsDelete(!isDelete)} />
                                {isDelete && (
                                    <ul className={'warehouses__dots-menu-items'} ref={deleteRef}>
                                        <li
                                            className={'warehouses__dots-menu-item warehouses__dots-menu-item_download'}
                                            onClick={() => handleDownloadFile(sfDocumentId)}
                                        >
                                            Download <DownloadIcon className="warehouses__iconDownload" />
                                        </li>
                                        {!disabled && (
                                            <li
                                                className={
                                                    'warehouses__dots-menu-item warehouses__dots-menu-item_delete'
                                                }
                                                onClick={() => handleDelete(id)}
                                            >
                                                Delete
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                </>
            ) : null}
        </>
    )
}
