import React from 'react'
import Search from '@/components/Search'
import Button from '@/components/Button'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import useMobileScreen from '@/hooks/useMobileScreen'
import { showAddWarehouseModal } from '@/parts/Modals/AddWarehouse'
import { useUserState } from '@/store/user'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

type AllWarehousesHeaderProps = {
    search: string
    onSearch: (value: string) => void
    onWarehouseAdded: () => void
}

const AllWarehousesHeader = ({ search, onSearch, onWarehouseAdded }: AllWarehousesHeaderProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const { state } = useUserState()
    const isMobile = useMobileScreen({ screenWidth: 1140 })

    return (
        <div className="flex flex-col xl:flex-row gap-5">
            <h2 className="text-2xl shrink-0">All Warehouses</h2>
            <div className="flex gap-5 flex-grow">
                <Search
                    label="Search"
                    filterValue={search}
                    onSearch={(value) => onSearch(value)}
                    wrapperClassName="flex-grow [&>svg]:top-[10px]"
                    className="py-0 h-9"
                    onBlur={() =>
                        search &&
                        sendCustomEvent('admin_warehouses_search', {
                            searchQuery: search,
                        })
                    }
                />
                {state.userInfo!.admin && (
                    <Button
                        types={['bordered']}
                        className="shrink-0"
                        onClick={() => {
                            showAddWarehouseModal({
                                props: {
                                    onClose: onWarehouseAdded,
                                },
                            })

                            sendCustomEvent('add_warehouse_clicked')
                        }}
                    >
                        {!isMobile ? (
                            'Add Warehouse'
                        ) : (
                            <>
                                <FaPlus />
                                Add
                            </>
                        )}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default AllWarehousesHeader
