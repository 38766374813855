import { ServiceMethods } from '../index'
import { CrossDockingBreakdownComponent } from './BreakdownComponent'
import { CrossDockingFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/entities/rfq/model'

export const crossDockingService: ServiceMethods = {
    breakdownComponent: CrossDockingBreakdownComponent,
    formComponent: CrossDockingFormComponent,

    getDefaultService: (type?: string) => {
        return {
            name: RfqService.CrossDocking,
            type: type ?? 'allIn',
            pricings: {
                [RfqServicePricing.CrossDocking]: { price: '' },
                [RfqServicePricing.HandlingTotal]: { price: '' },
            },
        }
    },
}
