import React from 'react'
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from '@/shared/ui/Accordion'
import { Separator } from '@/shared/ui/Separator'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { OrderQuoteBreakdown } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteBreakdown'
import { useAppSelector } from '@/app/store'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { Badge } from '@/shared/ui/Badge'

export const OrderQuoteHistory = () => {
    const inactiveQuotes = useAppSelector((state) => state.orderQuoteSupply.inactiveQuotes) as Quote[]

    const quoteMode = useAppSelector((state) => state.orderQuoteSupply.quoteMode)
    const isEditMode = quoteMode === 'edit' || quoteMode === 'revise'

    if (isEditMode || inactiveQuotes.length <= 0) {
        return null
    }

    return (
        <AccordionRoot
            type="multiple"
            className="flex flex-col gap-5 border border-border rounded-xl bg-background-tertiary p-4"
        >
            <div className="!text-xl !font-semibold">Previously submitted quotes</div>
            {inactiveQuotes.map((q, index) => (
                <React.Fragment key={q.id}>
                    {index !== 0 && <Separator />}
                    <AccordionItem value={q.id.toString()} className="border-0">
                        <div className="flex flex-col sm:flex-row justify-between gap-1">
                            <div className="flex gap-3 items-center">
                                <AccordionTrigger
                                    className="flex-grow-0 flex-none w-fit !text-xl !font-semibold justify-start gap-2"
                                    icon={null}
                                >
                                    Quote {inactiveQuotes.length - index}
                                    <InChevronDown
                                        size={20}
                                        className="text-foreground-secondary/70 mr-1 shrink-0 transition-transform duration-200"
                                    />
                                </AccordionTrigger>
                                <div>
                                    {{
                                        [QuoteStatus.REVISED]: (
                                            <Badge variant="warning" className="!no-underline">
                                                Revised
                                            </Badge>
                                        ),
                                        [QuoteStatus.WAREHOUSE_CANCELLED]: (
                                            <Badge variant="danger" className="!no-underline">
                                                Cancelled
                                            </Badge>
                                        ),
                                    }[q.status as number] ?? null}
                                </div>
                            </div>
                        </div>
                        <AccordionContent className="p-0">
                            <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3">
                                <OrderQuoteBreakdown quote={q} />
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                </React.Fragment>
            ))}
        </AccordionRoot>
    )
}
