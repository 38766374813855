import { AxiosRequestConfig } from 'axios'
import { HttpV2 } from '@shared/apiv2/api'
import {
    GetWarehouseOrderParams,
    GetWarehouseOrdersParams,
    UpdateOrderStatusParams,
} from '@/entities/warehouse-orders/model'
import { objectToQueryString } from '@shared/apiv2/list-query.utils'

const getOrder = async (params: GetWarehouseOrderParams, config?: AxiosRequestConfig) => {
    const response = await HttpV2.get(`accounts/${params.warehouseId}/warehouse/orders/${params.orderKey}`, config)
    return response
}

const getOrders = async (params: GetWarehouseOrdersParams, config?: AxiosRequestConfig) => {
    const { warehouseId, ...rest } = params
    const response = await HttpV2.get(
        `accounts/${params.warehouseId}/warehouse/orders?${objectToQueryString(rest)}`,
        config,
    )
    return response
}

const updateOrderStatus = async (params: UpdateOrderStatusParams, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put(
        `accounts/${params.warehouseId}/warehouse/orders/${params.orderKey}/status`,
        { status: params.status },
        config,
    )
    return response
}

export const warehouseOrdersApi = {
    getOrder,
    getOrders,
    updateOrderStatus,
}
