import { ServiceMethods } from '../index'
import { DrayageBreakdownComponent } from './BreakdownComponent'
import { DrayageFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/entities/rfq/model'

export const drayageService: ServiceMethods = {
    breakdownComponent: DrayageBreakdownComponent,
    formComponent: DrayageFormComponent,

    getDefaultService: (type?: string) => {
        return {
            name: RfqService.Drayage,
            type: type ?? 'itemized',
            pricings: {
                [RfqServicePricing.Drayage]: { price: '' },
                [RfqServicePricing.LineHaul]: { price: '' },
                [RfqServicePricing.Chassis]: { price: '' },
                [RfqServicePricing.AccessorialsDetention]: { price: '', active: false },
                [RfqServicePricing.AccessorialsFSC]: { price: '', active: false },
                [RfqServicePricing.AccessorialsOverweight]: { price: '', active: false },
                [RfqServicePricing.AccessorialsScale]: { price: '', active: false },
                [RfqServicePricing.AccessorialsDropPick]: { price: '', active: false },
                [RfqServicePricing.AccessorialsLayover]: { price: '', active: false },
                [RfqServicePricing.AccessorialsChassisSplit]: { price: '', active: false },
                [RfqServicePricing.AccessorialsChassisTriAxel]: { price: '', active: false },
                [RfqServicePricing.AccessorialsCleanTruck]: { price: '', active: false },
                [RfqServicePricing.AccessorialsCongestion]: { price: '', active: false },
                [RfqServicePricing.AccessorialsDemurrage]: { price: '', active: false },
                [RfqServicePricing.AccessorialsPerDiem]: { price: '', active: false },
                [RfqServicePricing.AccessorialsPierPass]: { price: '', active: false },
                [RfqServicePricing.AccessorialsPrePull]: { price: '', active: false },
                [RfqServicePricing.AccessorialsOther]: { price: '', active: false },
            },
        }
    },
}
