import React, { useState } from 'react'
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFloatingClose,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from '@/shared/ui/Dialog'
import { Button } from '@/shared/ui/Button'
import { Select, SelectOption } from '@/shared/ui/Select'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { InputLabel, InputWrapper } from '@/shared/ui/Input'
import { Textarea } from '@/shared/ui/Textarea'
import { loadboardOrdersApi } from '@/entities/loadboard-orders/api/loadboard-orders-api'
import { useAppSelector } from '@/app/store'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { cn } from '@/shared/utils/cn'

const REASON_OPTIONS: SelectOption[] = [
    { value: 'serviceNotAvailable', label: 'Service Not Available' },
    { value: 'outOfCapacity', label: 'Out of Capacity' },
    { value: 'locationMismatch', label: 'Location Mismatch' },
    // eslint-disable-next-line quotes
    { value: 'timing', label: "Timing Doesn't Work" },
    { value: 'lackOfInformation', label: 'Lack of Information' },
    { value: 'other', label: 'Other' },
]

type OrderNotInterestedForm = {
    reason: string
    reasonComment: string
}

const OrderNotInterestedFormSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
    reasonComment: Yup.string().max(250),
})

export const OrderNotInterestedModal = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const [submissionAttempted, setSubmissionAttempted] = useState(false)
    const [open, setOpen] = useState(false)

    const form = useFormik<OrderNotInterestedForm>({
        initialValues: {
            reason: '',
            reasonComment: '',
        },
        validateOnChange: submissionAttempted,
        validateOnMount: false,
        validationSchema: OrderNotInterestedFormSchema,
        onSubmit: async (values) => {
            try {
                const reasonComment = values.reason === 'other' ? values.reasonComment : undefined

                await loadboardOrdersApi.hideLoadboardOrder({
                    orderKey: order.id,
                    reason: values.reason,
                    reasonComment: reasonComment?.trim() || undefined,
                })

                toast('Order has been successfully marked as Not Interesting', { type: 'success' })

                setOpen(false)
            } catch (error: any) {
                console.error(error)
                toast(error.response?.data?.message ?? 'We could not hide this order', { type: 'error' })
            }
        },
    })

    const isLoading = form.isSubmitting

    return (
        <DialogRoot open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="link" disabled={isLoading} className="!text-sm">
                    Not Interested?
                </Button>
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay />
                <DialogContent className="flex flex-col gap-5">
                    <DialogHeader>
                        <DialogTitle>Not interested?</DialogTitle>
                        <DialogDescription>
                            Please choose a reason. This opportunity will be hidden from your list.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={form.handleSubmit} className="flex flex-col gap-5">
                        <div>
                            <Select
                                label="Reason*"
                                options={REASON_OPTIONS}
                                value={form.values.reason}
                                onValueChange={(v) => form.setFieldValue('reason', v)}
                            />
                            {form.errors.reason ? (
                                <div className="mt-1 text-sm text-danger">{form.errors.reason}</div>
                            ) : null}
                        </div>
                        {form.values.reason === 'other' ? (
                            <div>
                                <InputWrapper>
                                    <Textarea
                                        name="reasonComment"
                                        onChange={form.handleChange}
                                        className="resize-none h-20"
                                    />
                                    <InputLabel>Tell us more (Optional)</InputLabel>
                                    <div
                                        className={cn(
                                            'mt-1 text-sm text-right',
                                            form.values.reasonComment.length > 250 && 'text-danger',
                                        )}
                                    >
                                        {form.values.reasonComment.length > 250 && <span>Limit exceeded&nbsp;</span>}(
                                        {form.values.reasonComment.length} / 250)
                                    </div>
                                </InputWrapper>
                            </div>
                        ) : null}

                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="outlined">Cancel</Button>
                            </DialogClose>
                            <Button
                                onClick={() => !submissionAttempted && setSubmissionAttempted(true)}
                                disabled={isLoading}
                            >
                                Submit
                                {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
                            </Button>
                        </DialogFooter>
                    </form>
                    <DialogFloatingClose />
                </DialogContent>
            </DialogPortal>
        </DialogRoot>
    )
}
