import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

export const ReworkFormComponent = ({ order, isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string) => {
        if (service?.pricings?.rework) {
            service!.pricings.rework.price = value
        }
    }

    return (
        <div className="w-full">
            <div className="flex gap-2 w-full">
                <CurrencyInput
                    label={RfqServicePricingLabel[RfqServicePricing.Rework]}
                    defaultValue={service.pricings.rework?.price}
                    onValueChange={({ value }) => onChange(value ?? '')}
                    allowNegative={false}
                    decimalScale={2}
                    prefix="$"
                    wrapperClassName="w-full md:w-[200px]"
                />
                <div className="flex gap-2 items-center w-fit shrink-0">
                    <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                    <div className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                        <span>Pallet</span>
                        <span className="hidden sm:inline">&nbsp;(including materials)</span>
                    </div>
                </div>
            </div>
            {errors?.[RfqServicePricing.Rework] ? (
                <div className="text-sm text-danger">{errors[RfqServicePricing.Rework]}</div>
            ) : null}
        </div>
    )
}
