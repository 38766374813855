import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { Separator } from '@/shared/ui/Separator'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'

export const TransloadingBreakdownComponent = ({ order, service }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        return (
            <div className="flex flex-col gap-3">
                <span className="font-medium leading-5">Floor Loaded</span>

                {service.pricings[RfqServicePricing.Unloading]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Unloading]!.price}
                            className="text-xl leading-6 text-primary font-semibold"
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            {order.unitType}
                        </span>
                    </div>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}

                <Separator />

                <div className="space-y-1">
                    <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                        {RfqServicePricingLabel[RfqServicePricing.Palletization]}
                    </span>
                    <div className="space-y-3">
                        <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 sm:items-center w-full">
                            {service.pricings[RfqServicePricing.Palletization]?.price ? (
                                <CurrencyDisplay
                                    value={service.pricings[RfqServicePricing.Palletization]!.price}
                                    className="text-xl leading-6 text-primary font-semibold"
                                />
                            ) : (
                                <span className="text-foreground-secondary font-medium">N/A</span>
                            )}
                            <div className="flex gap-1 sm:gap-2 items-center">
                                <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                    per
                                </span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet (including materials)
                                </span>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center w-full">
                            {service.pricings[RfqServicePricing.PalletOut]?.price ? (
                                <CurrencyDisplay
                                    value={service.pricings[RfqServicePricing.PalletOut]!.price}
                                    className="text-xl leading-6 text-primary font-semibold"
                                />
                            ) : (
                                <span className="text-foreground-secondary font-medium">N/A</span>
                            )}
                            <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                Pallet Out
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            <span className="font-medium leading-5">Palletized</span>
            <div className="flex flex-col gap-1">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium flex">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                    <HintRoot>
                        <HintTrigger asChild>
                            <div className="ml-1 -mt-[1px]">
                                <FaInfoCircle size={16} />
                            </div>
                        </HintTrigger>
                        <HintContent sideOffset={-3}>
                            <HintArrow /> Combined In and Out Charges per Unit
                        </HintContent>
                    </HintRoot>
                </div>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        $
                        {Number(service.pricings[RfqServicePricing.HandlingTotal]!.price).toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        </div>
    )
}
