import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Account } from '@/entities/accounts/model'
import { Quote } from '@/entities/quotes/model'
import { COIStatus } from '@/entities/accounts/model/enums'

export type QuoteMode = 'default' | 'edit' | 'revise'

export type OrderQuoteSupplySlice = {
    quoteType: 'standard' | 'allIn'
    quoteMode: QuoteMode
    warehouses: Account[] | null
    selectedWarehouse: Account | null
    activeQuote: Quote | null
    inactiveQuotes: Quote[] | null
}

const initialState: OrderQuoteSupplySlice = {
    quoteType: 'standard',
    quoteMode: 'default',
    warehouses: null,
    selectedWarehouse: null,
    activeQuote: null,
    inactiveQuotes: null,
}

export const orderQuoteSupplySlice = createSlice({
    name: 'orderQuoteSupply',
    initialState,
    reducers: {
        revertToInitial: () => {
            return { ...initialState }
        },
        setQuoteType: (state, action: PayloadAction<string>) => {
            state.quoteType = action.payload as 'standard' | 'allIn'
        },
        setQuoteMode: (state, action: PayloadAction<QuoteMode>) => {
            state.quoteMode = action.payload
        },
        setWarehouses: (state, action: PayloadAction<Account[]>) => {
            state.warehouses = action.payload
        },
        setSelectedWarehouse: (state, action: PayloadAction<string>) => {
            const warehouse = state.warehouses?.find((wh) => wh.id === action.payload)
            if (warehouse) {
                state.selectedWarehouse = warehouse
            }
        },
        setWarehouseCOIStatus: (state, action: PayloadAction<{ id: string; status: COIStatus | null }>) => {
            const warehouse = state.warehouses?.find((wh) => wh.id === action.payload.id)
            if (warehouse) {
                warehouse.coiStatus = action.payload.status

                if (state.selectedWarehouse?.id === warehouse.id) {
                    state.selectedWarehouse.coiStatus = action.payload.status
                }
            }
        },
        setActiveQuote: (state, action: PayloadAction<Quote | null>) => {
            state.activeQuote = action.payload
        },
        setInactiveQuotes: (state, action: PayloadAction<Quote[] | null>) => {
            state.inactiveQuotes = action.payload
        },
        addInactiveQuote: (state, action: PayloadAction<Quote>) => {
            state.inactiveQuotes?.unshift(action.payload)
        },
    },
})

export const orderQuoteSupplyActions = orderQuoteSupplySlice.actions

export const {
    revertToInitial,
    setQuoteType,
    setWarehouses,
    setQuoteMode,
    setActiveQuote,
    setInactiveQuotes,
    addInactiveQuote,
    setSelectedWarehouse,
    setWarehouseCOIStatus,
} = orderQuoteSupplySlice.actions

export const orderQuoteSupplyReducer = orderQuoteSupplySlice.reducer
