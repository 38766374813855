export enum RfqStatus {
    Pending = 1,
    Quoted = 2,
    Booked = 3,
    Dropped = 4,
    Finished = 5,
    Cancelled = 6,
}

export enum RfqInvoiceStatus {
    Pending = 'Pending', // Currently unused
    Confirmed = 'Confirmed', // When invoice is uploaded, it has status 'Confirmed' which means that it was uploaded.
    Rejected = 'Rejected',
    Expired = 'Expired',
}
