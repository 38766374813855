import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { WarehouseInfo } from './index'
import Button from '@/components/Button'
import { CONSTS } from '@/common/consts'
import { showDeleteStaffModal } from '@/parts/Modals/DeleteStaffModal'
import DotsMenu from '@/components/DotsMenu'
import { HttpV2 } from '@shared/apiv2/api'
import { showUnableDeleteAccountModal } from '@/parts/Modals/UnableDeleteAccountModal'
import { toast } from 'react-toastify'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

interface WarehouseActionsProps {
    warehouse: WarehouseInfo
    refreshWarehouses?: any
    admin?: boolean
}

const WarehouseActions: FC<WarehouseActionsProps> = ({ warehouse, refreshWarehouses, admin }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const navigate = useNavigate()
    // const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false)

    // const deleteRef = useOnclickOutside(() => {
    //     setIsDotsMenuOpen(false)
    // })

    const handleDetailsClick = () => {
        localStorage.setItem(CONSTS.warehouseId, warehouse?.id)
        navigate(`/warehouses/${warehouse.id}`)
        sendCustomEvent('warehouse_details_clicked')
    }

    // const toggleDotsMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //     event.stopPropagation()
    //     setIsDotsMenuOpen(!isDotsMenuOpen)
    // }

    const handleDelete = async () => {
        if (warehouse.isCmpAcc) {
            toast('Cannot delete company account.', { type: 'error' })
            return
        }

        try {
            const { data: isValid } = await HttpV2.get<boolean>(`accounts/${warehouse.id}/validate-delete`)
            if (!isValid) {
                showUnableDeleteAccountModal({
                    props: {
                        accountId: warehouse.id,
                        accountName: warehouse.name,
                    },
                })
                sendCustomEvent('warehouse_delete_error', {
                    accountName: warehouse.name,
                    error_message: 'This warehouse has open orders',
                })
            } else {
                showDeleteStaffModal({
                    props: {
                        modalTitle: `Confirm Delete ${warehouse?.name}`,
                        deleteApiLink: `accounts/${warehouse?.id}/warehouse`,
                        refreshWarehouses: refreshWarehouses,
                        confirmButtonTitle: 'Delete Warehouse',
                        confirmButtonClassName: `${warehousesPage}__modal__btn`,
                        children: (
                            <div>
                                <h3 className={`${warehousesPage}__modal__title`}>{warehouse?.name}</h3>
                                <p className={`${warehousesPage}__modal__text`}>{warehouse?.location}</p>
                            </div>
                        ),
                    },
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    const olimpTable = 'olimp-table'
    const warehousesPage = 'warehouses'

    return (
        <div className={`${olimpTable}__btns-column`}>
            <Button
                className={`${warehousesPage}__details-btn`}
                type="button"
                types={['blue']}
                label="Details"
                onClick={handleDetailsClick}
            />
            {admin && (
                <DotsMenu
                    buttons={[
                        {
                            name: 'Delete',
                            onClick: handleDelete,
                            className: `${warehousesPage}__dots-menu-item ${warehousesPage}__dots-menu-item_delete`,
                        },
                    ]}
                />

                // <div className={`${warehousesPage}__more-btn-wrapper`}>
                //     <button onClick={toggleDotsMenu} className={`${warehousesPage}__dots-menu-btn`}>
                //         <DotsMenuIcon className={`${warehousesPage}__dots-menu-icon`} />
                //     </button>
                //     {isDotsMenuOpen && (
                //         <ul className={`${warehousesPage}__dots-menu-items`} ref={deleteRef}>
                //             <li
                //                 className={`${warehousesPage}__dots-menu-item ${warehousesPage}__dots-menu-item_delete`}
                //                 onClick={handleDelete}
                //             >
                //                 Delete
                //             </li>
                //         </ul>
                //     )}
                // </div>
            )}
        </div>
    )
}

export default WarehouseActions
