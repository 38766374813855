import React, { ChangeEvent, useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { LoadBoardListFilters } from '@shared/apiv2/load-board'
import { SimpleWarehouse } from '@shared/apiv2/warehouse'
import { ReactComponent as ArrowUp } from '@/assets/images/chevron-up.svg'

import { Slider } from '@/components/Slider'
import Datepicker from '@/components/Datepicker'
import Checkbox from '@/components/Checkbox'
import {
    AccordionServices,
    LoadBoardServiceNames,
    certificationsServiceOption,
    environmentServiceOption,
    serviceOption,
} from './LoadBoardHelper'
import { useUserState } from '@/store/user'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import ClickableList from '@/components/ClickableList'
import { setIconForCurrentService } from './helper'
import { LoadBoardCertifications, LoadBoardEnvironments, RfqServiceType } from './types'
import Button from '@/components/Button'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

type LoadBoardSidebarType = {
    CLASS_NAME: string
    onLoadBoardServicesChanged: (e: ChangeEvent, val: string, nameOfService: string) => void
    loadBoardServicesValues: (name: string, serviceName: string) => boolean
    updateFilter: (name: string, param: any) => void
    filters: any
    loading: boolean
    isCarrier: boolean
    onPeriodChange: (params: any) => any
    setFilters: (params: any) => any
    distance: number
    whData: SimpleWarehouse | undefined
    setDistance: (param: any) => void
    clearFilter: () => void
    searchInput: any
    setSearchInput: any
    showFilter: boolean
    setShowFilter: (params: boolean) => any
}

// eslint-disable-next-line complexity
export const LoadBoardSidebar = ({
    updateFilter,
    loadBoardServicesValues,
    CLASS_NAME,
    filters,
    loading,
    isCarrier,
    onPeriodChange,
    setFilters,
    distance,
    whData,
    setDistance,
    searchInput,
    setSearchInput,
    onLoadBoardServicesChanged,
    setShowFilter,
    showFilter,
    clearFilter,
}: LoadBoardSidebarType) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const {
        state: { isLoggedIn },
    } = useUserState()
    const [search, setSearch] = React.useState('')
    const showDistanceSlider = !loading && search
    const [resetTrigger, setResetTrigger] = useState<boolean>(false)

    const prevFiltersRef = useRef<any>(null)
    useEffect(() => {
        const filtersChangedByUser = Object.entries(filters || {}).some(([key, value]) => {
            if (!!value && !!prevFiltersRef.current) {
                return prevFiltersRef.current[key as keyof LoadBoardListFilters] !== value
            } else if (value) {
                return true
            }

            return false
        })

        if (filtersChangedByUser) {
            const { state, zip, country, city } = searchInput
            const filtersAnalyticsObject = {
                ...filters,
                ...(state ? { location_state: state } : {}),
                ...(zip ? { location_zip: zip } : {}),
                ...(country ? { location_country: country } : {}),
                ...(city ? { location_city: city } : {}),
            }

            delete filtersAnalyticsObject['']

            for (const [key, value] of Object.entries(filtersAnalyticsObject)) {
                if (!value) {
                    delete filtersAnalyticsObject[key]
                }
            }

            sendCustomEvent('loadboard_filters/search', filtersAnalyticsObject)
            prevFiltersRef.current = filters
        }
    }, [filters, searchInput, sendCustomEvent])

    const clearAllFilters = () => {
        setFilters({
            services: '',
            environments: '',
            certifications: '',
        })
        setResetTrigger(!resetTrigger)
        setSearch('')
        setSearchInput({ name: 'address', value: '' })
        clearFilter()
    }

    return (
        <div className={`${CLASS_NAME}__filters-panel ${showFilter ? 'active' : ''}`}>
            <div className={`${CLASS_NAME}__filter-titleWrap`}>
                <h2 className={`${CLASS_NAME}__filters-title`}>Filters</h2>
                <ArrowUp className={`${CLASS_NAME}__arrow-up`} onClick={() => setShowFilter(!showFilter)} />
            </div>
            <div className={`${CLASS_NAME}__filter-section`}>
                Service Location
                <GoogleAutocompleteInput
                    type="text"
                    id="addressSearch"
                    name="search"
                    data-name="search"
                    value={search}
                    wrapperClassName={cn(`${CLASS_NAME}__filter-input`)}
                    inputPath="search"
                    label=""
                    onChangeText={(path, value) => {
                        setSearch(value)
                    }}
                    changePlaceFull={(field, value) => {
                        if ((value.lat && value.lng) || !value.formattedAddress) {
                            setSearchInput({
                                name: 'address',
                                value: value.lat && value.lng ? `${value.lat},${value.lng}` : undefined,
                                lng: value.lng,
                                lat: value.lat,
                                building: value.building,
                                city: value.city,
                                country: value.country,
                                full_address: value.formattedAddress,
                                state: value.state,
                                street: value.street,
                                zip: value.zip,
                            })
                        }
                    }}
                    disabled={filters.searchAroundMe}
                    placeholder="Type to search..."
                    onBlur={() => {}}
                />
                {showDistanceSlider && <label style={{ marginTop: 5 }}>Search Distance</label>}
                {showDistanceSlider && (
                    <Slider
                        measure="mi"
                        name="searchAroundMeDistance"
                        onChange={(e: any) => {
                            setDistance(e.target.value)
                        }}
                        handleFinalChange={(v: any) => {
                            setFilters({
                                ...filters,
                                searchAroundMeDistance: distance,
                            })
                        }}
                        value={distance}
                        min={1}
                        max={300}
                    />
                )}
                {/*{isLoggedIn && !loading && !isCarrier && !whData?.shippingAddress?.fullAddress && (*/}
                {/*    <Info className={'warning'}>*/}
                {/*        To find loads near your warehouse, fill in your warehouse address.*/}
                {/*    </Info>*/}
                {/*)}*/}
                {/*{isLoggedIn && !loading && !isCarrier && !whData?.shippingAddress?.fullAddress && (*/}
                {/*    <Link className={cn(`${CLASS_NAME}__filter-link`)} to={'/warehouse/settings'}>*/}
                {/*        <PinIcon /> Check my location settings*/}
                {/*    </Link>*/}
                {/*)}*/}
                {isLoggedIn && !loading && whData?.shippingAddress?.fullAddress && (
                    <Checkbox
                        value={Boolean(filters.searchAroundMe)}
                        onChange={(e: any) => {
                            if (!filters.searchAroundMe && e.target.value) {
                                setSearch(whData?.shippingAddress?.fullAddress || '')
                                setSearchInput({
                                    name: 'address',
                                    value: undefined,
                                })
                            } else if (!e.target.value) {
                                setSearch('')
                                setSearchInput({
                                    name: 'address',
                                    value: undefined,
                                })
                            }
                            setFilters({
                                ...filters,
                                searchAroundMe: e.target.value,
                                searchAroundMeDistance: distance,
                            })
                        }}
                        name="searchAroundMe"
                        label="Use my warehouse address"
                    />
                )}
            </div>
            <div className={cn(`${CLASS_NAME}__filter-section`)}>
                Service Dates
                <Datepicker
                    hasReset
                    past
                    minInterval={1}
                    type="white"
                    dateFormat={'MM/DD/YYYY'}
                    start={filters.startDate as any}
                    end={filters.endDate as any}
                    onChange={onPeriodChange}
                />
            </div>
            {/* <div className={cn(`${CLASS_NAME}__filter-section`)}>
                <AccordionServices title="Services">
                    {serviceOption.map((elem, i) => (
                        <Checkbox
                            key={`${elem.value} ${i}`}
                            value={loadBoardServicesValues(elem.value, LoadBoardServiceNames.services)}
                            // value={serviceValue(elem.value)}
                            name={elem.name}
                            onChange={(e) => onLoadBoardServicesChanged(e, elem.value, LoadBoardServiceNames.services)}
                            // onChange={(e) => onServiceChange(e, elem.value)}
                            label={elem.label}
                        />
                    ))}
                </AccordionServices>
            </div> */}

            <div className={`${CLASS_NAME}__filter-section`}>
                <AccordionServices title="Services">
                    <ClickableList
                        multiSelect
                        items={Object.entries(RfqServiceType).map(([key, value]) => {
                            return {
                                id: value,
                                value: value,
                                icon: setIconForCurrentService(value),
                            }
                        })}
                        resetTrigger={resetTrigger}
                        onItemSelect={(value) => updateFilter('services', value)}
                    />
                </AccordionServices>
            </div>

            <div className={`${CLASS_NAME}__filter-section`}>
                <AccordionServices title="Freight Environments">
                    <ClickableList
                        multiSelect
                        resetTrigger={resetTrigger}
                        items={environmentServiceOption.map(({ label, value }) => {
                            const id =
                                Object.entries(LoadBoardEnvironments).find(([key, val]) => val === label)?.[0] || ''

                            return {
                                id: id,
                                label: label,
                                value: value,
                                icon: setIconForCurrentService(value),
                            }
                        })}
                        onItemSelect={(value) => {
                            updateFilter('environments', value)
                        }}
                    />
                </AccordionServices>
            </div>

            <div className={`${CLASS_NAME}__filter-section`}>
                <AccordionServices title="Certifications">
                    <ClickableList
                        multiSelect
                        resetTrigger={resetTrigger}
                        items={Object.entries(LoadBoardCertifications).map(([key, value]) => {
                            return {
                                id: key,
                                value: value,
                                icon: setIconForCurrentService(value),
                            }
                        })}
                        onItemSelect={(value) => updateFilter('certifications', value)}
                    />
                </AccordionServices>
            </div>
            {/* <div className={`${CLASS_NAME}__filter-section`}>
                <AccordionServices title="Environments">
                    {environmentServiceOption.map((elem, i) => (
                        <Checkbox
                            key={`${elem.value} ${i}`}
                            value={loadBoardServicesValues(elem.value, LoadBoardServiceNames.environment)}
                            name={elem.name}
                            onChange={(e) =>
                                onLoadBoardServicesChanged(e, elem.value, LoadBoardServiceNames.environment)
                            }
                            label={elem.label}
                        />
                    ))}
                </AccordionServices>
            </div> */}
            {/* <div className={`${CLASS_NAME}__filter-section`}>
                <AccordionServices title="Certifications">
                    {certificationsServiceOption.map((elem, i) => (
                        <Checkbox
                            key={`${elem.value} ${i}`}
                            value={loadBoardServicesValues(elem.value, LoadBoardServiceNames.certification)}
                            name={elem.name}
                            onChange={(e) =>
                                onLoadBoardServicesChanged(e, elem.value, LoadBoardServiceNames.certification)
                            }
                            label={elem.label}
                        />
                    ))}
                </AccordionServices>
            </div> */}
            <div className={`${CLASS_NAME}__buttons`}>
                <Button style={{ marginRight: 12 }} types={['bordered', 'full']} onClick={clearAllFilters}>
                    Clear
                </Button>
                <Button types={['blue', 'full']} onClick={() => setShowFilter(!showFilter)}>
                    Apply
                </Button>
            </div>
        </div>
    )
}
