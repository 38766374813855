import React from 'react'
import { QuoteInsurance } from './QuoteInsurance'
import { QuoteAllIn } from './QuoteAllIn'
import { QuoteStandard } from './QuoteStandard'
import { useAppSelector } from '@/app/store'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { COIStatus } from '@/entities/accounts/model/enums'

export const OrderQuoteSubmit = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const selectedWarehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse)
    const quoteType = useAppSelector((state) => state.orderQuoteSupply.quoteType)

    if (order.status === LoadboardOrderStatus.CLOSED || order.status === LoadboardOrderStatus.BID_ACCEPTED) {
        return (
            <div className="border border-border bg-background-tertiary rounded-lg flex items-center justify-center py-3">
                The order is closed for quote submissions.
            </div>
        )
    }

    return (
        <div>
            {selectedWarehouse?.coiStatus !== COIStatus.Confirmed ? (
                <QuoteInsurance />
            ) : quoteType === 'standard' ? (
                <QuoteStandard mode="default" />
            ) : (
                <QuoteAllIn mode="default" />
            )}
        </div>
    )
}
