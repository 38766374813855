export enum BidStatusType {
    CREATED = 0,
    ACCEPTED = 1,
    DECLINED = 2,
}

export const BidStatus = {
    [BidStatusType.CREATED]: 'CREATED',
    [BidStatusType.ACCEPTED]: 'ACCEPTED',
    [BidStatusType.DECLINED]: 'DECLINED',
}

export const BidStatusName = {
    CREATED: 'CREATED',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
}

export type BidsType = {
    createdAt?: string
    createdBy?: Date
    id: number
    notes?: string
    postId: number
    price: number
    status: number
    updatedAt?: Date
    warehouseId?: number
}

export const RfqServiceType = {
    'Cross-Docking': 'Cross-Docking',
    Rework: 'Rework / Restack',
    Storage: 'Storage',
    Transloading: 'Transloading',
    'Local Delivery': 'Final Mile / Local Delivery',
    Drayage: 'Drayage',
    Disposal: 'Disposal',
    'Yard Storage': 'Yard Storage / Trailer Parking',
}

// export enum LoadBoardEnvironments {
//     Dry = 'Dry',
//     Frozen = 'Frozen (-10° to -32°)',
//     'Chilled/Refrigerated' = 'Chilled/Refrigerated (33° to 42°)',
//     'Temp Controlled' = 'Temp Controlled (50° to 65°)',
//     'Ambient' = 'Ambient (65°+)',
//     'Specific Temp w/in Environment' = 'Specific Temp w/in Environment',
// }

export const LoadBoardEnvironments = {
    Dry: 'Dry',
    Frozen: 'Frozen (32° and below)',
    Chilled: 'Chilled/Refrigerated (33° to 48°)',
    Ambient: 'Ambient (65°+)',
    Specific: 'Temperature Controlled',
}

export const LoadBoardCertifications = {
    'Food Grade': 'Food Grade',
    'Food (AIB)': 'Food (AIB)',
    Organic: 'Organic',
    Medical: 'Medical',
    'Foreign Trade Zone (FTZ)': 'Foreign Trade Zone (FTZ)',
    'TSA Approved': 'TSA Approved',
    Military: 'Military',
    Hazmat: 'Hazmat',
    Bonded: 'Bonded',
    GDP: 'GDP',
    Alcohol: 'Alcohol',
}
