import { QuoteService } from '@/entities/quotes/model'
import { RfqService } from '@/entities/rfq/model'
import { standardQuoteService } from './standard-quote-services'

export const getDefaultSQService = (
    service: string,
    isUnitFloorLoaded: boolean,
    mode: 'read' | 'write' = 'write',
    existingService?: QuoteService | undefined,
) => {
    let defaultService: any
    let serviceType: string

    switch (service) {
        case RfqService.CrossDocking:
        case RfqService.Transloading:
            serviceType = existingService?.type ? existingService.type : isUnitFloorLoaded ? 'allIn' : 'unit'
            defaultService = standardQuoteService[service as RfqService]!.getDefaultService(serviceType)
            break
        case RfqService.Storage:
            defaultService = standardQuoteService[service as RfqService]!.getDefaultService(
                existingService?.type ?? 'unit',
            )
            break
        case RfqService.Drayage:
            if (existingService) {
                serviceType = existingService.type as string
            } else {
                if (mode === 'write') {
                    serviceType = 'itemized'
                } else {
                    serviceType = 'allIn'
                }
            }
            defaultService = standardQuoteService[service as RfqService]!.getDefaultService(serviceType)
            break
        case RfqService.Rework:
        case RfqService.FinalMile:
        case RfqService.Disposal:
        case RfqService.YardStorage:
        case RfqService.Handling:
            defaultService = standardQuoteService[service as RfqService]!.getDefaultService()
            break
    }

    return defaultService
}
