import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InBell = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.5V2.4375C16.4219 3.14062 19 6.14062 19 9.75V10.6406C19 12.8438 19.7969 15 21.25 16.6406L21.5781 17.0156C22 17.4844 22.0938 18.0938 21.8594 18.6562C21.625 19.1719 21.0625 19.5 20.5 19.5H2.5C1.89062 19.5 1.32812 19.1719 1.09375 18.6562C0.859375 18.0938 0.953125 17.4844 1.375 17.0156L1.70312 16.6406C3.15625 15 4 12.8438 4 10.6406V9.75C4 6.14062 6.53125 3.14062 10 2.4375V1.5C10 0.703125 10.6562 0 11.5 0C12.2969 0 13 0.703125 13 1.5ZM11.5 24C10.7031 24 9.90625 23.7188 9.34375 23.1562C8.78125 22.5938 8.5 21.7969 8.5 21H14.5C14.5 21.7969 14.1719 22.5938 13.6094 23.1562C13.0469 23.7188 12.25 24 11.5 24Z" />
            </svg>
        </IconBase>
    )
}
