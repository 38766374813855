import * as React from 'react'
import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/shared/utils/cn'

const toggleVariants = cva(
    'h-8 px-2 inline-flex items-center justify-center border border-solid border-transparent rounded-lg !text-sm !font-normal ring-offset-background transition-colors duration-75 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-background-tertiary hover:bg-accent-light/70 data-[state=on]:border-primary data-[state=on]:hover:bg-accent-light/70',
                outlined:
                    'border border-border bg-transparent hover:bg-accent-light/50 data-[state=on]:bg-background-tertiary data-[state=on]:border-primary data-[state=on]:hover:bg-accent-light/70',
                filled: 'border border-border bg-transparent hover:border-primary data-[state=on]:bg-primary data-[state=on]:border-primary data-[state=on]:text-white data-[state=on]:hover:bg-primary/90 data-[state=on]:hover:border-primary/90',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

const Toggle = React.forwardRef<
    React.ElementRef<typeof TogglePrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, ...props }, ref) => (
    <TogglePrimitive.Root ref={ref} className={cn(toggleVariants({ variant, className }))} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, toggleVariants }
