import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'

export const ReworkBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex items-center">
            {service.pricings[RfqServicePricing.Rework]?.price ? (
                <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 sm:items-center w-full">
                    <CurrencyDisplay
                        value={service.pricings[RfqServicePricing.Rework]!.price}
                        className="text-xl leading-6 text-primary font-semibold"
                    />
                    <div className="flex gap-1 sm:gap-2 items-center">
                        <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                            per
                        </span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            Pallet (including materials)
                        </span>
                    </div>
                </div>
            ) : (
                <span className="text-foreground-secondary font-medium">N/A</span>
            )}
        </div>
    )
}
