import { RfqService } from '@/entities/rfq/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { getServicesFromOrder, isFloorLoaded, getDefaultSQService } from './index'
import { StandardQuoteValues } from '../model'

export const getDefaultSQValues = (order: LoadboardOrder) => {
    const services = getServicesFromOrder(order)
    const isUnitFloorLoaded = isFloorLoaded(order.unitType)
    const values: StandardQuoteValues = {}

    services.forEach((service) => {
        values[service as RfqService] = getDefaultSQService(service, isUnitFloorLoaded)
    })

    return values
}
