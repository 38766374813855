import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import useSWR from 'swr'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { WarehousesMenuList } from '../helper'
import { MenuSelect } from './MenuSelect'
import './WarehousesMenu.scss'
import { HttpV2 } from '@shared/apiv2/api'
import { WarehouseInfo } from '../index'
import useOnclickOutside from 'react-cool-onclickoutside'
import cn from 'classnames'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const fetcher = async (url: string) => {
    const response = await HttpV2.get(url)
    return response.data
}

interface WarehousesMenuProps {
    selectWarehouse: (id: string) => void
}

export const WarehousesMenu = ({ selectWarehouse }: WarehousesMenuProps) => {
    const { sendMenuClick, sendCustomEvent } = useCustomAnalyticsEvent()
    const { id } = useParams()
    const location = useLocation()
    const currentTab = location.pathname.split('/')[3] || WarehousesMenuList[0].link

    const [activeWH, setActiveWH] = useState<any>(null)
    const [menuToggle, setMenuToggle] = useState<boolean>(false)
    const [expandMenu, setExpandMenu] = useState<boolean>(false)
    const [warehousesList, setWarehousesList] = useState<WarehouseInfo[] | undefined>()
    const [searchTerm, setSearchTerm] = useState('')

    const TIME_INTERVAL = 500
    const navigate = useNavigate()

    const menuRef = useOnclickOutside(() => {
        setMenuToggle(false)
    })

    const handleSelect = (whId: string) => {
        setMenuToggle(false)
        const path = location.pathname.split('/')
        path[2] = whId
        navigate(path.join('/'))
        sendCustomEvent('viewed_warehouse_changed', { warehouse_id: whId })
    }

    const { data, isValidating } = useSWR(
        searchTerm ? `accounts/related?limit=10&search=${encodeURIComponent(searchTerm)}` : null,
        fetcher,
    )

    const fetchWarehouses = async () => {
        try {
            const warehouses = await HttpV2.get('accounts/related')
            setWarehousesList(warehouses.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (warehousesList) {
            const warehouse = warehousesList.find((item: any) => item.id === id)
            setActiveWH(warehouse)
        }
    }, [id, warehousesList])

    useEffect(() => {
        // set initial list of accounts
        fetchWarehouses()
    }, [])

    useEffect(() => {
        if (data) {
            setWarehousesList(data)
        }
    }, [data])

    const handleSearch = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            // if empty set default existing list of accounts
            if (!e.target.value) {
                fetchWarehouses()
            }
            setSearchTerm(e.target.value)
        }, TIME_INTERVAL),

        [],
    )

    if (!id) {
        return <Navigate to={'/warehouses'} />
    }

    return (
        <div className="warehouses-menu">
            <div
                ref={menuRef}
                className={`${
                    menuToggle ? 'warehouses-menu__title warehouses-menu__title_active' : 'warehouses-menu__title'
                }`}
            >
                <div
                    className={cn('warehouses-menu__titleWrap', { active: menuToggle })}
                    title={activeWH ? activeWH.name : 'Select Warehouse'}
                    onClick={() => {
                        setExpandMenu(false)
                        setMenuToggle(!menuToggle)
                    }}
                >
                    {activeWH ? activeWH.name : 'Select Warehouse'}
                </div>
                <MenuSelect
                    selectWarehouse={handleSelect}
                    loading={isValidating}
                    id={id}
                    data={warehousesList}
                    expandMenu={expandMenu}
                    setExpandMenu={setExpandMenu}
                    handleSearch={handleSearch}
                />
            </div>
            <ul className="warehouses-menu__list">
                {WarehousesMenuList?.map((menu, index) => (
                    <li
                        className={`warehouses-menu__item ${
                            menu.link === currentTab ? 'warehouses-menu__item_active' : ''
                        }`}
                        key={`menu-${index}`}
                        onClick={() => {
                            const link = `/warehouses/${id}/${menu.link}`
                            navigate(link)
                            sendMenuClick({ label: 'warehouses_menu', link: link, place: 'header' })
                        }}
                    >
                        {menu.title}
                    </li>
                ))}
            </ul>
        </div>
    )
}
