import React, { FC, useEffect, useState, useMemo } from 'react'
import HelpLinks from '@/parts/Cards/HelpLinks'
import Input from '@/components/InputLabel'
import { Link } from 'react-router-dom'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'
import PhoneInput from '@/pages/RFQPortal/components/IntlTelInput'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import Progress from '@/components/ModernTable/Progress'
import { showChangePasswordModal } from '@/parts/Modals/ChangePasswordModal'
import { useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'
import { toast } from 'react-toastify'
import { InputField, InputIcon, InputLabel, InputWrapper } from '@/shared/ui/Input'
import { InPlus } from '@/shared/ui/Icons/InPlus'
import { Button } from '@/shared/ui/Button'
import { ChangeEmailModal } from '@/pages/Account/ChangeEmailModal'
import './index.scss'
import cn from 'classnames'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const PAGE_CLASS_NAME = 'account-page'
const ACOUNT_CLASS_NAME = 'account-page-account'

const Account: FC = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [isAccountDataSubmitting, setIsAccountDataSubmitting] = useState<boolean>(false)
    const [accountDataSubmitError, setAccountDataSubmitError] = useState<string>('')
    const [phoneItiObject, setPhoneItiObject] = useState<any>(null)

    const [userLocation, setUserLocation] = useState<any>({ formattedAddress: '' })
    const [firstName, setFirstName] = useState<string>('')
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string>('')

    const [lastName, setLastName] = useState<string>('')
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>('')

    const [email, setEmail] = useState<string>('')
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')

    const [phone, setPhone] = useState<string>('')
    const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('')

    const [password, setPassword] = useState<string>('')
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')

    const [editableMode, setEditableMode] = useState<boolean>(false)

    const { state, dispatch } = useUserState()

    const initialContactInfo = useMemo(
        () => ({
            firstName: state.userInfo?.firstName,
            lastName: state.userInfo?.lastName,
            email: state.userInfo?.email,
            phone: state.userInfo?.phone,
            location: {
                formattedAddress: state.userInfo?.address ?? '',
            },
        }),
        [state.userInfo],
    )

    useEffect(() => {
        setFirstName(initialContactInfo.firstName || '')
        setLastName(initialContactInfo.lastName || '')
        setEmail(initialContactInfo.email || '')
        setPhone(initialContactInfo.phone || '')
        setUserLocation({
            formattedAddress: initialContactInfo.location.formattedAddress || '',
        })
    }, [initialContactInfo])

    useEffect(() => {
        if (
            initialContactInfo.firstName !== firstName ||
            initialContactInfo.lastName !== lastName ||
            initialContactInfo.email !== email ||
            initialContactInfo.phone !== phone ||
            (userLocation.formattedAddress &&
                initialContactInfo.location.formattedAddress !== userLocation.formattedAddress)
        ) {
            setAccountDataSubmitError('')
            setEditableMode(true)
        } else {
            setEditableMode(false)
        }
    }, [initialContactInfo, firstName, lastName, email, phone, password, userLocation.formattedAddress])

    const handleCancel = () => {
        setFirstName(initialContactInfo.firstName || '')
        setLastName(initialContactInfo.lastName || '')
        setEmail(initialContactInfo.email || '')
        setPhone(initialContactInfo.phone || '')
    }

    const handleSubmit = async () => {
        const phoneValidation = validatePhone()
        if (!phoneValidation) {
            return
        }

        setIsAccountDataSubmitting(true)

        try {
            await HttpV2.put('/sso/account', {
                phone,
                location: userLocation,
                firstName,
                lastName,
            })

            if (dispatch && state.userInfo) {
                dispatch({
                    type: UserActionType.SET_LOGGED_STATE,
                    userInfo: {
                        ...state.userInfo,
                        phone: phone,
                        address: userLocation.formattedAddress,
                        firstName,
                        lastName,
                    },
                })
            }

            toast.success('Account successfully updated!', {
                autoClose: 3000,
            })
        } catch (error: any) {
            setAccountDataSubmitError(error.response?.data?.message)
        } finally {
            setIsAccountDataSubmitting(false)
        }
    }

    const validatePhone = (): boolean => {
        if (!editableMode) {
            clearAllErrors()
            return false
        }

        if (!phoneItiObject) {
            setPhoneErrorMessage('Error! Please contact support.')
            return false
        }

        if (phoneItiObject.getNumber().trim() === '') {
            setPhoneErrorMessage('Field is required')
            return false
        }

        if (phoneItiObject && !phoneItiObject.isValidNumber() && phoneItiObject.getNumber().trim() !== '') {
            setPhoneErrorMessage('Please enter a valid phone number')
            return false
        }

        setPhoneErrorMessage('')
        return true
    }

    const clearAllErrors = () => {
        setPhoneErrorMessage('')
    }

    return (
        <div className={`${PAGE_CLASS_NAME} app-page-container_flex`}>
            <div className="app-page">
                <div className={`${ACOUNT_CLASS_NAME} app-card`}>
                    <div className="app-card__header bordered-block">
                        <p className="app-card__title">Account</p>
                    </div>
                    {!state.userInfo ? (
                        <Progress />
                    ) : (
                        <form className={`${ACOUNT_CLASS_NAME}-form app-card-content`} action="">
                            <div className="app-card-flex-row">
                                <InputWrapper>
                                    <InputField
                                        className="text-base"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e: any) => setFirstName(e.target.value)}
                                    />
                                    <InputLabel>First Name</InputLabel>
                                </InputWrapper>
                                {/*<Input*/}
                                {/*    label="First Name*"*/}
                                {/*    value={firstName}*/}
                                {/*    onChange={(e: any) => setFirstName(e.target.value)}*/}
                                {/*    errorMessage={editableMode ? firstNameErrorMessage : ''}*/}
                                {/*    disabled*/}
                                {/*/>*/}
                            </div>
                            <div className="app-card-flex-row">
                                <InputWrapper>
                                    <InputField
                                        className="text-base"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e: any) => setLastName(e.target.value)}
                                    />
                                    <InputLabel>Last Name*</InputLabel>
                                </InputWrapper>
                            </div>
                            <div className="app-card-flex-row">
                                <InputWrapper>
                                    <InputField className="text-base" placeholder="Email" value={email} disabled />
                                    <InputLabel>Email*</InputLabel>
                                </InputWrapper>

                                <div>
                                    <ChangeEmailModal />
                                </div>
                            </div>
                            <div className="app-card-flex-row">
                                <PhoneInput
                                    label="Phone#*"
                                    clientPhoneItiObject={phoneItiObject}
                                    setClientPhoneItiObject={setPhoneItiObject}
                                    type="tel"
                                    id="phone"
                                    placeholder="(999) 999-9999"
                                    value={phone}
                                    changeValue={(value) => setPhone(value)}
                                    errorMessage={editableMode ? phoneErrorMessage : ''}
                                    validatePhone={validatePhone}
                                />
                            </div>
                            <div className="app-card-flex-row">
                                <InputWrapper>
                                    <InputLabel>Location</InputLabel>

                                    <GoogleAutocompleteInput
                                        type="text"
                                        id="addressSearch"
                                        name="search"
                                        data-name="search"
                                        value={userLocation.formattedAddress}
                                        wrapperClassName={cn(`${ACOUNT_CLASS_NAME}__location-input`)}
                                        inputPath="search"
                                        label=""
                                        onChangeText={(path, value) => {
                                            setUserLocation((curr: any) => ({ ...curr, formattedAddress: value }))
                                        }}
                                        changePlaceFull={(field, value) => {
                                            if ((value.lat && value.lng) || !value.formattedAddress) {
                                                // setSearchInput({
                                                //     name: 'address',
                                                //     value: value.lat && value.lng ? `${value.lat},${value.lng}` : undefined,
                                                // })
                                                setUserLocation(value)
                                            }
                                        }}
                                        placeholder="Type to search..."
                                        onBlur={() => {}}
                                    />
                                </InputWrapper>
                            </div>

                            <div className="app-card-flex-row">
                                <InputWrapper>
                                    <InputField
                                        type="password"
                                        className="text-base"
                                        value={password}
                                        placeholder="************"
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        disabled
                                    />
                                    <InputLabel>Password</InputLabel>
                                </InputWrapper>
                                <div>
                                    <Button
                                        variant="link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            showChangePasswordModal({
                                                props: {},
                                            })

                                            sendCustomEvent('password_change_started')
                                        }}
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                    <CancelDeleteButtons
                        isEditableMode={editableMode}
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmit}
                        isSubmitting={isAccountDataSubmitting}
                        errorMessage={accountDataSubmitError}
                    />
                </div>
            </div>
            <div className="app-sidebar">
                <HelpLinks />
            </div>
        </div>
    )
}

export default Account
