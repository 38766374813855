import { HttpV2 } from '@shared/apiv2/api'
import { BaseQueryFn, createApi, retry } from '@reduxjs/toolkit/query/react'
import { AxiosRequestConfig } from 'axios'

export const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig> => (params) => HttpV2({ ...params })

const baseQueryWithRetry = retry(axiosBaseQuery(), { maxRetries: 3 })

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithRetry,
    endpoints: () => ({}),
})
