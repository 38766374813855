import { HttpV2 } from '@shared/apiv2/api'

export const uploadChatAttachment = async (file: File): Promise<number> => {
    return HttpV2.post(
        '/chats/attachments',
        {
            file,
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    ).then((res) => {
        return res.data
    })
}

export const getAttachmentSignedURL = async (id: number): Promise<string> => {
    return HttpV2.get('/chats/attachments/' + id).then((res) => {
        return res.data
    })
}
