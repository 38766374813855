import { useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { ChatEvent } from '@/entities/chat/model/enums'

export enum IONamespace {
    Chat = 'chat',
    Notifications = 'notifications',
}

export const instances: Map<IONamespace, Socket> = new Map()

export function makeSocket(namespace: IONamespace) {
    let instance = instances.get(namespace)
    if (!instance) {
        const token = localStorage.getItem('token')
        const processLocalSocketURL = process.env.REACT_APP_LOCAL_SOCKET_URL
        const socketURL = (processLocalSocketURL ? processLocalSocketURL : window.location.origin) + '/' + namespace
        instance = io(socketURL, {
            transports: ['websocket'],
            auth: {
                Authorization: `Bearer ${token}`,
            },
        })
        ;(instance as any).connecting = true

        instances.set(namespace, instance)

        instance.on('connect', () => {
            ;(instance as any).connecting = false
            console.log('Socket connected to', namespace)
        })

        instance.on('disconnect', (reason, description) => {
            ;(instance as any).connecting = false
            console.log('Socket disconnected from', namespace, reason, description)
        })

        instance.on('error', (message) => {
            ;(instance as any).connecting = false

            console.error('Socket Error received:', message)
        })
    }

    if (instance.disconnected && (instance as any).connecting === false) {
        ;(instance as any).connecting = true
        instance.connect()
    }

    return instance
}

export const useSocket = (namespace: IONamespace) => {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [error, setError] = useState(null)
    const [connected, setConnected] = useState(false)

    useEffect(() => {
        const socketInstance = makeSocket(namespace)
        setSocket(socketInstance)
        setConnected(socketInstance.connected)
        const handleConnect = () => {
            setConnected(true)
        }

        const handleDisconnect = () => {
            setConnected(false)
        }

        if (socketInstance) {
            socketInstance.on('connect', handleConnect)
            socketInstance.on('disconnect', handleDisconnect)
        }

        return () => {
            if (socketInstance) {
                socketInstance.off('connect', handleConnect)
                socketInstance.off('disconnect', handleDisconnect)
                socketInstance.close()
            }
        }
    }, [])

    return {
        error,
        socket,
        connected,
    }
}

export const useSocketSubscriptions = (
    socket: Socket | null,
    subscriptions: { event: string; callback?: (data: any) => void }[],
) => {
    useEffect(() => {
        // Unsubscribe from all subsciptions first
        subscriptions.forEach(({ event, callback }) => {
            if (socket && callback) {
                socket.off(event, callback)
            }
        })

        // Then subscribe to all subscriptions
        subscriptions.forEach(({ event, callback }) => {
            if (socket && callback) {
                socket.on(event, callback)
            }
        })

        return () => {
            // Cleanup: Unsubscribe when component unmounts or any dependency changes
            subscriptions.forEach(({ event, callback }) => {
                if (socket && callback) {
                    socket.off(event, callback)
                }
            })
        }
    }, [socket, subscriptions])
}
