/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { HttpV2 } from '@shared/apiv2/api'
import { toast } from 'react-toastify'
import InputLabel from '@/components/InputLabel'
import Button from '@/components/Button'
import './WarehouseGeneral.scss'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import CheckBoxNew from '@/components/CheckboxNew'
import { Address, Warehouse } from '../types'
import { Loader } from '@/components/Loader'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { useUserState } from '@/store/user'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const GeneralWarehouseSchema = Yup.object().shape({
    name: Yup.string().required('Warehouse name is required'),
    location: Yup.lazy((value) =>
        typeof value === 'object'
            ? Yup.object().shape({
                  formattedAddress: Yup.string().required('Warehouse location is required field'),
              })
            : Yup.string().required('Warehouse location is required field'),
    ),
})

type GeneralProps = {
    warehouseId: string | null
    data: any
    loading: boolean
    revalidate: any
    manager: boolean
}

function General({ warehouseId, data, loading, revalidate, manager }: GeneralProps) {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [searchInput, setSearchInput] = useState('')
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [userLocation, setUserLocation] = useState<any>({ formattedAddress: '' })

    const { state } = useUserState()

    const formik = useFormik<Warehouse & { primary: boolean }>({
        initialValues: {
            name: data?.name || '',
            location: data?.location || '',
            usdot: data?.usdot || '',
            totalLoadingDocks: data?.totalLoadingDocks || '',
            primary: data?.isPrimary || false,
        },
        validateOnMount: false,
        validationSchema: GeneralWarehouseSchema,
        validateOnChange: validateOnChange,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting, setErrors, setTouched }) => {
            setSubmitting(true)
            try {
                await HttpV2.put(`/accounts/${warehouseId}/warehouse`, {
                    ...values,
                    location: userLocation,
                })
                    .then(async (response: any) => {
                        await revalidate()
                        toast('Success!', { type: 'success' })
                        sendCustomEvent('warehouse_general_info_updated', {
                            ...values,
                        })
                    })
                    .catch((err) => {
                        console.log(err?.response?.data)

                        if (err?.response?.data?.totalLoadingDocks) {
                            toast(err?.response?.data.totalLoadingDocks, { type: 'error' })
                        }

                        if (err?.response?.data?.usdot) {
                            toast(err?.response?.data?.usdot, { type: 'error' })
                        }

                        if (err?.response?.data?.message) {
                            toast(err?.response?.data?.message, { type: 'error' })
                        }
                    })
            } catch (err: any) {
                console.log(err)
                toast(err?.message ?? 'Soothing went wrong!', { type: 'error' })
            } finally {
                setValidateOnChange(true)
                setSubmitting(false)
            }
        },
    })

    useEffect(() => {
        if (data?.location && !userLocation.formattedAddress) {
            setUserLocation({
                formattedAddress: data.location || '',
            })
            formik.setFieldValue('location', data.location)
        }
    }, [data])

    const handleInputChange = (e: any) => {
        formik.handleChange(e)
        formik.setErrors({})
    }

    const locationHasChanged = userLocation.formattedAddress !== data?.location
    return (
        <div className="warehouses-general__general">
            <div className="app-card__header bordered-block">
                <h2 className="app-card__title">General</h2>
            </div>
            <>
                {loading ? (
                    <Loader type="large" />
                ) : (
                    <>
                        <div className="warehouses-general__general__left">
                            <InputLabel
                                name="name"
                                label="Warehouse Name*"
                                className="warehouses-general__general__input warehouses-general_mb"
                                value={formik.values.name || ''}
                                onChange={handleInputChange}
                                errorMessage={formik.errors.name}
                                disabled={!manager}
                            />
                            <div className="warehouses-general_mb">
                                <GoogleAutocompleteInput
                                    type="text"
                                    id="addressSearch"
                                    name="search"
                                    data-name="search"
                                    className="warehouses-general__general__input"
                                    value={userLocation.formattedAddress}
                                    inputPath="search"
                                    label="Location Address*"
                                    onChangeText={(path, value) => {
                                        setUserLocation((curr: any) => ({ ...curr, formattedAddress: value }))
                                        formik.setFieldValue('location', value)
                                    }}
                                    changePlaceFull={(field, value) => {
                                        if (value.lat && value.lng) {
                                            setUserLocation(value)
                                            formik.setFieldValue('location', value.formattedAddress)
                                        }
                                    }}
                                    initialValue={data?.location}
                                    errorMessage={formik.errors.location?.formattedAddress}
                                    onBlur={() => {}}
                                    disabled={!manager}
                                />
                            </div>

                            <CheckBoxNew
                                name="primary"
                                onChange={(checked) => {
                                    formik.setFieldValue('primary', checked)
                                }}
                                checked={formik.values.primary!}
                                label="Set as Primary Warehouse"
                                className="warehouses-general__checkbox warehouses-general_mb"
                            />

                            <h3 className="warehouses-general__subtitle  warehouses-general_mb">Details</h3>

                            <InputLabel
                                name="usdot"
                                label="US DOT or MX/MX#"
                                className="warehouses-general__general__input warehouses-general_mb"
                                value={formik.values.usdot || ''}
                                onChange={handleInputChange}
                                disabled={!manager}
                                errorMessage={formik.errors.usdot}
                            />

                            <InputLabel
                                name="totalLoadingDocks"
                                label="# of Loading Docks"
                                disabled={!manager}
                                className="warehouses-general__general__input warehouses-general_mb"
                                value={formik.values.totalLoadingDocks || ''}
                                onChange={handleInputChange}
                                errorMessage={formik.errors.totalLoadingDocks}
                            />

                            {(formik.dirty || locationHasChanged) && (
                                <>
                                    <div className="warehouses-general__general__btns">
                                        <Button
                                            onClick={() => {
                                                // revalidate()
                                                formik.resetForm({
                                                    values: {
                                                        ...data,
                                                        primary: data.isPrimary,
                                                    },
                                                })
                                                setUserLocation({
                                                    formattedAddress: data.location,
                                                })
                                            }}
                                            types={['bordered']}
                                            className="warehouses-general__general__btn"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            style={{ width: 160 }}
                                            types={['bordered', 'blue']}
                                            loading={formik.isSubmitting}
                                            onClick={() => formik.handleSubmit()}
                                            disabled={
                                                !formik.isValid ||
                                                !(formik.dirty || locationHasChanged) ||
                                                formik.isSubmitting
                                            }
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="warehouses-general__general__right"></div>
                    </>
                )}
            </>
        </div>
    )
}

export default General
