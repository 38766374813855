import { HttpV2 } from '@shared/apiv2/api'
import { useState } from 'react'

export const getDemandInfoByOrderReference = async (reference: string) => {
    return HttpV2.get(`/chats/demand/info?reference=${reference}`).then((res) => res.data)
}

export const inviteDemandToChat = async (reference: string) => {
    return HttpV2.post(`/chats/demand/invite?reference=${reference}`)
}

export interface UseInviteProps {
    onError: (message: string) => void
    onSuccess: () => void
}

export const useInviteDemandToChat = ({ onError, onSuccess }: UseInviteProps) => {
    const [loading, setLoading] = useState(false)

    const invite = async (reference: string) => {
        try {
            setLoading(true)
            await inviteDemandToChat(reference)
            onSuccess()
        } catch (e: any) {
            onError(e.response.data.message)
        } finally {
            setLoading(false)
        }
    }

    return {
        invite,
        loading,
    }
}
