import React, { useState } from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { Separator } from '@/shared/ui/Separator'
import { Button } from '@/shared/ui/Button'
import { Label } from '@/shared/ui/Label'
import { Checkbox } from '@/shared/ui/Checkbox'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

const PRICINGS_WITH_DAY_POSTFIX = [
    RfqServicePricing.AccessorialsLayover,
    RfqServicePricing.AccessorialsChassisTriAxel,
    RfqServicePricing.AccessorialsDemurrage,
    RfqServicePricing.AccessorialsPerDiem,
]

const NON_ACCESSORIAL_PRICINGS = [RfqServicePricing.Drayage, RfqServicePricing.LineHaul, RfqServicePricing.Chassis]

export const DrayageFormComponent = ({ service, errors }: ServiceFormComponentProps) => {
    const onPriceChange = (value: string, field: RfqServicePricing) => {
        if (service.pricings[field]) {
            service.pricings[field]!.price = value
        }
    }

    /** Hack to rerender UI to display updated ref.current state */
    const [_, rerender] = useState({})

    const onTypeChange = (newType: string) => {
        service.type = newType
        rerender({})
    }

    const onCheckedChange = (checked: boolean, field: RfqServicePricing) => {
        if (service.pricings[field]) {
            service.pricings[field]!.active = checked
            rerender({})
        }
    }

    const setAllPricingsActive = (active: boolean) => {
        Object.entries(service.pricings).forEach(([key, value]) => {
            if (NON_ACCESSORIAL_PRICINGS.includes(key as RfqServicePricing)) return
            value.active = active
        })
        rerender({})
    }

    const hasActive = Object.values(service.pricings).some((p) => p.active)

    return (
        <div className="flex flex-col gap-5 w-full">
            <TabsRoot defaultValue={service.type as string} onValueChange={onTypeChange}>
                <TabsList variant="filled" className="h-8 w-full md:w-fit">
                    <TabsTrigger value="itemized" className="basis-0 flex-grow md:basis-auto md:flex-grow-0">
                        Itemized
                    </TabsTrigger>
                    <TabsTrigger value="allIn" className="basis-0 flex-grow md:basis-auto md:flex-grow-0">
                        All In
                    </TabsTrigger>
                </TabsList>
            </TabsRoot>

            {service.type === 'allIn' ? (
                <div className="w-full">
                    <CurrencyInput
                        label={RfqServicePricingLabel[RfqServicePricing.Drayage]}
                        defaultValue={service.pricings.drayage?.price}
                        onValueChange={({ value }) => onPriceChange(value ?? '', RfqServicePricing.Drayage)}
                        allowNegative={false}
                        decimalScale={2}
                        prefix="$"
                        wrapperClassName="w-full md:w-[200px]"
                    />
                    {errors?.[RfqServicePricing.Drayage] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.Drayage]}</div>
                    ) : null}
                </div>
            ) : null}

            {service.type === 'itemized' ? (
                <>
                    <div className="w-full">
                        <CurrencyInput
                            label={RfqServicePricingLabel[RfqServicePricing.LineHaul]}
                            defaultValue={service.pricings.lineHaul?.price}
                            onValueChange={({ value }) => onPriceChange(value ?? '', RfqServicePricing.LineHaul)}
                            allowNegative={false}
                            decimalScale={2}
                            prefix="$"
                            wrapperClassName="w-full md:w-[200px]"
                        />
                        {errors?.[RfqServicePricing.LineHaul] ? (
                            <div className="text-sm text-danger">{errors[RfqServicePricing.LineHaul]}</div>
                        ) : null}
                    </div>
                    <div>
                        <div className="flex gap-2 items-center w-full">
                            <CurrencyInput
                                label={RfqServicePricingLabel[RfqServicePricing.Chassis]}
                                defaultValue={service.pricings.chassis?.price}
                                onValueChange={({ value }) => onPriceChange(value ?? '', RfqServicePricing.Chassis)}
                                allowNegative={false}
                                decimalScale={2}
                                prefix="$"
                                wrapperClassName="w-full md:w-[200px]"
                            />
                            <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                                Day
                            </span>
                        </div>
                        {errors?.[RfqServicePricing.Chassis] ? (
                            <div className="text-sm text-danger">{errors[RfqServicePricing.Chassis]}</div>
                        ) : null}
                    </div>

                    <Separator />

                    <div className="flex gap-2">
                        <div className="text-sm font-medium text-foreground-secondary">Accessorials</div>
                        <Button
                            type="button"
                            variant="link"
                            onClick={hasActive ? () => setAllPricingsActive(false) : () => setAllPricingsActive(true)}
                        >
                            {hasActive ? 'Deselect All' : 'Select All'}
                        </Button>
                    </div>

                    {/** Filter off all non-accessorial pricings */}
                    {Object.entries(service.pricings)
                        .filter(([key]) => !NON_ACCESSORIAL_PRICINGS.includes(key as RfqServicePricing))
                        .map(([key, pricing], index) => {
                            const pricingName = key as RfqServicePricing
                            return (
                                <div
                                    className="flex gap-2 md:gap-0 lg:gap-7 flex-col md:flex-row w-full min-h-9"
                                    key={index}
                                >
                                    <Label className="flex items-center gap-2 w-[200px] shrink-0">
                                        <Checkbox
                                            checked={pricing.active}
                                            onCheckedChange={(c: boolean) => onCheckedChange(c, pricingName)}
                                        />
                                        {RfqServicePricingLabel[pricingName]}
                                    </Label>

                                    {pricing.active && (
                                        <div>
                                            <div className="flex gap-2 items-center">
                                                <CurrencyInput
                                                    defaultValue={pricing.price}
                                                    onValueChange={({ value }) => onPriceChange(value ?? '', pricingName)}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    prefix="$"
                                                    wrapperClassName="w-full md:w-[200px]"
                                                />

                                                {PRICINGS_WITH_DAY_POSTFIX.includes(pricingName) ||
                                                pricingName === RfqServicePricing.AccessorialsDetention ? (
                                                    <>
                                                        <span className="text-xl shrink-0 text-foreground-secondary">
                                                            /
                                                        </span>
                                                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                                                            {pricingName === RfqServicePricing.AccessorialsDetention
                                                                ? 'Hour'
                                                                : 'Day'}
                                                        </span>
                                                    </>
                                                ) : pricingName === RfqServicePricing.AccessorialsFSC ? (
                                                    <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                                                        % of line haul
                                                    </span>
                                                ) : null}
                                            </div>
                                            {errors?.[pricingName] ? (
                                                <div className="text-sm text-danger">{errors[pricingName]}</div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                </>
            ) : null}
        </div>
    )
}
