import { StandardQuoteErrors } from '@/pages/Order/ui/OrderQuote/model'
import { StandardQuoteServiceDto } from '@/entities/quotes/model'

export const validateSQServices = (services: StandardQuoteServiceDto[]): any => {
    const errors: StandardQuoteErrors = {}

    if (services.length <= 0) {
        return {
            isValid: false,
            genericError: 'Please fill at least one field',
        }
    }

    services.forEach((service) => {
        service.pricings.forEach((pricing) => {
            let error = ''

            if (isNaN(pricing.price) || pricing.price < 0) {
                error = 'Price must be a positive number'
            }

            if (pricing.price > 99_999_999.99) {
                error = 'Price exceeds the maximum allowed value'
            }

            if (error) {
                if (!errors[service.name]) {
                    errors[service.name] = {
                        [pricing.name]: error,
                    }
                } else {
                    errors[service.name][pricing.name] = error
                }
            }
        })
    })

    if (Object.keys(errors).length > 0) {
        return {
            isValid: false,
            errors: errors,
        }
    }

    return { isValid: true }
}
