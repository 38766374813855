import { InCrossDocking } from '@/shared/ui/Icons/InCrossDocking'
import { InRework } from '@/shared/ui/Icons/InRework'
import { InStorage } from '@/shared/ui/Icons/InStorage'
import { InTransloading } from '@/shared/ui/Icons/InTransloading'
import { InFinalMile } from '@/shared/ui/Icons/InFinalMile'
import { InDisposal } from '@/shared/ui/Icons/InDisposal'
import { InDrayage } from '@/shared/ui/Icons/InDrayage'
import { InYardStorage } from '@/shared/ui/Icons/InYardStorage'
import { IconProps } from '@/shared/ui/Icons'
import { ReactElement } from 'react'

export enum RfqService {
    CrossDocking = 'Cross-Docking',
    Rework = 'Rework / Restack',
    Storage = 'Storage',
    Transloading = 'Transloading',
    FinalMile = 'Final Mile / Local Delivery',
    Drayage = 'Drayage',
    Disposal = 'Disposal',
    YardStorage = 'Yard Storage / Trailer Parking',
    Handling = 'Handling',
}

export enum RfqServicePricing {
    CrossDocking = 'crossDocking',
    HandlingTotal = 'handlingTotal',
    Rework = 'rework',
    Storage = 'storage',
    Unloading = 'unloading',
    Palletization = 'palletization',
    PalletOut = 'palletOut',
    LineHaul = 'lineHaul',
    Chassis = 'chassis',
    Drayage = 'drayage',
    Disposal = 'disposal',

    AccessorialsDetention = 'accessorialDetention',
    AccessorialsFSC = 'accessorialFSC',
    AccessorialsOverweight = 'accessorialOverweight',
    AccessorialsScale = 'accessorialScale',
    AccessorialsDropPick = 'accessorialDropPick',
    AccessorialsLayover = 'accessorialLayover',
    AccessorialsChassisSplit = 'accessorialsChassisSplit',
    AccessorialsChassisTriAxel = 'accessorialsChassisTriAxel',
    AccessorialsCleanTruck = 'accessorialsCleanTruck',
    AccessorialsCongestion = 'accessorialsCongestion',
    AccessorialsDemurrage = 'accessorialsDemurrage',
    AccessorialsPerDiem = 'accessorialsPerDiem',
    AccessorialsPierPass = 'accessorialsPierPass',
    AccessorialsPrePull = 'accessorialsPrePull',
    AccessorialsOther = 'accessorialsOther',
}

export const RfqServicePricingLabel = {
    [RfqServicePricing.CrossDocking]: 'Cross-Docking',
    [RfqServicePricing.HandlingTotal]: 'Handling IN & OUT',
    [RfqServicePricing.Rework]: 'Rework / Restack',
    [RfqServicePricing.Storage]: 'Storage',
    [RfqServicePricing.Unloading]: 'Unloading',
    [RfqServicePricing.Palletization]: 'Palletization',
    [RfqServicePricing.PalletOut]: 'Pallet Out (if applicable)',
    [RfqServicePricing.LineHaul]: 'Line Haul',
    [RfqServicePricing.Chassis]: 'Chassis',
    [RfqServicePricing.Drayage]: 'Drayage',
    [RfqServicePricing.Disposal]: 'Disposal',

    [RfqServicePricing.AccessorialsDetention]: 'Detention',
    [RfqServicePricing.AccessorialsFSC]: 'Fuel Surcharge (FSC)',
    [RfqServicePricing.AccessorialsOverweight]: 'Overweight',
    [RfqServicePricing.AccessorialsScale]: 'Scale',
    [RfqServicePricing.AccessorialsDropPick]: 'Drop/Pick',
    [RfqServicePricing.AccessorialsLayover]: 'Layover',
    [RfqServicePricing.AccessorialsChassisSplit]: 'Chassis - Split',
    [RfqServicePricing.AccessorialsChassisTriAxel]: 'Chassis - Tri-Axel',
    [RfqServicePricing.AccessorialsCleanTruck]: 'Clean Truck (CA Only)',
    [RfqServicePricing.AccessorialsCongestion]: 'Congestion',
    [RfqServicePricing.AccessorialsDemurrage]: 'Demurrage',
    [RfqServicePricing.AccessorialsPerDiem]: 'Per Diem',
    [RfqServicePricing.AccessorialsPierPass]: 'Pier Pass',
    [RfqServicePricing.AccessorialsPrePull]: 'Pre-Pull',
    [RfqServicePricing.AccessorialsOther]: 'Other',
}

export type ServiceIcons = Partial<Record<RfqService, (props: IconProps) => ReactElement>>

export const serviceIcons: ServiceIcons = {
    [RfqService.CrossDocking]: InCrossDocking,
    [RfqService.Rework]: InRework,
    [RfqService.Storage]: InStorage,
    [RfqService.Transloading]: InTransloading,
    [RfqService.FinalMile]: InFinalMile,
    [RfqService.Disposal]: InDisposal,
    [RfqService.Drayage]: InDrayage,
    [RfqService.YardStorage]: InYardStorage,
}
