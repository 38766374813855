import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InClose2 = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75628 4.99372C3.41457 4.65201 3.41457 4.09799 3.75628 3.75628C4.09799 3.41457 4.65201 3.41457 4.99372 3.75628L14 12.7626L23.0063 3.75628C23.348 3.41457 23.902 3.41457 24.2437 3.75628C24.5854 4.09799 24.5854 4.65201 24.2437 4.99372L15.2374 14L24.2437 23.0063C24.5854 23.348 24.5854 23.902 24.2437 24.2437C23.902 24.5854 23.348 24.5854 23.0063 24.2437L14 15.2374L4.99372 24.2437C4.65201 24.5854 4.09799 24.5854 3.75628 24.2437C3.41457 23.902 3.41457 23.348 3.75628 23.0063L12.7626 14L3.75628 4.99372Z" />
            </svg>
        </IconBase>
    )
}
