// src/useMessageSeen.js
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMessageContext } from './singleMessageProvider'
const BATCH_SIZE = 10
function useMessageSeen(messageId: number, seenBy: number[], scrollableContainerRef: any, userId?: number) {
    const { ref, inView, entry } = useInView({
        threshold: 0.1, // Trigger when 10% of the message is in view
        root: scrollableContainerRef.current,
    })

    const { unseenMessagesRef, debouncedSendUnseenMessages, sendUnseenMessages } = useMessageContext()
    const prevInViewRef = useRef(inView)
    const hasUserSeenRef = useRef(userId ? seenBy.includes(userId) : true)

    useEffect(() => {
        if (entry && entry.isIntersecting) {
            if (userId && !hasUserSeenRef.current) {
                hasUserSeenRef.current = true // Mark as seen
                if (!unseenMessagesRef.current) {
                    unseenMessagesRef.current = []
                }

                if (!unseenMessagesRef.current.includes(messageId)) {
                    unseenMessagesRef.current.push(messageId)
                }

                // If buffer is full, send immediately
                if (unseenMessagesRef.current.length >= BATCH_SIZE) {
                    sendUnseenMessages()
                } else {
                    // Set timeout to send messages after inactivity
                    debouncedSendUnseenMessages()
                }
            }
        }

        // Cleanup function to ensure remaining messages are sent when component unmounts
        return () => {
            debouncedSendUnseenMessages.cancel() // Cancel any pending debounce
            sendUnseenMessages() // Send any remaining unseen messages
        }
    }, [entry, messageId, unseenMessagesRef, userId, debouncedSendUnseenMessages, sendUnseenMessages])

    // Track inView changes to avoid redundant effect calls
    useEffect(() => {
        prevInViewRef.current = inView
    }, [inView])

    useEffect(() => {
        hasUserSeenRef.current = userId ? seenBy.includes(userId) : true
    }, [seenBy, userId])

    return ref
}

export default useMessageSeen
