import React, { FC, RefObject, useState } from 'react'
import HintBox from '@/pages/RFQPortal/components/HintBox'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'
import './checkbox.scss'

interface CheckboxProps {
    currentOppId?: string
    errorMessage?: string | false | undefined
    avaliableOptions: string[]
    disabled?: boolean
    fieldName: string
    stateData: string[]
    handleChange: (isChecked: boolean, inputValue: string) => void
    isFlex?: boolean
    onClick?: () => void
    wrapperRef?: RefObject<HTMLDivElement>
    type?: Type
    tooltipMessages?: any
    disabledOptions?: string[]
}

export enum Type {
    days = 'days',
}

const Checkbox: FC<CheckboxProps> = ({
    currentOppId,
    errorMessage,
    avaliableOptions,
    fieldName,
    disabled,
    stateData,
    handleChange,
    wrapperRef,
    isFlex = false,
    onClick,
    type,
    tooltipMessages,
    disabledOptions,
}) => {
    const [checkboxId] = useState(currentOppId || generateRandomId())

    return (
        <div className={`${type === Type.days ? 'group-wrapper-days' : 'group-wrapper'} rfq-checkbox`} ref={wrapperRef}>
            <div className={isFlex ? 'flex-checkboxes' : 'checkbox-group'}>
                {avaliableOptions.map((item) => {
                    const itemId = `${item}`.replace(/\s/g, '_')

                    const name = fieldName + (checkboxId ? '-' + checkboxId : '')
                    const id = itemId + (checkboxId ? '-' + checkboxId : '')

                    const toolTipServices = Object.keys(tooltipMessages || []).map((service) => service)

                    return (
                        <div className="checkbox-wrapper" key={id} data-item={item}>
                            <input
                                type="checkbox"
                                name={name}
                                id={id}
                                value={item}
                                checked={stateData.includes(item)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const isChecked = e.target.checked
                                    const inputValue = e.target.value
                                    handleChange(isChecked, inputValue)
                                }}
                                onClick={onClick}
                                disabled={disabledOptions?.includes(item) || disabled}
                            />
                            <label htmlFor={id} className="checkbox-label">
                                <span className="checkbox-label-item">{item}</span>
                                {tooltipMessages && toolTipServices.includes(item) && (
                                    <HintBox content={tooltipMessages[item]}></HintBox>
                                )}
                            </label>
                        </div>
                    )
                })}
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default Checkbox
