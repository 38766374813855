import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InSendV2 = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.35 11.1375C21.2 10.875 20.975 10.6875 20.7125 10.5375L3.42496 0.862462C3.12496 0.712462 2.78746 0.637462 2.44996 0.674962C2.11246 0.712462 1.81246 0.824962 1.54996 1.04996C1.28746 1.27496 1.09996 1.57496 1.02496 1.87496C0.912458 2.21246 0.949958 2.54996 1.06246 2.88746L4.28746 12L1.06246 21.1125C0.949958 21.45 0.949958 21.7875 1.02496 22.0875C1.09996 22.425 1.28746 22.6875 1.54996 22.9125C1.81246 23.1375 2.11246 23.25 2.44996 23.2875C2.48746 23.2875 2.56246 23.2875 2.59996 23.2875C2.86246 23.2875 3.16246 23.2125 3.42496 23.0625L20.7125 13.3875C20.975 13.2375 21.2 13.05 21.35 12.7875C21.5 12.525 21.575 12.225 21.575 11.9625C21.575 11.7 21.5 11.4 21.35 11.1375ZM2.67496 2.36246L18.35 11.1375H5.78746L2.67496 2.36246ZM2.67496 21.6375L5.82496 12.8625H18.3875L2.67496 21.6375Z" />
            </svg>
        </IconBase>
    )
}
