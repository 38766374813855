import React from 'react'
import {
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/shared/ui/AlertDialog'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { useAppSelector } from '@/app/store'

export type OrderQuoteSubmitDialogProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
    isLoading: boolean
    onSubmit: () => void
}

export const QuoteSubmitModal = ({ open, onOpenChange, isLoading, onSubmit }: OrderQuoteSubmitDialogProps) => {
    const quoteType = useAppSelector((state) => state.orderQuoteSupply.quoteType)
    const quoteMode = useAppSelector((state) => state.orderQuoteSupply.quoteMode)

    return (
        <AlertDialogRoot open={open} onOpenChange={onOpenChange}>
            <AlertDialogTrigger asChild>
                <Button type="button" disabled={isLoading} className="basis-0 flex-grow sm:basis-auto sm:flex-grow-0">
                    {{
                        default: 'Submit',
                        edit: 'Update',
                        revise: 'Revise',
                    }[quoteMode] ?? 'Submit'}
                    <span className="hidden sm:block">&nbsp;Quote</span>
                    {isLoading ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            {{
                                default: 'Confirm Quote',
                                edit: 'Update Quote',
                                revise: 'Confirm Revision',
                            }[quoteMode] ?? 'Confirm Quote'}
                        </AlertDialogTitle>
                    </AlertDialogHeader>
                    {{
                        default: (
                            <>
                                <p>Clicking {'"Confirm"'} will submit your Quote to OLIMP.</p>
                                <p>
                                    Once submitted you will have the ability to update or cancel your quote if needed.
                                </p>
                            </>
                        ),
                        edit: (
                            <>
                                <p>
                                    By clicking {'"Update"'} your current Quote will be updated with the new
                                    information.
                                </p>
                            </>
                        ),
                        revise: (
                            <>
                                <p>By clicking {'"Confirm"'} new revised version of your Quote will be submitted.</p>
                            </>
                        ),
                    }[quoteMode] ?? null}
                    <AlertDialogFooter>
                        <AlertDialogCancel type="button" disabled={isLoading}>
                            Cancel
                        </AlertDialogCancel>
                        <Button onClick={onSubmit} disabled={isLoading}>
                            {{
                                default: 'Confirm',
                                edit: 'Update',
                                revise: 'Confirm',
                            }[quoteMode] ?? 'Confirm'}
                            {isLoading ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialogRoot>
    )
}
