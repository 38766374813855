import React from 'react'

const isChromeOrEdgeOniOS = (() => {
    const userAgent = navigator.userAgent
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream
    const isChrome = /CriOS/.test(userAgent)
    const isEdge = /EdgiOS/.test(userAgent)
    return isIOS && (isChrome || isEdge)
})()

export const useIOSInputFocusOutFix = (inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>) => {
    // refer to https://support.google.com/chrome/thread/170808931?hl=en&sjid=6941042876217237643-AP
    // When tapping Done on iOS keyboard, the input does not lose focus (no focusout event), only blur event is triggered
    // This hook listens to blur event and triggers focusout event manually, if viewport height changes (due to keyboard closing)

    React.useEffect(() => {
        if (!isChromeOrEdgeOniOS) {
            return // Do nothing if not on Chrome or Edge on iOS
        }

        const input = inputRef.current
        if (!input) {
            return
        }

        const handleBlur = (event: Event) => {
            const viewportHeight = window.visualViewport?.height ?? 0
            const handleFocusOut = () => {
                const newViewportHeight = window.visualViewport?.height ?? 0
                if (newViewportHeight === viewportHeight) {
                    return
                }

                input.blur()
            }

            // Delay the focusout event to make sure the viewport height has changed
            setTimeout(handleFocusOut, 100)
        }

        input.addEventListener('blur', handleBlur)
        return () => {
            input.removeEventListener('blur', handleBlur)
        }
    }, [inputRef])
}
