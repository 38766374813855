import React, { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Container } from '@/shared/ui/Container'
import { Section } from '@/shared/ui/Section'
import { Button } from '@/shared/ui/Button'
import { Loader } from '@/shared/ui/Loader'
import { InArrowLeft } from '@/shared/ui/Icons/InArrowLeft'
import { toast } from 'react-toastify'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { useGetLoadboardOrder } from '@/entities/loadboard-orders/api'
import { OrderDetails } from '@/pages/Order/ui/OrderDetails'
import { OrderProgress } from '@/pages/Order/ui/OrderProgress'
import { OrderQuoteDemand } from '@/pages/Order/ui/OrderQuote'
import { OrderQuoteSupply } from '@/pages/Order/ui/OrderQuote'
import { useScrollTop } from '@/shared/hooks/useScrollTop'
import { OrderChat } from '@/pages/Order/ui/OrderChat'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { orderPageActions } from '@/pages/Order/model/slice'

export const OrderPage = () => {
    const dispatch = useAppDispatch()
    const order = useAppSelector((state) => state.orderPage.order)

    useScrollTop()

    const navigate = useNavigate()
    const location = useLocation()
    const orderKey = useParams().orderKey

    const { state: userState } = useUserState()
    const isDemandAccount = userState.userInfo?.role === UserRole.FREIGHT_BROKER_MANAGER

    const handleNavigateBack = () => {
        /** location.key has value "default" when the page is loaded initially (i.e., when opened by using URL)
         *  location.state.redirected is "true" when redirected from old /loadboard/:orderKey URL */

        if (location.key !== 'default' && location.state?.redirected !== true) {
            navigate(-1)
            return
        }

        navigate(isDemandAccount ? '/rfq/list' : '/loadboard')
    }

    const { data, error, isValidating: isLoading, revalidate: refetchOrder } = useGetLoadboardOrder(orderKey)

    useEffect(() => {
        if (error) {
            handleNavigateBack()
            toast('We could not load the order.', { type: 'error' })
        }
        if (!isLoading && data) {
            dispatch(orderPageActions.setOrder(data))
        }
    }, [isLoading, error, data])

    return (
        <Container className="pt-3 pb-6 space-y-3">
            {/** Page Header */}
            <header className="px-3 h-6 sm:px-0">
                <Button
                    variant="plain"
                    className="text-foreground-secondary hover:text-primary gap-2"
                    onClick={handleNavigateBack}
                >
                    <InArrowLeft /> Back
                </Button>
            </header>

            {isLoading || error || !order || !isDemandAccount ? null : <OrderProgress order={order} />}

            {/** Page main body with details, bids, chat, etc. */}
            <div className="flex flex-col xl:flex-row gap-6">
                <main className="flex flex-grow flex-col gap-6 w-full">
                    {isLoading || error || !order ? (
                        <Section>
                            <Loader />
                        </Section>
                    ) : (
                        <>
                            {/** Order details. Available for everyone */}
                            <OrderDetails />

                            {/** Order quote. Displays different content based on several variables */}
                            <Section>
                                {{
                                    [UserRole.WAREHOUSE_MANAGER]: <OrderQuoteSupply />,
                                    [UserRole.FREIGHT_BROKER_MANAGER]: <OrderQuoteDemand />,
                                }[userState.userInfo!.role as number] ?? null}
                            </Section>
                        </>
                    )}
                </main>
                <aside className="shrink-0">{orderKey && <OrderChat reference={orderKey} />}</aside>
            </div>
        </Container>
    )
}
