import { OrderChatSlice } from '@/pages/Order/ui/OrderChat/model/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatMessageDto, ChatMessagingMode } from '@/entities/chat/model/dto'

const initialState: OrderChatSlice = {
    uploadingMedias: [],
    chatMessages: [],
    participants: [],
    availableChats: [],
    selectedChat: undefined,
    currentMessage: '',
    totalUnseen: 0,
    chatLoading: false,
    currentChat: null,
    demandInfo: undefined,
    messageBoard: [],
    messageBoardLoading: true,
    messageBoardActiveReference: undefined,
    messageBoardFilters: {
        hasChats: true,
        myChats: true,
        search: '',
    },
}

export const orderChatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChatMessageBoardSearch(state, action) {
            state.messageBoardFilters.search = action.payload
        },
        setChatMessageBoardFilters(state, action) {
            state.messageBoardFilters = action.payload
        },
        setMessageBoardLoading(state, action) {
            state.messageBoardLoading = action.payload
        },
        setChatMessageBoard(state, action) {
            state.messageBoard = action.payload ?? []
        },
        setDemandInfo(state, action) {
            state.demandInfo = action.payload
        },
        setActiveMessageBoardReference(state, action: PayloadAction<string | undefined>) {
            state.messageBoardActiveReference = action.payload
        },
        startUploadingMedia(state, action: PayloadAction<{ id: number }>) {
            state.uploadingMedias = state.uploadingMedias.map((media) =>
                media.id === action.payload.id ? { ...media, loading: true } : media,
            )
        },
        finishUploadingMedia(state, action: PayloadAction<{ id: number }>) {
            state.uploadingMedias = state.uploadingMedias.map((media) =>
                media.id === action.payload.id ? { ...media, loading: false } : media,
            )
        },
        updateUploadingMedia(
            state,
            action: PayloadAction<{ id: number | string; preview: string; previewLoading: boolean }>,
        ) {
            state.uploadingMedias = state.uploadingMedias.map((media) =>
                media.id === action.payload.id
                    ? { ...media, preview: action.payload.preview, previewLoading: action.payload.previewLoading }
                    : media,
            )
        },
        clearUploadingMedias(state) {
            state.uploadingMedias = []
        },
        addUploadingMedia(state, action) {
            state.uploadingMedias = [...state.uploadingMedias, action.payload]
        },
        removeUploadingMediaAt(state, action: PayloadAction<number>) {
            state.uploadingMedias = state.uploadingMedias.filter((media, index) => index !== action.payload)
        },
        selectChat(state, action: PayloadAction<number | ChatMessagingMode | undefined>) {
            state.selectedChat = action.payload
        },
        setParticipants(state, action) {
            state.participants = action.payload ?? []
        },
        setAvailableChats(state, action) {
            state.availableChats = action.payload ?? []
        },
        setChatMessages(
            state,
            action: PayloadAction<{
                messages: ChatMessageDto[]
                userId: number
            }>,
        ) {
            state.chatMessages = action.payload.messages ?? []
            state.totalUnseen = state.chatMessages.reduce(
                (acc, msg) => acc + (!msg.me && !msg.seenBy?.includes?.(action.payload.userId) ? 1 : 0),
                0,
            )
        },
        setMessagesSeen(state, action) {
            state.chatMessages = state.chatMessages.map((msg) => {
                if (!action.payload.messageIds.includes(msg.id)) {
                    return msg
                }

                const seenBySet = new Set(msg.seenBy ?? [])
                seenBySet.add(action.payload.participantId)

                return {
                    ...msg,
                    seenBy: Array.from(seenBySet),
                }
            })
            state.totalUnseen = state.chatMessages.reduce(
                (acc, msg) => acc + (!msg.me && !msg.seenBy?.includes?.(action.payload.participantId) ? 1 : 0),
                0,
            )
            // update unseen count in messageboard
            state.messageBoard = state.messageBoard.map((msg) => {
                const chats = msg.chats.map((chat) => {
                    const unseenMessagesCount = chat.unseen_messages_count - 1
                    return chat.id === state.selectedChat
                        ? {
                              ...chat,
                              unseen_messages_count: isNaN(unseenMessagesCount) ? 0 : unseenMessagesCount,
                          }
                        : chat
                })
                return msg.rfq_key === state.messageBoardActiveReference
                    ? {
                          ...msg,
                          unseen: chats.reduce((acc, chat) => acc + chat.unseen_messages_count, 0),
                          chats: chats,
                      }
                    : msg
            })
        },
        addChatMessage(state, action) {
            state.chatMessages = [...state.chatMessages, action.payload]
            state.totalUnseen += !action.payload.me ? 1 : 0
        },
        setCurrentMessage(state, action) {
            state.currentMessage = action.payload
        },
        setChatLoading(state, action) {
            state.chatLoading = action.payload
        },
        setCurrentChat(state, action) {
            state.currentChat = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const orderChatActions = orderChatSlice.actions

export const orderChatReducer = orderChatSlice.reducer
