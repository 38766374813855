export enum RfqFreightEnv {
    Dry = 'Dry',
    Freeze = 'Freeze',
    Chilled = 'Chilled',
    TempControlled = 'Temp Controlled',
    Ambient = 'Ambient',
    Specific = 'Specific',
}

export const RfqFreightEnvLabel = {
    [RfqFreightEnv.Dry]: 'Dry',
    [RfqFreightEnv.Freeze]: 'Frozen (32° and below)',
    [RfqFreightEnv.Chilled]: 'Chilled/Refrigerated (33° to 48°)',
    [RfqFreightEnv.TempControlled]: 'Temp Controlled',
    [RfqFreightEnv.Ambient]: 'Ambient (65°+)',
    [RfqFreightEnv.Specific]: 'Temp Controlled',
}
