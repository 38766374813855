import { useGetRelatedAccounts } from '@/entities/accounts/api'
import {
    setQuoteMode,
    setActiveQuote,
    setInactiveQuotes,
    setSelectedWarehouse,
    setWarehouses,
} from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/model'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { SelectOption } from '@/shared/ui/Select'
import { QuoteStatus } from '@/entities/quotes/model'

export const useOrderQuoteWarehouses = () => {
    const dispatch = useAppDispatch()
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const { data, error, isValidating } = useGetRelatedAccounts()

    const onWarehouseSelect = (accountId: string) => {
        dispatch(setQuoteMode('default'))
        dispatch(setSelectedWarehouse(accountId))

        /** Get and set to store active quote for selected WH and */
        const activeQuote = order.bids.find(
            (bid) =>
                bid.warehouseId === accountId &&
                [
                    QuoteStatus.CREATED,
                    QuoteStatus.QUOTED,
                    QuoteStatus.REQUOTE,
                    QuoteStatus.ACCEPTED,
                    QuoteStatus.DECLINED,
                ].includes(bid.status),
        )
        dispatch(setActiveQuote(activeQuote || null))

        /** Get all other quotes for selected WH to show as history */
        const inactiveQuotes = order.bids.filter((bid) => bid.warehouseId === accountId && bid.id !== activeQuote?.id)
        dispatch(setInactiveQuotes(inactiveQuotes))
    }

    /** Save related accounts in state */
    useEffect(() => {
        if (!isValidating && !error && data) {
            dispatch(setWarehouses(data))

            let warehouseToSelect: string | null = null

            const primaryWH = data.find((item) => item.isPrimary)
            const companyAccount = data.find((item) => item.isCmpAcc)

            if (primaryWH) {
                warehouseToSelect = primaryWH.id
            } else if (companyAccount) {
                warehouseToSelect = companyAccount.id
            } else if (data.length > 0) {
                warehouseToSelect = data[0].id
            }

            if (warehouseToSelect) {
                onWarehouseSelect(warehouseToSelect)
            }
        }
    }, [isValidating, data, error])

    const warehouses = useAppSelector((state) => state.orderQuoteSupply.warehouses)
    const warehousesOptions: SelectOption[] | undefined = warehouses?.map((w) => ({ value: w.id, label: w.name }))

    return { isLoading: isValidating, error, warehousesOptions, onWarehouseSelect }
}
