import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'

export const CrossDockingFormComponent = ({ isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string, field: RfqServicePricing) => {
        if (service.pricings[field]) {
            service.pricings[field]!.price = value
        }
    }

    if (isFloorLoaded) {
        return (
            <div className="flex flex-col gap-5 w-full">
                <div className="font-medium">Floor Loaded</div>
                <div>
                    <CurrencyInput
                        label={RfqServicePricingLabel[RfqServicePricing.CrossDocking]}
                        defaultValue={service.pricings.crossDocking?.price}
                        onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.CrossDocking)}
                        allowNegative={false}
                        decimalScale={2}
                        prefix="$"
                        wrapperClassName="w-full md:w-[200px]"
                    />
                    {errors?.[RfqServicePricing.CrossDocking] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.CrossDocking]}</div>
                    ) : null}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="font-medium">Palletized</div>
            <div>
                <CurrencyInput
                    label={
                        <div className="flex">
                            {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                            <HintRoot>
                                <HintTrigger asChild>
                                    <div className="ml-1 -mt-[1px]">
                                        <FaInfoCircle size={16} />
                                    </div>
                                </HintTrigger>
                                <HintContent sideOffset={-3}>
                                    <HintArrow /> Combined In and Out Charges per Unit
                                </HintContent>
                            </HintRoot>
                        </div>
                    }
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.handlingTotal?.price}
                    onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.HandlingTotal)}
                    allowNegative={false}
                    decimalScale={2}
                    className="w-full md:w-[200px]"
                    prefix="$"
                />
                {errors?.[RfqServicePricing.HandlingTotal] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingTotal]}</div>
                ) : null}
            </div>
        </div>
    )
}
