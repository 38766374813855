import React, { FC, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { useLocation } from 'react-router'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'

import '@/pages/RFQPortal/RFQPortal.scss'

import RfqHeader, { IframedRfqHeader } from '@/pages/RFQPortal/parts/RfqHeader'
import { RFQList } from '@/pages/RFQPortal/RfqList'
import RfqOrder from '@/pages/RFQPortal/RfqOrder'
import RfqForm from '@/pages/RFQPortal/RfqForm'

import { useDynamicHeightEvents } from '@/pages/RFQPortal/dynamic.height'
import { useUserState } from '@/store/user'
import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'
import { reloginNeeded } from '@/pages/RFQPortal/helpers/rfqHelper'
import RfqModals from '@/pages/RFQPortal/modals'

import RfqModalProvider from '@/pages/RFQPortal/contexts/RfqModalProvider'
import ExpandedOppProvider from '@/pages/RFQPortal/contexts/ExpandedOppProvider'
import RFQProjectList from '@/pages/RFQPortal/RFQProjectList'
import IframedRfqOrder from '@/pages/RFQPortal/RfqOrder/iframe-solution'
import { UserRole } from '@/store/user/user'
import RoutesList from '@/router'
import ThankYou from './pages/ThankYou'
import { useMicrosite, MicrisiteData } from '@/pages/RFQPortal/hooks/useMicrosite'

const RFQ404: FC = () => {
    return (
        <div>
            <h2>404 - Not Found</h2>
        </div>
    )
}

export const iframeSolution = window.self !== window.top

export interface IFilter {
    user: any
    start: any
    end: any
    status: any
    payment: any
}

async function setupData(iframeData: any = {}) {
    if (!iframeData.siteData.access_token) {
        return
    }
    try {
        setHttpV2Token(iframeData.siteData.access_token)
        const { data: myData } = await HttpV2.get('/auth/me')
        iframeData.account_data_SF = {
            ...myData,
            zipCode: myData.zip,
            title: myData.position,
        }
    } catch (e: any) {
        // if 401, navigate wp
        if (e.response.status === 401) {
            reloginNeeded()
        }
        console.error(e)
    }
}

export const RfqPortalRoutes: FC = () => {
    const params = useParams<any>()
    const { state } = useUserState()
    const location = useLocation()
    useDynamicHeightEvents()

    const { data } = useMicrosite()
    const micrositeData: MicrisiteData = data

    const { wpContextData, setWpContextData } = useWpData()

    const [filters, setFilters] = useState<any>({
        user: '',
        start: undefined,
        end: undefined,
        status: undefined,
        payment: undefined,
    })

    const [projectFilters, setProjectFilters] = useState<any>({
        user: '',
        start: undefined,
        end: undefined,
        status: undefined,
        payment: undefined,
    })

    const getIframeData = async (event: MessageEvent) => {
        if (event?.data?.iframeData) {
            const { iframeData } = event.data
            const { siteData: iframeSiteData } = iframeData

            const publicSites: (typeof iframeSiteData.SITE_SLUGS)[] = [
                'rfq',
                'on-demand-warehousing',
                'savealot',
                'evans',
            ]

            if (
                iframeSiteData.CLIENT_SLUG !== undefined &&
                iframeSiteData?.SITES_WITH_REGISTRATION?.includes(iframeSiteData.CLIENT_SLUG)
            ) {
                iframeSiteData.showRfqListLink = true
            } else {
                iframeSiteData.showRfqListLink = false
            }

            iframeSiteData.publicSites = publicSites

            await setupData(iframeData)
            setWpContextData(iframeData)
        }
    }

    useEffect(() => {
        if (iframeSolution) {
            window.addEventListener('message', getIframeData)

            console.log('ready to get notifications')

            // Always notify the parent that the iframe is ready to listen when receiving data!
            window.parent.postMessage({ type: 'getIframeDataIsReady' }, '*')

            // For local testing
            // if (window.top === window.self) {
            //     getIframeData({
            //         data: {
            //             iframeData: {
            //                 siteData: {
            //                     access_token:
            //                         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NjMwOCwicm9sZSI6MiwidHlwZSI6Im1hbmFnZXIiLCJhZG1pbiI6ZmFsc2UsInNmQWNjb3VudElkIjoiMDAxRG4wMDAwMEt0VjIySUFGIiwic2ZDb250YWN0SWQiOiIwMDNESjAwMDAwaGttSmVZQUkiLCJmaXJzdE5hbWUiOiJBRkFTRkFTIiwibGFzdE5hbWUiOiJBRkFTR0dTQUdTQSIsImVtYWlsIjoibWF4aW0uZ3JvbW92QG9saW1wd2FyZWhvdXNpbmcuY29tIiwicGhvbmUiOiIzMjQyMzQyMzQyIiwicG9zaXRpb24iOiJEZXYiLCJzdGF0ZSI6IkhJIiwiemlwIjpudWxsLCJjb21wYW55TmFtZSI6Ik1pcmlhbSBUZXN0IENvbXBhbnkiLCJhZGRpdGlvbmFsRW1haWwiOm51bGwsInZlcmlmaWVkIjp0cnVlLCJiYW5uZWQiOmZhbHNlLCJhY3RpdmUiOnRydWUsImlhdCI6MTcwMjAyNTYxOCwiZXhwIjoxNzAyNjMwNDE4fQ.M0VMLJzmAL2UKKmL17weI7cYTsSwvZArfrwEEfdCYps',
            //                     CLIENT_SLUG: 'rfq',
            //                     CLIENT_NAME: 'rfq',
            //                     CLIENT_SUPPORT_DATA: {
            //                         general: {},
            //                     },
            //                 },
            //                 account_data_SF: {},
            //             },
            //         },
            //     } as MessageEvent)
            // }

            return () => {
                window.removeEventListener('message', getIframeData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (state.isLoggedIn && state.userInfo?.role !== UserRole.FREIGHT_BROKER_MANAGER) {
        return <Navigate to="/" />
    }

    if (state.isLoggedIn && state.userInfo?.companySlug && state.userInfo?.companySlug !== params.slug) {
        // if user is logged in and company slug is different from the one in the url, or there is no one in url, redirect him and preserve query parameters
        let pathname = location.pathname
        // replace in pathname company/anything until slash
        pathname = pathname.replace(/ms\/[^/]+\/?/, '')
        return <Navigate to={`/ms/${state.userInfo?.companySlug}${pathname}${location.search}`} replace />
    }

    return (
        <ExpandedOppProvider>
            <RfqModalProvider>
                <div className="wrapper">
                    <Routes location={location}>
                        <Route
                            path="/"
                            element={
                                iframeSolution ? <IframedRfqHeader siteData={wpContextData?.siteData} /> : <RfqHeader />
                            }
                        >
                            <Route index element={iframeSolution ? <IframedRfqOrder /> : <RfqForm />} />
                            <Route path={RoutesList.rfq.thankYou} element={<ThankYou />} />
                            <Route
                                path={RoutesList.rfq.list}
                                element={<RFQList filters={filters} setFilters={setFilters} />}
                            />
                            <Route
                                path={RoutesList.rfq.projects}
                                element={<RFQProjectList filters={projectFilters} setFilters={setProjectFilters} />}
                            />
                            {/* <Route
                                    path={RoutesList.rfq.public.base}
                                    element={iframeSolution ? <IframedRfqOrder /> : <RfqOrder />}
                                />
                                <Route path={RoutesList.rfq.public.thankYou} element={<ThankYou />} /> */}
                            <Route path="*" element={<RFQ404 />} />
                        </Route>
                    </Routes>
                    <RfqModals />
                </div>
            </RfqModalProvider>
        </ExpandedOppProvider>
    )
}
