import { ServiceMethods } from '../index'
import { HandlingBreakdownComponent } from './BreakdownComponent'
import { HandlingFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/entities/rfq/model'

export const handlingService: ServiceMethods = {
    breakdownComponent: HandlingBreakdownComponent,
    formComponent: HandlingFormComponent,

    getDefaultService: () => {
        return {
            name: RfqService.Handling,
            type: null,
            pricings: {
                [RfqServicePricing.HandlingTotal]: { price: '' },
            },
        }
    },
}
