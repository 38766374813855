import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '@/shared/ui/Button'
import { useChat } from '@/entities/chat/api/useChat'
import { useUserState } from '@/store/user'
import { orderChatSlice } from '@/pages/Order/ui/OrderChat/model/slice'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { UserRole } from '@/store/user/user'
import { toast } from 'react-toastify'
import { ChatMessageDto, ChatMessagingMode } from '@/entities/chat/model/dto'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { DrawerContent, DrawerHandle, DrawerOverlay, DrawerPortal, DrawerRoot, DrawerTrigger } from '@/shared/ui/Drawer'
import { IoChatbox } from '@react-icons/all-files/io5/IoChatbox'
import { ChatWindow } from '@/pages/Order/ui/OrderChat/ui/ChatWindow'
import { cn } from '@/shared/utils/cn'
import { MessageProvider } from '@/entities/chat/api/singleMessageProvider'
import { ChatAction } from '@/entities/chat/model/enums'
import { notificationsSlice } from '@/entities/notifications/model/slice'
import './index.scss'
import { getDemandInfoByOrderReference } from '@/entities/chat/api/psmApi'
import { useMediaQuery } from '@/shared/hooks/useMediaQuery'
import { Badge } from '@/shared/ui/Badge'

const {
    addChatMessage,
    selectChat,
    setChatMessages,
    setMessagesSeen,
    setChatLoading,
    setCurrentChat,
    setParticipants,
    setAvailableChats,
    setDemandInfo,
} = orderChatSlice.actions

const { markMessagesSeen } = notificationsSlice.actions

export const OrderChat = ({
    reference,
    className,
    hide,
    addons,
    psmMode,
    messageBoard,
    viewportClassName,
}: {
    reference?: string
    className?: string
    viewportClassName?: string
    hide?: boolean
    messageBoard?: boolean
    psmMode?: boolean
    addons?: React.ReactNode
}) => {
    const vpRef = React.useRef<HTMLDivElement>(null)
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [thumbsById, setThumbsById] = useState<{ [key: number]: ArrayBuffer }>({})
    const isMobile = useMediaQuery('(max-width: 1140px)')

    const scrollBottom = () => {
        vpRef.current?.scrollTo({
            top: vpRef.current.scrollHeight,
            behavior: 'auto',
        })
    }

    const { state: userState } = useUserState()
    const isPSM = userState.userInfo?.role === UserRole.PSM
    const dispatch = useAppDispatch()
    const participants = useAppSelector((state) => state.orderChat.participants)
    const chatLoading = useAppSelector((state) => state.orderChat.chatLoading)
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const currentChat = useAppSelector((state) => state.orderChat.currentChat)
    const totalUnseen = useAppSelector((state) => state.orderChat.totalUnseen)
    const selectedChatRef = React.useRef<any>(null)
    useEffect(() => {
        selectedChatRef.current = selectedChat
    }, [selectedChat])

    const extractThumbs = (messages: ChatMessageDto[]) => {
        // need to store in map by id, and return cleaned up from msg.attachments[].thumb
        const thumbs: { [key: string]: ArrayBuffer } = thumbsById
        messages.forEach((msg) => {
            msg.attachments?.forEach?.((att) => {
                if (att.thumb) {
                    thumbs[att.id] = att.thumb
                    delete att.thumb
                }
            })
        })

        setThumbsById(thumbs)
    }

    const { getChat, getChats, sendMessage, connected, error, socket } = useChat({
        callbacks: {
            onError: (msg) => {
                toast(msg, {
                    type: 'error',
                })
            },
            onMessageReceived: (message) => {
                const matchByModeAndReference =
                    selectedChatRef.current === message.mode && reference === message.reference
                if (!matchByModeAndReference && message.chatId !== selectedChatRef.current) return

                extractThumbs([message])
                dispatch(addChatMessage(message))
                scrollBottom()
            },
            onChatReceived: ({ chat, messages, participants: chatParticipants }) => {
                dispatch(setChatLoading(false))

                if (
                    (chat.messagingMode === selectedChatRef.current || chat.id === selectedChatRef.current) &&
                    (!chat.reference || reference === chat.reference)
                ) {
                    dispatch(setCurrentChat(chat))
                    dispatch(setParticipants(chatParticipants))
                    if (chat && !selectedChatRef.current) {
                        dispatch(selectChat(chat.id))
                    }
                    extractThumbs(messages)
                    dispatch(setChatMessages({ messages, userId: userState.userInfo!.id }))
                    scrollBottom()
                }
            },
            onMessageSeen: ({ messageIds, participantId }) => {
                if (!isPSM && !participants.find((p) => p.managerId === participantId)) {
                    initialLoad()
                    return
                }
                dispatch(
                    setMessagesSeen({
                        messageIds,
                        participantId,
                    }),
                )
                // scrollBottom()
            },
            onChatsReceived: (chats) => {
                dispatch(setChatLoading(false))
                dispatch(setAvailableChats(chats))
            },
        },
    })

    const fetchDemandInfo = useCallback(async () => {
        if (!reference) return
        const info = await getDemandInfoByOrderReference(reference)
        if (selectedChat === 'DEMAND_PSM') {
            dispatch(setDemandInfo(info))
        }
    }, [dispatch, reference, selectedChat])

    useEffect(() => {
        if (socket?.connected) {
            if (typeof selectedChat === 'number' && isPSM) {
                dispatch(setChatLoading(true))
                dispatch(setDemandInfo(undefined))
                getChat(selectedChat)
            } else if (selectedChat === 'DEMAND_PSM' && isPSM) {
                dispatch(setChatMessages({ messages: [], userId: userState.userInfo!.id }))
                dispatch(setChatLoading(false))
                fetchDemandInfo()
            }
        }
    }, [socket?.connected, selectedChat, isPSM])

    const initialLoad = useCallback(() => {
        if (!reference) return
        if (socket?.connected && userState.userInfo && !chatLoading) {
            dispatch(setChatLoading(true))

            dispatch(setChatMessages({ messages: [], userId: userState.userInfo!.id }))
            if (isPSM) {
                getChats(reference)
            } else {
                dispatch(
                    selectChat(
                        userState.userInfo.role === UserRole.WAREHOUSE_MANAGER
                            ? ChatMessagingMode.SUPPLY_PSM
                            : ChatMessagingMode.DEMAND_PSM,
                    ),
                )
                getChat(reference)
            }
        }
    }, [
        socket?.connected,
        userState.userInfo,
        chatLoading,
        reference,
        currentChat?.reference,
        dispatch,
        isPSM,
        getChats,
        getChat,
    ])

    const handleMessagesSeen = (messageIds: number[]) => {
        if (userState.userInfo?.id) {
            dispatch(setMessagesSeen({ messageIds, participantId: userState.userInfo?.id }))
            dispatch(markMessagesSeen(messageIds))
        }
        if (socket) {
            socket.emit(ChatAction.SeenMessage, {
                messageIds,
            })
        }
    }

    const handleDrawerOpenChange = (open: boolean) => {
        if (open) {
            setTimeout(scrollBottom, 500)
        }
    }

    useEffect(() => {
        if (socket?.connected && reference && userState.userInfo) {
            initialLoad()
        }
    }, [socket?.connected, reference, userState.userInfo])

    if (hide) {
        return null
    }

    const chatWindow = reference ? (
        <MessageProvider onSeen={handleMessagesSeen}>
            <ChatWindow
                className={className}
                viewportClassName={viewportClassName}
                thumbsById={thumbsById}
                reference={reference}
                sendMessage={sendMessage}
                psmMode={psmMode}
                error={error}
                addons={addons}
                userState={userState}
                scrollBottom={scrollBottom}
                viewportRef={vpRef}
            />
        </MessageProvider>
    ) : null
    if (messageBoard) {
        return chatWindow
    }
    return (
        <>
            {!psmMode && isMobile && (
                <DrawerRoot direction="bottom" onOpenChange={handleDrawerOpenChange}>
                    <DrawerTrigger asChild>
                        <Button
                            className="rounded-full xl:hidden fixed right-5 bottom-5 w-12 h-12 p-0 text-lg bg-white text-primary shadow shadow-accent-dark"
                            variant="plain"
                        >
                            <IoChatbox className="w-6 h-6" />
                            {totalUnseen > 0 && (
                                <Badge className="circle px-2 fixed right-3 bottom-3" variant="danger" color="red">
                                    {totalUnseen}
                                </Badge>
                            )}
                        </Button>
                    </DrawerTrigger>
                    <DrawerPortal>
                        <DrawerOverlay />
                        <DrawerContent className="w-full top-[0px] max-h-[100vh]">{chatWindow}</DrawerContent>
                    </DrawerPortal>
                </DrawerRoot>
            )}
            {(!isMobile || psmMode) && (
                <div
                    className={cn('hidden xl:block w-[350px] 2xl:w-[510px]', {
                        'w-full 2xl:w-full': psmMode,
                        block: psmMode,
                        hidden: !psmMode,
                    })}
                >
                    {chatWindow}
                </div>
            )}
        </>
    )
}
