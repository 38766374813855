import useSWR from 'swr'
import { getSWRFetcher, HttpV2 } from '@shared/apiv2/api'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

export const useServicesApi = (accountId?: string) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const {
        data,
        error: dataError,
        revalidate,
    } = useSWR(accountId ? `accounts/${accountId}/warehouse/services` : null, getSWRFetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
    })

    const [loading, setLoading] = useState(false)
    const updateServices = async (services: any) => {
        if (!accountId) {
            toast('Account ID is required to update services')
            return
        }
        setLoading(true)
        try {
            await HttpV2.put(`accounts/${accountId}/warehouse/services`, services)
            await revalidate()
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    const dataLoading = !data && !dataError

    return {
        data: data || {
            services: [],
            envs: [],
            temperatures: [],
            vehicles: [],
            features: [],
            hazmatClasses: [],
            certs: [],
            equipments: [],
        },
        dataLoading,
        dataError,
        revalidate,
        updateServices,
        updateServicesLoading: loading,
    }
}
