import { useState } from 'react'

export type UseInnerOpenProps = {
    open?: boolean
    defaultOpen?: boolean
    onOpenChange?: (open: boolean) => void
}

/** Function that is used for semi-controlled management of UI components.
 *  Mostly used in Prefab UI components, such as DatePicker
 *  Allows to implement some unique features that can be implemented with controlled open/closed state only
 *  without passing open/onOpenChange props from outside
 *
 *  Examples:
 *  - shouldCloseOnSelect in DatePicker
 *  */
export const useInnerOpen = ({ open, defaultOpen, onOpenChange }: UseInnerOpenProps) => {
    const [innerOpen, setInnerOpen] = useState(defaultOpen ?? false)

    const onInnerOpenChange = (o: boolean) => {
        if (open === undefined) {
            setInnerOpen(o)
        }
        onOpenChange?.(o)
    }

    const actualOpen = open === undefined ? innerOpen : open

    return [actualOpen, onInnerOpenChange] as const
}
