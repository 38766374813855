// ManageStaffModal.tsx
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { toast } from 'react-toastify'
import Checkbox from '@/components/CheckboxNew'
import Search from '@/components/Search'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import useManageStaffColumns from './useManageStaffColumns'
import { usePermissions } from '@/hooks/usePermissions'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './ManageStaffModal.scss'

interface ManageStaffModalProps {
    modalTitle?: string
    contactId: string
    isAdmin: boolean
    triggerUpdateStaffList: () => void
}

export interface ManageStaffTableRecord {
    id: string
    name: string
    location: string
    visibility: boolean
    permission: PermissionLevel | ''
}

export enum PermissionLevel {
    User = 1, //Read
    Manager = 2, //Write
    Admin = 4,
}

const modalClass = 'manage-staff-modal'

const ManageStaffModal: FC<ManageStaffModalProps> = ({ modalTitle, contactId, isAdmin, triggerUpdateStaffList }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(isAdmin)
    const [mSTableData, setMSTableData] = useState<ManageStaffTableRecord[]>([])
    const [filter, setFilter] = useState('')
    const mainColumns = useManageStaffColumns(setMSTableData, isSuperAdmin)
    const {
        isPermissionsLoading,
        userPermissionsSending,
        fetchUserPermissions,
        updateUserPermissions,
        setSuperAdminPermissions,
    } = usePermissions()

    useEffect(() => {
        setIsSuperAdmin(isAdmin)
    }, [isAdmin])

    const fetchPermissions = async () => {
        const { data: relatedWarehouses } = await HttpV2.get('accounts/related')
        const permissionsData = await fetchUserPermissions(contactId, relatedWarehouses, isSuperAdmin)
        triggerUpdateStaffList()
        setMSTableData(permissionsData)
    }

    useEffect(() => {
        console.log('mSTableData', mSTableData)
    }, [mSTableData])

    useEffect(() => {
        fetchPermissions()
    }, [])

    const handleSavePermissions = async () => {
        await updateUserPermissions(contactId, isSuperAdmin, mSTableData, fetchPermissions)
        sendCustomEvent('user_settings_changed', {
            user: contactId,
            isSuperAdmin,
        })
        onCoseFunc()
    }

    const onCoseFunc = useCallback(() => {
        ModalRegistry.get().close(Modals.ManageStaffModal)
        sendCustomEvent('user_settings_change_cancelled')
    }, [])

    useEffect(() => {
        if (isSuperAdmin && contactId) {
            setMSTableData(setSuperAdminPermissions(mSTableData))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuperAdmin])

    return (
        <div className={`${modalClass}`}>
            {modalTitle && <div className="modal-header">{modalTitle}</div>}

            <Checkbox
                label="Organization Admin"
                checked={isSuperAdmin}
                onChange={(checked) => setIsSuperAdmin(checked)}
                className={`${modalClass}__isAdmin`}
            />
            <div className={`${modalClass}__search-wrapper`}>
                <p className={`${modalClass}__search-label`}>Select which warehouses this user has visibility on</p>
                <Search
                    className={`${modalClass}__search`}
                    onSearch={setFilter}
                    filterValue={filter}
                    label="Search"
                    onBlur={() =>
                        filter &&
                        sendCustomEvent('manage_staff_search', {
                            searchQuery: filter,
                        })
                    }
                />
            </div>
            <ModernTable
                columns={mainColumns}
                data={mSTableData}
                loading={isPermissionsLoading}
                total={mSTableData?.length}
                globalFilter={filter}
            />

            <div className={`${modalClass}__btns-wrapper modal-btns-wrapper`}>
                <Button types={['inverse']} label="Cancel" onClick={onCoseFunc} />
                <Button
                    types={['blue']}
                    label="Save"
                    onClick={handleSavePermissions}
                    loading={userPermissionsSending}
                    className={`${modalClass}__btn-save`}
                />
            </div>
        </div>
    )
}

ModalRegistry.get().register<ManageStaffModalProps>(Modals.ManageStaffModal, {
    id: 'ManageStaffModal',
    className: 'manage-staff-modal ',
    size: 'huge',
    Component: memo(ManageStaffModal),
})

export const showManageStaffModal = (props: ModalPartialProps<ManageStaffModalProps>): void => {
    ModalRegistry.get().show<ManageStaffModalProps>(Modals.ManageStaffModal, props)
}

export default memo(ManageStaffModal)
