import { Avatar, AvatarFallback } from '@/shared/ui/Avatar'
import React, { FC } from 'react'
import dayjs from 'dayjs'
import { ChatMessageDto } from '@/entities/chat/model/dto'
import cn from 'classnames'
import {
    ChatMessageFileAttachment,
    ChatMessageImgAttachment,
} from '@/pages/Order/ui/OrderChat/ui/ChatMessageAttachments'
import useMessageSeen from '@/entities/chat/api/useMessageSeen'
import { htmlifyMessage } from '@/pages/Order/ui/OrderChat/ui/chat.utils'
import { HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'

export const ChatMessage: FC<
    ChatMessageDto & {
        thumbs: { [key: number]: ArrayBuffer }
        userId?: number
        participants: any[]
        containerRef: React.RefObject<HTMLDivElement>
    }
> = ({
    id,
    seenBy,
    userId,
    participants,
    attachments,
    containerRef,
    thumbs,
    message,
    createdAt,
    name,
    nameShort,
    me,
}) => {
    const imgAttachments = attachments?.filter((attachment) => attachment.isImage) ?? []
    const otherFiles = attachments?.filter((attachment) => !attachment.isImage) ?? []
    const gridClasses = () => {
        if (imgAttachments.length === 1) return 'grid-cols-1'
        if (imgAttachments.length === 2) return 'grid-cols-2'
        if (imgAttachments.length >= 3) return 'grid-cols-2'
        return 'grid-cols-1' // Default fallback
    }

    const ref = useMessageSeen(id, seenBy ?? [], containerRef, !me ? userId : undefined)
    const seenByParticipants =
        seenBy?.map((seenById) => participants.find((p) => p.managerId === seenById))?.filter(Boolean) ?? []
    return (
        <div ref={ref} className={cn('flex gap-2 w-full justify-end', { 'flex-row-reverse': !me })}>
            <div className={cn(' flex flex-col gap-2 max-w-[70%]')}>
                <div className={cn(' flex gap-2', { 'justify-start': !me, 'flex-row-reverse': me })}>
                    {message && (
                        <div
                            className={cn(
                                ' p-3 border border-border font-medium break-words max-w-full olimp-chat-message',
                                {
                                    'bg-background-tertiary rounded-[20px_20px_20px_2px]': !me,
                                    'bg-primary rounded-[20px_20px_2px_20px] text-white': me,
                                },
                            )}
                            dangerouslySetInnerHTML={{
                                __html: htmlifyMessage(message),
                            }}
                        />
                    )}
                </div>

                {imgAttachments.length > 0 && (
                    <div className={cn('flex gap-2', { 'flex-row-reverse': me })}>
                        <div className={cn('grid gap-2', gridClasses())} style={{ direction: me ? 'rtl' : 'ltr' }}>
                            {imgAttachments.map(({ key, ...props }, index) => (
                                <ChatMessageImgAttachment key={key} index={index} {...props} thumb={thumbs[props.id]} />
                            ))}
                        </div>
                    </div>
                )}
                {otherFiles.length > 0 && (
                    <div className={cn('flex gap-2', { 'flex-row-reverse': me })}>
                        <div className="grid gap-2 grid-cols-1">
                            {otherFiles.map(({ key, ...props }, index) => (
                                <ChatMessageFileAttachment key={key} index={index} {...props} />
                            ))}
                        </div>
                    </div>
                )}
                <div className={cn(' flex gap-2 text-sm', { 'justify-start': !me, 'flex-row-reverse': me })}>
                    <div className="relative">
                        <span className="mr-2">{me ? 'You' : name}</span>
                        <span className="text-foreground-secondary text-sm">
                            {dayjs(createdAt).format('M/D/YYYY h:mm A')}
                        </span>
                        {seenByParticipants.length > 0 && me && (
                            <div
                                className={cn('flex gap-2 absolute left-0 bottom-0 top-0', {
                                    'justify-start': !me,
                                    'flex-row-reverse': me,
                                })}
                                style={{
                                    transform: 'translateX(calc(-100% - 4px))',
                                }}
                            >
                                <HintRoot>
                                    <HintTrigger asChild>
                                        <div className="flex gap-2 cursor-pointer">
                                            {seenByParticipants.map((participant) => (
                                                <Avatar
                                                    key={participant.managerId}
                                                    className="w-5 h-5 -ml-3 :first-child:ml-0 "
                                                >
                                                    <AvatarFallback className="text-[8px] font-semibold bg-white border text-black">
                                                        {participant.nameShort}
                                                    </AvatarFallback>
                                                </Avatar>
                                            ))}
                                        </div>
                                    </HintTrigger>
                                    <HintContent>
                                        <div className="flex gap-2 flex-col">
                                            <small className="block text-xs text-gray-950 pb-1 border-b border-gray">
                                                Seen by
                                            </small>
                                            {seenByParticipants.map((participant) => (
                                                <div key={participant.managerId}>{participant.fullName}</div>
                                            ))}
                                        </div>
                                    </HintContent>
                                </HintRoot>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={cn('self-end pb-[30px]')}>
                <Avatar>
                    <AvatarFallback className="text-sm">{nameShort}</AvatarFallback>
                </Avatar>
            </div>
        </div>
    )
}
