import { AxiosRequestConfig } from 'axios'
import { HttpV2 } from '@shared/apiv2/api'
import { HideLoadboardOrderRequest } from '@/entities/loadboard-orders/model/request'

const hideLoadboardOrder = async (req: HideLoadboardOrderRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post(
        `/load-board/order/${req.orderKey}/hide`,
        {
            reason: req.reason,
            reasonComment: req.reasonComment,
        },
        config,
    )
    return response
}

export const loadboardOrdersApi = {
    hideLoadboardOrder,
}
