import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'

export const DisposalBreakdownComponent = ({ order, service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex items-center">
            {service.pricings[RfqServicePricing.Disposal]?.price ? (
                <div className="flex gap-2 items-center w-full">
                    <CurrencyDisplay
                        value={service.pricings[RfqServicePricing.Disposal]!.price}
                        className="text-xl leading-6 text-primary font-semibold"
                    />
                    <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                    <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                        {service.pricings[RfqServicePricing.Disposal]!.modifier === 'pound'
                            ? 'Freight Weight (lb)'
                            : 'Pallet - Standard'}
                    </span>
                </div>
            ) : (
                <span className="text-foreground-secondary font-medium">N/A</span>
            )}
        </div>
    )
}
