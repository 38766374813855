import React, { FC, memo, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { useFormik } from 'formik'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'
import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import RfqDatepicker from '@/pages/RFQPortal/components/RfqDatepicker'
import { createFileList } from '@/utils/helper'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './InsuranceUploadModal.scss'
const CLASS_NAME = 'file-upload-modal'

interface InsuranceUploadModalProps {
    loading: boolean | undefined
    onSubmit: (files: FileList, expirationDate: string) => void
}

interface InsuranceUploadForm {
    file: File | undefined
    expirationDate: string | undefined
}

const InsuranceUploadFormSchema = Yup.object().shape({
    file: Yup.mixed().required('Please upload your certificate of insurance'),
    expirationDate: Yup.string().required('You must enter an expiration date.'),
})

const InsuranceUploadModal: FC<InsuranceUploadModalProps> = ({ loading = false, onSubmit }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false)

    const formik = useFormik<InsuranceUploadForm>({
        initialValues: {
            file: undefined,
            expirationDate: undefined,
        },
        validateOnChange: triedToSubmit,
        validateOnMount: false,
        validationSchema: InsuranceUploadFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')
            formik.setSubmitting(true)
            let success = false

            try {
                let fileList
                if (values.file) {
                    fileList = createFileList([values.file])
                }

                if (fileList && values.expirationDate) {
                    onSubmit(fileList, values.expirationDate)
                }

                ModalRegistry.get().close(Modals.InsuranceUploadModal)
                success = true
            } catch (errors: any) {
                formik.setSubmitting(false)

                if (errors && errors?.response) {
                    setErrorMessage(errors?.response?.data?.message)
                }
                success = false
            } finally {
                sendCustomEvent('insurance_uploaded', {
                    success,
                })
            }
        },
    })

    useEffect(() => {
        const modal = document.querySelector('.react-responsive-modal-modal') as HTMLElement
        if (modal) {
            modal.style.overflowY = 'visible'
        }
    }, [])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className={`${CLASS_NAME}__content`}>
                    <div className={`${CLASS_NAME}__dz-wrapper`}>
                        <DropZoneArea
                            filesCollection={formik.values.file ? [formik.values.file] : []}
                            changeFormData={(path, files) => formik.setFieldValue('file', files[0])}
                            allowedFormats={['image/jpeg', 'application/pdf']}
                            maxFiles={1}
                        />
                        <p className="error-message">{formik.errors?.file}</p>
                    </div>
                    <RfqDatepicker
                        label="Expiration Date*"
                        placeholder=""
                        handleChange={(dateObj) =>
                            formik.setFieldValue('expirationDate', dateObj.start?.format('MM/DD/YYYY'))
                        }
                        start={formik.values.expirationDate || undefined}
                        dateFormat={'MM/DD/YYYY'}
                        range={false}
                        errorMessage={formik.errors?.expirationDate}
                    />
                </div>
                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(Modals.InsuranceUploadModal)}
                    isSubmitting={loading}
                    disableSubmit={!formik.isValid}
                    errorMessage={errorMessage}
                    textSubmit="Upload"
                    stylesSubmit={{ width: '85px' }}
                    buttonsPosition="right"
                    onSubmitClick={() => setTriedToSubmit(true)}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<InsuranceUploadModalProps>(Modals.InsuranceUploadModal, {
    id: 'InsuranceeUploadModalProps',
    className: 'insurance-upload-modal',
    size: 'huge',
    Component: memo(InsuranceUploadModal),
    title: 'Upload Certificate of Insurance',
})

export const showInsuranceUploadModal = (props: ModalPartialProps<InsuranceUploadModalProps>): void =>
    ModalRegistry.get().show<InsuranceUploadModalProps>(Modals.InsuranceUploadModal, props)

export default memo(InsuranceUploadModal)
