import * as React from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'

import { cn } from '@/shared/utils/cn'

type TDrawerContext = { direction: 'top' | 'right' | 'bottom' | 'left' }
const DrawerContext = React.createContext<TDrawerContext>({ direction: 'bottom' })

const DrawerRoot = ({ direction, children, ...props }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
    <DrawerPrimitive.Root direction={direction} {...props} noBodyStyles>
        <DrawerContext.Provider value={{ direction: direction ?? 'bottom' }}>{children}</DrawerContext.Provider>
    </DrawerPrimitive.Root>
)
DrawerRoot.displayName = 'DrawerRoot'

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-50 bg-black/80', className)} {...props} />
))
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

const DrawerContent = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, ...props }, ref) => {
    const { direction } = React.useContext(DrawerContext)

    return (
        <DrawerPrimitive.Content
            ref={ref}
            className={cn(
                'fixed z-50 flex h-auto flex-col border bg-background-secondary',
                {
                    'top-0 inset-x-0 rounded-b-xl': direction === 'top',
                    'right-0 inset-y-0': direction === 'right',
                    'bottom-0 inset-x-0 rounded-t-xl': direction === 'bottom',
                    'left-0 inset-y-0': direction === 'left',
                },
                className,
            )}
            {...props}
        />
    )
})
DrawerContent.displayName = 'DrawerContent'

const DrawerHandle = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Handle>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Handle>
>(({ className, ...props }, ref) => {
    const { direction } = React.useContext(DrawerContext)

    return (
        <DrawerPrimitive.Handle
            className={cn(
                '!absolute !rounded-full bg-accent-light',
                {
                    '!h-1 !w-20 bottom-4 inset-x-0 !mx-auto': direction === 'top',
                    '!h-20 !w-1 left-4 top-1/2 -translate-y-1/2': direction === 'right',
                    '!h-1 !w-20 top-4 inset-x-0 !my-auto': direction === 'bottom',
                    '!h-20 !w-1 right-4 top-1/2 -translate-y-1/2': direction === 'left',
                },
                className,
            )}
            {...props}
        />
    )
})
DrawerContent.displayName = 'DrawerContent'

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col space-y-1.5 sm:text-left', className)} {...props} />
)
DrawerHeader.displayName = 'DrawerHeader'

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('mt-auto flex flex-col gap-2 p-3', className)} {...props} />
)
DrawerFooter.displayName = 'DrawerFooter'

const DrawerTitle = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Title
        ref={ref}
        className={cn('text-lg font-semibold leading-none tracking-tight', className)}
        {...props}
    />
))
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

const DrawerDescription = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Description ref={ref} className={cn('text-sm text-foreground-secondary', className)} {...props} />
))
DrawerDescription.displayName = DrawerPrimitive.Description.displayName

export {
    DrawerRoot,
    DrawerTrigger,
    DrawerPortal,
    DrawerOverlay,
    DrawerContent,
    DrawerHandle,
    DrawerClose,
    DrawerHeader,
    DrawerFooter,
    DrawerTitle,
    DrawerDescription,
}
