import { RfqService } from '@/entities/rfq/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

export const getServicesFromOrder = (order: LoadboardOrder) => {
    const s = order.services.split(',')
    if (!s.includes(RfqService.CrossDocking) && !s.includes(RfqService.Transloading)) {
        return [RfqService.Handling, ...s]
    }
    return s
}
