import * as React from 'react'
import { cn } from '@/shared/utils/cn'
import { isBoolean } from 'lodash'

const ErrorMessage = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...props }, ref) => {
        if (children === null || children === undefined || isBoolean(children)) {
            return null
        }

        if (typeof children === 'string' && !children.trim()) {
            return null
        }

        return (
            <div className={cn('text-sm text-danger', className)} ref={ref} {...props}>
                {children}
            </div>
        )
    },
)
ErrorMessage.displayName = 'ErrorMessage'

export { ErrorMessage }
