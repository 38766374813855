import * as React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { InputField, InputIcon, InputLabel, InputWrapper } from '@/shared/ui/Input'
import { v4 } from 'uuid'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'
import { cn } from '@/shared/utils/cn'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { useRef } from 'react'
import { autoUpdate, size, useFloating } from '@floating-ui/react'

const CurrencyDisplay = React.forwardRef<HTMLSpanElement, Omit<NumberFormatProps, 'customInput' | 'displayType'>>(
    ({ prefix = '$', thousandSeparator = true, decimalScale = 2, fixedDecimalScale = true, ...props }, ref) => (
        <NumberFormat
            getInputRef={ref}
            displayType="text"
            prefix={prefix}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            {...props}
        />
    ),
)

const CurrencyInputField = React.forwardRef<HTMLInputElement, Omit<NumberFormatProps, 'customInput' | 'displayType'>>(
    ({ prefix = '$', thousandSeparator = true, decimalScale = 2, fixedDecimalScale = true, ...props }, ref) => (
        <NumberFormat
            getInputRef={ref}
            displayType="input"
            customInput={InputField}
            prefix={prefix}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            {...props}
        />
    ),
)

export type CurrencyInputProps = Omit<NumberFormatProps, 'customInput' | 'displayType'> & {
    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof InputWrapper>, 'className'>

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof InputLabel>, 'className'>

    icon?: React.ReactNode
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof InputIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>
}

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
    (
        {
            wrapperClassName,
            wrapperProps,

            label,
            labelClassName,
            labelProps,

            icon,
            iconWrapperClassName,
            iconWrapperProps,

            error,
            errorClassName,
            errorProps,

            id,
            className,

            ...inputProps
        },
        ref,
    ) => {
        const actualId = React.useRef(id ?? v4())

        const { refs } = useFloating({
            whileElementsMounted: autoUpdate,
            middleware: [
                size({
                    apply: ({ elements, rects }) => {
                        const { width: inputWidth, height: inputHeight } = rects.reference
                        const contentStyle = elements.floating.style
                        contentStyle.setProperty('--input-field-width', `${inputWidth}px`)
                        contentStyle.setProperty('--input-field-height', `${inputHeight}px`)
                    },
                }),
            ],
        })
        const composedInputRef = useComposedRefs(ref, (node) => refs.setReference(node))
        const composedWrapperRef = useComposedRefs(iconWrapperProps?.ref, (node) => refs.setFloating(node))

        return (
            <InputWrapper className={wrapperClassName} {...wrapperProps} ref={composedWrapperRef}>
                {label !== undefined ? (
                    <InputLabel
                        htmlFor={actualId.current}
                        className={cn(!!error && 'text-danger', labelClassName)}
                        {...labelProps}
                    >
                        {label}
                    </InputLabel>
                ) : null}

                <CurrencyInputField
                    ref={composedInputRef}
                    id={actualId.current}
                    className={cn(!!error && 'border-danger', className)}
                    {...inputProps}
                />

                {icon !== undefined ? (
                    <InputIcon
                        className={cn(
                            'h-[var(--input-field-height)] left-[var(--input-field-width)] -translate-x-full',
                            iconWrapperClassName,
                        )}
                        {...iconWrapperProps}
                    >
                        {icon}
                    </InputIcon>
                ) : null}

                <ErrorMessage className={cn('ml-3 mt-0.5', errorClassName)} {...errorProps}>
                    {error}
                </ErrorMessage>
            </InputWrapper>
        )
    },
)
CurrencyInput.displayName = 'CurrencyInput'

export { CurrencyDisplay, CurrencyInput }
