const reportWebVitals = (onPerfEntry?: any): void => {
    if (onPerfEntry) {
        import('web-vitals').then((module) => {
            const { onCLS, onFID, onFCP, onLCP, onTTFB } = module
            onCLS(onPerfEntry)
            onFID(onPerfEntry)
            onFCP(onPerfEntry)
            onLCP(onPerfEntry)
            onTTFB(onPerfEntry)
        })
    }
}

export default reportWebVitals
