import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Quote } from '@/entities/quotes/model'
import { RfqService } from '@/entities/rfq/model'
import { StandardQuoteValues } from '../model'
import { getServicesFromOrder, getDefaultSQService, isFloorLoaded } from './index'

type GetSQValuesFromQuoteArguments = {
    order: LoadboardOrder
    quote: Quote
    userType?: 'supply' | 'demand'
    mode?: 'read' | 'write'
}

export const getSQValuesFromQuote = ({ order, quote, userType, mode }: GetSQValuesFromQuoteArguments) => {
    const services = getServicesFromOrder(order)
    const isUnitFloorLoaded = isFloorLoaded(order.unitType)
    const values: StandardQuoteValues = {}

    for (const service of services) {
        const existingService = quote.services.find((s) => s.name === service)

        const defaultService = getDefaultSQService(service, isUnitFloorLoaded, mode, existingService)

        if (!existingService) {
            values[service as RfqService] = defaultService
            continue
        }

        for (const pricing of existingService.pricings) {
            const defaultPricing = defaultService.pricings[pricing.name]

            if (!defaultPricing) continue

            defaultService.pricings[pricing.name] = {
                ...defaultService.pricings[pricing.name],
                price: (userType === 'supply' ? pricing.buyPrice?.toString() : pricing.sellPrice?.toString()) ?? '',
                modifier: pricing.modifier ?? null,
                ...(userType === 'supply' && {
                    active: defaultService.pricings[pricing.name].active === undefined ? undefined : !!pricing.buyPrice,
                }),
            }
        }

        values[service as RfqService] = defaultService
    }

    return values
}
