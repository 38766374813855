import { createAsyncThunk } from '@reduxjs/toolkit'
import { Quote, QuoteStatus, SubmitQuoteRequest, UpdateQuoteRequest } from '@/entities/quotes/model'
import { quotesApi } from '@/entities/quotes/api'
import { addInactiveQuote, setQuoteMode, setActiveQuote } from './slice'
import { orderPageActions } from '@/pages/Order/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

export const submitQuote = createAsyncThunk<any, SubmitQuoteRequest>(
    'quote/submit',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await quotesApi.submitQuote(body)

            dispatch(orderPageActions.addQuote(response.data))
            dispatch(setActiveQuote(response.data))
        } catch (e: any) {
            if (e?.response?.data?.message) {
                return rejectWithValue(e.response.data)
            }

            return rejectWithValue({ message: 'We could not submit your quote. We are sorry!' })
        }
    },
)

export const updateQuote = createAsyncThunk<any, UpdateQuoteRequest>(
    'quote/update',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await quotesApi.updateQuote(body)

            dispatch(orderPageActions.setQuote({ quoteId: body.quoteId, quote: response.data }))
            dispatch(setActiveQuote(response.data))

            dispatch(setQuoteMode('default'))
        } catch (e: any) {
            if (e?.response?.data?.message) {
                return rejectWithValue(e.response.data)
            }

            return rejectWithValue({ message: 'We could not updated your quote. We are sorry!' })
        }
    },
)

export const reviseQuote = createAsyncThunk<any, UpdateQuoteRequest>(
    'quote/revise',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await quotesApi.reviseQuote(body)

            dispatch(orderPageActions.addQuote(response.data[0]))
            dispatch(setActiveQuote(response.data[0]))

            dispatch(orderPageActions.setQuote({ quoteId: body.quoteId, quote: response.data[1] }))
            dispatch(addInactiveQuote(response.data[1]))

            dispatch(setQuoteMode('default'))
        } catch (e: any) {
            if (e?.response?.data?.message) {
                return rejectWithValue(e.response.data)
            }

            return rejectWithValue({ message: 'We could not revise your quote. We are sorry!' })
        }
    },
)

export const cancelQuote = createAsyncThunk<void, void>(
    'quote/cancel',
    async (body, { dispatch, rejectWithValue, getState }) => {
        try {
            const order: LoadboardOrder = (getState() as any).orderPage.order
            const quote: Quote = (getState() as any).orderQuoteSupply.activeQuote

            await quotesApi.cancelQuote({ quoteId: quote.id, orderKey: order.id })
            const cancelledQuote = { ...quote, status: QuoteStatus.WAREHOUSE_CANCELLED }

            dispatch(setActiveQuote(null))
            dispatch(addInactiveQuote(cancelledQuote))
            dispatch(orderPageActions.setQuote({ quoteId: quote.id, quote: cancelledQuote }))
        } catch (e: any) {
            if (e?.response?.data?.message) {
                return rejectWithValue(e.response.data)
            }

            return rejectWithValue({ message: 'We could not cancel your quote. We are sorry!' })
        }
    },
)
