import React, { FC, useEffect, useState, useRef } from 'react'
import Button from '@/components/Button'
import Schedule from '@/components/Schedule'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { Days } from '@/components/Schedule/enums'
import { Hours, convertTimeToDisplayFormat } from '@/components/Schedule/enums'
import { toast } from 'react-toastify'
import { removeIdFromNewObjects, generateFiveDigitId } from './helpers'
import Progress from '@/components/ModernTable/Progress'
import { Loader } from '@/components/Loader'
import { ValidateSchedule } from '@/components/Schedule'
import { isNameUnique } from './helpers'
import useMobileScreen from '@/hooks/useMobileScreen'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './WarehouseGeneral.scss'

interface WarehousesScheduleProps {
    warehouseId: string | null
    loading?: boolean
    manager?: boolean
    description?: string
}

export interface WarehouseScheduleItem {
    id: number
    name: string
    days?: Days[]
    from: Hours | string
    to: Hours | string
}

const createNewScheduleItem = (): WarehouseScheduleItem => {
    return { id: generateFiveDigitId(), name: '', days: [], from: '12:00am', to: '12:00am' }
}

const initialSchedule: WarehouseScheduleItem[] = [
    {
        id: generateFiveDigitId(),
        name: 'After Hours',
        days: [],
        from: '',
        to: '',
    },
]

const card = 'app-card'
const warehouseSchedule = 'warehouses-schedule'

const WarehouseSchedule: FC<WarehousesScheduleProps> = ({ warehouseId, loading, description, manager }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const isMobile = useMobileScreen({ screenWidth: 1140 })

    const resetBtnErrorRef = useRef<HTMLDivElement>(null)
    const saveBtnErrorRef = useRef<HTMLDivElement>(null)

    const [isScheduleLoading, setIsScheduleLoading] = useState<boolean>(true)
    const [isScheduleSubmiting, setIsScheduleSubmiting] = useState<boolean>(false)
    const [isScheduleReseting, setIsScheduleReseting] = useState<boolean>(false)

    const [currentSchedule, setCurrentSchedule] = useState<WarehouseScheduleItem[]>(initialSchedule)
    const [newSchedule, setNewSchedule] = useState<WarehouseScheduleItem[]>(currentSchedule)

    const [validateSchedules, setValidateSchedules] = useState<ValidateSchedule[]>([])

    const updateNewScheduleField = (id: number, field: string, value: string) => {
        if (field === 'name' && !isNameUnique(value, newSchedule)) {
            toast.error('Schedule name must be unique.')
            return
        }

        setNewSchedule((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    if (field === 'from' || field === 'to') {
                        const convertedValue = Object.entries(Hours).find(([, val]) => val === value)?.[0] || value
                        return { ...item, [field]: convertedValue }
                    }
                    return { ...item, [field]: value }
                }
                return item
            }),
        )
    }

    const onDelete = (id: number) => {
        setNewSchedule((prev) => prev.filter((item) => item.id !== id))
    }

    const addScheduleItem = () => {
        setNewSchedule((prev) => [...prev, createNewScheduleItem()])
        sendCustomEvent('add_schedule_clicked')
    }

    const handleCancell = () => {
        setNewSchedule(currentSchedule)
    }

    const fetchWarehouseSchedule = async () => {
        try {
            setIsScheduleLoading(true)
            let { data }: any = await HttpV2.get(`/accounts/${warehouseId}/warehouse/working-time`)

            data = data.map((item: any) => {
                if (item.days?.length < 1) {
                    return { ...item, days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] }
                }

                return item
            })

            const afterHoursArray = data.filter((item: WarehouseScheduleItem) => item.name === 'After Hours')
            if (afterHoursArray.length > 0) {
                setCurrentSchedule(data)
            } else {
                setCurrentSchedule((prevData) => {
                    return [...prevData.filter((item) => item.name === 'After Hours'), ...data]
                })
            }
            return { status: 'success', data: data }
        } catch (error: any) {
            return { status: 'error', message: error.message }
        } finally {
            setIsScheduleLoading(false)
        }
    }

    const updateWarehouseSchedule = async (path: string, schedule: WarehouseScheduleItem[] = []) => {
        if (saveBtnErrorRef.current) {
            saveBtnErrorRef.current.innerHTML = ''
            saveBtnErrorRef.current.classList.remove('active')
        }

        if (resetBtnErrorRef.current) {
            resetBtnErrorRef.current.innerHTML = ''
            resetBtnErrorRef.current.classList.remove('active')
        }

        let ref: React.RefObject<HTMLDivElement> | null = null
        if (path === '/warehouse/working-time') {
            setIsScheduleSubmiting(true)
            ref = saveBtnErrorRef
        } else if (path === '/warehouse/working-time/reset') {
            setIsScheduleReseting(true)
            ref = resetBtnErrorRef
        }

        try {
            let payload: WarehouseScheduleItem[] = structuredClone(schedule)
            payload = removeIdFromNewObjects(payload)

            payload = payload.filter((item: WarehouseScheduleItem) => item.from && item.to)
            payload = payload.map((item: any) =>
                !item.days || item.days?.length < 1
                    ? { ...item, days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] }
                    : item,
            )

            await HttpV2.put(`/accounts/${warehouseId}${path}`, payload)
            await fetchWarehouseSchedule()

            toast('Schedule updated successfully!', { type: 'success' })
        } catch (error: any) {
            const errorMessage = error.response?.data?.message

            if (ref && ref.current) {
                ref.current.innerHTML = errorMessage
                ref.current.classList.add('active')
            }
        } finally {
            if (path === '/warehouse/working-time') {
                setIsScheduleSubmiting(false)
            } else if (path === '/warehouse/working-time/reset') {
                setIsScheduleReseting(false)
            }
        }
    }

    const handlSubmit = async (e: any) => {
        e.preventDefault()

        const isValidForm = validateSchedules.every((validation) => validation.func())

        const updatedSchedule = newSchedule.map((item) => {
            if (item.days && item.days.length === 0) {
                const { days, ...updatedItem } = item
                return updatedItem
            }
            return item
        })

        if (isValidForm) {
            await updateWarehouseSchedule('/warehouse/working-time', updatedSchedule)
            sendCustomEvent('warehouse_schedule_updated')
        }
    }

    const handleResetToOrgDefault = async () => {
        await updateWarehouseSchedule('/warehouse/working-time/reset')
    }

    useEffect(() => {
        fetchWarehouseSchedule()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId])

    useEffect(() => {
        setNewSchedule(currentSchedule)
    }, [currentSchedule])

    const afterHoursSchedule = newSchedule.find((item) => item.name === 'After Hours')
    const labelName = isMobile ? 'Add' : 'Add Schedule'

    return (
        <form className={warehouseSchedule}>
            <div className={`${card}__header bordered-block`}>
                <p className={`${card}__title`}>Weekly Schedule</p>
                {manager && <Button type="button" types={['bordered']} label={labelName} onClick={addScheduleItem} />}
            </div>
            {description && <p className={`${card}__description`}>{description}</p>}
            {loading ? (
                <Loader type="large" />
            ) : (
                <>
                    <div className={`${warehouseSchedule}__info`}>
                        {isScheduleLoading || !warehouseId ? (
                            <div className="olimp-table__loading">
                                <Progress />
                            </div>
                        ) : (
                            newSchedule.length > 0 &&
                            newSchedule
                                .filter((item) => item.name !== 'After Hours')
                                .reverse()
                                .map((item: WarehouseScheduleItem) => {
                                    return (
                                        <Schedule
                                            key={item.id}
                                            id={item.id}
                                            name={item.name}
                                            disabled={!manager}
                                            days={item.days || []}
                                            from={convertTimeToDisplayFormat(item.from)}
                                            to={convertTimeToDisplayFormat(item.to)}
                                            onDelete={() => onDelete(item.id)}
                                            updateSchedule={updateNewScheduleField}
                                            setValidateSchedules={setValidateSchedules}
                                        />
                                    )
                                })
                        )}
                        {afterHoursSchedule && (
                            <Schedule
                                disabled={!manager}
                                key={afterHoursSchedule.id}
                                id={afterHoursSchedule.id}
                                name={afterHoursSchedule.name}
                                days={afterHoursSchedule.days || []}
                                from={convertTimeToDisplayFormat(afterHoursSchedule.from)}
                                to={convertTimeToDisplayFormat(afterHoursSchedule.to)}
                                updateSchedule={updateNewScheduleField}
                                disabledInputs={['schedule-name']}
                                setValidateSchedules={setValidateSchedules}
                            />
                        )}
                    </div>
                    {/* <div className={`${warehouseSchedule}__buttons`}> */}
                    {/* TODO: temporary hide reset */}
                    {/* <div>
                            {isScheduleReseting ? (
                                <p className={`${warehouseSchedule}__loader`}>
                                    <Loader />
                                </p>
                            ) : (
                                <Button
                                    className={`${warehouseSchedule}__reset-btn`}
                                    type="button"
                                    label="Reset All to Organization Default"
                                    onClick={handleResetToOrgDefault}
                                />
                            )}
                            <p className={`${warehouseSchedule}__reset-btn-error`} ref={resetBtnErrorRef}></p>
                        </div> */}
                    {currentSchedule !== newSchedule && manager && (
                        <div className={`${warehouseSchedule}__cancel-save-btns`}>
                            <Button
                                className={`${warehouseSchedule}__cancel-btn`}
                                type="button"
                                types={['bordered']}
                                label="Cancel"
                                onClick={handleCancell}
                            />
                            <Button
                                className={`${warehouseSchedule}__save-btn`}
                                types={['blue']}
                                type="submit"
                                label="Save Changes"
                                onClick={handlSubmit}
                                loading={isScheduleSubmiting}
                            />
                        </div>
                    )}
                    {/* </div> */}
                </>
            )}
        </form>
    )
}

export default WarehouseSchedule
