import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import {
    PopoverRoot,
    PopoverContent,
    PopoverTrigger,
    PopoverTriggerLabel,
    PopoverTriggerWrapper,
    PopoverTriggerIcon,
    PopoverArrow,
} from '@/shared/ui/Popover'
import { Calendar } from '@/shared/ui/Calendar'
import {
    ActiveModifiers,
    DateRange,
    DayPickerMultipleProps,
    DayPickerRangeProps,
    DayPickerSingleProps,
    Matcher,
} from 'react-day-picker'
import { format } from 'date-fns'
import { InCalendar } from '@/shared/ui/Icons/InCalendar'
import { isArray } from 'lodash'
import { useInnerOpen } from '@/shared/hooks/useInnerOpen'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'
import { useRef } from 'react'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { autoUpdate, size, useFloating } from '@floating-ui/react'

export type DatePickerProps = {
    disabled?: boolean
    datesDisabled?: Matcher | Matcher[] | undefined

    shouldCloseOnSelect?: boolean

    placeholder?: React.ReactNode
    renderValue?: (selected: Date | Date[] | DateRange | undefined) => React.ReactNode

    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof PopoverTriggerWrapper>, 'className'>

    triggerClassName?: string
    triggerProps?: Omit<React.ComponentProps<typeof PopoverTrigger>, 'className' | 'disabled'>
    renderTrigger?: (selected: Date | Date[] | DateRange | undefined) => React.ReactNode

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof PopoverTriggerLabel>, 'className'>

    icon?: React.ReactNode
    iconClassName?: string
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof PopoverTriggerIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>

    popoverContentClassName?: string
    popoverContentProps?: Omit<React.ComponentProps<typeof PopoverContent>, 'className'>

    hidePopoverArrow?: boolean
    popoverArrowClassName?: string
    popoverArrowProps?: Omit<React.ComponentProps<typeof PopoverArrow>, 'className'>
} & (DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps) &
    Omit<React.ComponentProps<typeof PopoverRoot>, 'children'>

export type OnInnerSelect = (
    date: Date | Date[] | DateRange | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: React.MouseEvent,
) => void

export function DatePicker({
    disabled,
    datesDisabled,

    open,
    onOpenChange,
    defaultOpen,
    modal,

    placeholder,
    renderValue,

    wrapperClassName,
    wrapperProps,

    triggerClassName,
    triggerProps,
    renderTrigger,

    label,
    labelClassName,
    labelProps,

    icon,
    iconClassName,
    iconWrapperClassName,
    iconWrapperProps,

    error,
    errorClassName,
    errorProps,

    popoverContentClassName,
    popoverContentProps,

    hidePopoverArrow,
    popoverArrowClassName,
    popoverArrowProps,

    onSelect,
    shouldCloseOnSelect = false,

    ...calendarProps
}: DatePickerProps) {
    const { refs } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply: ({ elements, rects }) => {
                    const { width: triggerWidth, height: triggerHeight } = rects.reference
                    const contentStyle = elements.floating.style
                    contentStyle.setProperty('--datepicker-trigger-width', `${triggerWidth}px`)
                    contentStyle.setProperty('--datepicker-trigger-height', `${triggerHeight}px`)
                },
            }),
        ],
    })
    const composedTriggerRef = useComposedRefs(triggerProps?.ref, (node) => refs.setReference(node))
    const composedWrapperRef = useComposedRefs(wrapperProps?.ref, (node) => refs.setFloating(node))

    const Comp = React.useMemo(() => {
        switch (calendarProps.mode) {
            case 'single':
                return DatePickerSingleValue
            case 'multiple':
                return DatePickerMultipleValue
            case 'range':
                return DatePickerRangeValue
        }
    }, [calendarProps.mode])

    const [actualOpen, actualOnOpenChange] = useInnerOpen({ open, defaultOpen, onOpenChange })

    /** Use function below for some additional actions before actually submitting it to the outside onSelect */
    const onInnerSelect: OnInnerSelect = (d, selectedDay, activeModifiers, e) => {
        if (calendarProps.mode === 'single') {
            const date = d as Date | undefined
            if (shouldCloseOnSelect && date) {
                actualOnOpenChange(false)
            }
        }

        /** Commented template. Uncomment if something unique is required to do with multiple DatePicker */
        // if (calendarProps.mode === 'multiple') {
        //     const dates = d as Date[] | undefined
        // }

        if (calendarProps.mode === 'range') {
            const range = d as DateRange | undefined
            if (shouldCloseOnSelect && range?.from && range?.to) {
                actualOnOpenChange(false)
            }
        }

        onSelect?.(d as any, selectedDay, activeModifiers, e)
    }

    return (
        <PopoverRoot open={actualOpen} onOpenChange={actualOnOpenChange} defaultOpen={defaultOpen} modal={modal}>
            <PopoverTriggerWrapper className={wrapperClassName} {...wrapperProps} ref={composedWrapperRef}>
                {label !== undefined ? (
                    <PopoverTriggerLabel
                        htmlFor={'123'}
                        className={cn(!!error && 'text-danger', labelClassName)}
                        {...labelProps}
                    >
                        {label}
                    </PopoverTriggerLabel>
                ) : null}

                {renderTrigger ? (
                    <PopoverTrigger asChild>{renderTrigger(calendarProps.selected)}</PopoverTrigger>
                ) : (
                    <PopoverTrigger
                        id={'123'}
                        className={cn(
                            !!error && 'border-danger',
                            'justify-start text-left !font-normal data-[state=open]:border-primary',
                            !calendarProps.selected && 'text-foreground-primary/70',
                            isArray(calendarProps.selected) &&
                                !calendarProps.selected.length &&
                                'text-foreground-primary/70',
                            triggerClassName,
                        )}
                        disabled={disabled}
                        {...triggerProps}
                        ref={composedTriggerRef}
                    >
                        {renderValue ? (
                            renderValue(calendarProps.selected)
                        ) : (
                            <Comp date={calendarProps.selected as any} placeholder={placeholder} />
                        )}
                    </PopoverTrigger>
                )}

                <PopoverTriggerIcon
                    className={cn(
                        'h-[var(--datepicker-trigger-height)] left-[var(--datepicker-trigger-width)] -translate-x-full',
                        iconWrapperClassName,
                    )}
                    {...iconWrapperProps}
                >
                    {icon !== undefined ? (
                        icon
                    ) : (
                        <InCalendar size={16} className={cn('mr-2 text-foreground-secondary/70', iconClassName)} />
                    )}
                </PopoverTriggerIcon>

                <ErrorMessage className={cn('ml-3 mt-0.5', errorClassName)} {...errorProps}>
                    {error}
                </ErrorMessage>
            </PopoverTriggerWrapper>
            <PopoverContent className={cn('w-auto p-0', popoverContentClassName)} {...popoverContentProps}>
                {hidePopoverArrow ? null : <PopoverArrow className={popoverArrowClassName} {...popoverArrowProps} />}
                <Calendar onSelect={onInnerSelect} disabled={datesDisabled} {...calendarProps} />
            </PopoverContent>
        </PopoverRoot>
    )
}

const DatePickerSingleValue = ({ date, placeholder }: { date?: Date; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow justify-start overflow-hidden">
            {date ? format(date, 'PPP') : placeholder ?? 'Pick a date'}
        </span>
    </div>
)

const DatePickerMultipleValue = ({ date, placeholder }: { date?: Date[]; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow">
            {date && date.length > 0
                ? `${date.length} date${date.length > 1 ? 's' : ''} selected`
                : placeholder ?? 'Pick dates'}
        </span>
    </div>
)

const DatePickerRangeValue = ({ date, placeholder }: { date?: DateRange; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow justify-start overflow-hidden">
            {date?.from ? (
                date.to ? (
                    <>
                        {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                    </>
                ) : (
                    format(date.from, 'LLL dd, y')
                )
            ) : (
                placeholder ?? 'Pick a range'
            )}
        </span>
    </div>
)
