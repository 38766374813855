import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { ChatAction, ChatEvent } from '@/entities/chat/model/enums'

const BATCH_SIZE = 10 // Number of messages to batch
const INACTIVITY_TIMEOUT = 2000 // 1 second of inactivity

const MessageContext = createContext({
    unseenMessagesRef: null as any,
    debouncedSendUnseenMessages: debounce(() => {}, INACTIVITY_TIMEOUT),
    sendUnseenMessages: () => {},
})

export const MessageProvider = ({ children, onSeen }: any) => {
    const unseenMessagesRef = useRef<number[]>([])

    const sendUnseenMessages = useCallback(() => {
        if (unseenMessagesRef.current.length > 0) {
            onSeen(unseenMessagesRef.current)
            unseenMessagesRef.current = [] // Clear buffer after sending
        }
    }, [onSeen])

    const debouncedSendUnseenMessages = debounce(sendUnseenMessages, INACTIVITY_TIMEOUT)

    useEffect(() => {
        return () => {
            debouncedSendUnseenMessages.cancel() // Cancel any pending debounce
            sendUnseenMessages() // Send any remaining unseen messages
        }
    }, [debouncedSendUnseenMessages, sendUnseenMessages])

    return (
        <MessageContext.Provider value={{ unseenMessagesRef, debouncedSendUnseenMessages, sendUnseenMessages }}>
            {children}
        </MessageContext.Provider>
    )
}

export const useMessageContext = () => useContext(MessageContext)
