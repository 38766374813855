import { StandardQuoteService, StandardQuoteValues } from '@/pages/Order/ui/OrderQuote/model'
import { RfqService, RfqServicePricing } from '@/entities/rfq/model'
import { StandardQuoteServicePricingDto, StandardQuoteServiceDto } from '@/entities/quotes/model'

export const mapSQValuesToSend = (services: StandardQuoteValues): StandardQuoteServiceDto[] => {
    const servicesToSubmit: StandardQuoteServiceDto[] = []

    Object.entries(services).forEach(([sKey, service]) => {
        const pricings = pickServicePricings(service)
        const mappedPricings = Object.entries(pricings).map(([pKey, pricing]) => {
            return {
                name: pKey as RfqServicePricing,
                price: pricing.price,
                modifier: pricing.modifier,
                active: pricing.active,
            }
        })

        const isForSubmission = mappedPricings.some((p) => {
            if (!p.price.trim()) return false
            if (p.active !== undefined && !p.active) return false
            return true
        })

        if (!isForSubmission) return

        const pricingsToSubmit = mappedPricings.reduce<StandardQuoteServicePricingDto[]>((prev, curr) => {
            if (!curr.price.trim()) return prev
            if (curr.active !== undefined && !curr.active) return prev
            prev.push({
                name: curr.name,
                price: Number(curr.price),
                modifier: curr.modifier ?? null,
            })
            return prev
        }, [])

        const serviceToSubmit: StandardQuoteServiceDto = {
            name: service.name as RfqService,
            type: service.type,
            pricings: pricingsToSubmit,
        }

        servicesToSubmit.push(serviceToSubmit)
    })

    return servicesToSubmit
}

const pickServicePricings = (service: StandardQuoteService) => {
    const pricings = { ...service.pricings }

    switch (service.name) {
        case RfqService.CrossDocking:
            if (service.type === 'allIn') {
                return {
                    [RfqServicePricing.CrossDocking]: pricings.crossDocking,
                }
            } else {
                return {
                    [RfqServicePricing.HandlingTotal]: pricings.handlingTotal,
                }
            }
        case RfqService.Transloading:
            if (service.type === 'allIn') {
                return {
                    [RfqServicePricing.Unloading]: pricings.unloading,
                    [RfqServicePricing.Palletization]: pricings.palletization,
                    [RfqServicePricing.PalletOut]: pricings.palletOut,
                }
            } else {
                return {
                    [RfqServicePricing.HandlingTotal]: pricings.handlingTotal,
                }
            }
        case RfqService.Handling:
            return {
                [RfqServicePricing.HandlingTotal]: pricings.handlingTotal,
            }
        case RfqService.Drayage:
            if (service.type === 'itemized') {
                delete pricings.drayage
                return pricings
            } else {
                return {
                    [RfqServicePricing.Drayage]: pricings.drayage,
                }
            }
        default:
            return pricings
    }
}
