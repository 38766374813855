import { AxiosRequestConfig } from 'axios'
import { HttpV2 } from '@shared/apiv2/api'
import { UploadOrderInvoiceRequest } from '@/entities/rfq/model/request'
import { RfqInvoice } from '@/entities/rfq/model/types'

const uploadOrderInvoice = async (req: UploadOrderInvoiceRequest, config?: AxiosRequestConfig) => {
    if (!req.file && !req.downloadInvoiceURL) {
        throw new Error('Either file or downloadInvoiceURL must be provided')
    }
    const formData = new FormData()
    if (req.file) {
        formData.append('file', req.file)
    }
    if (req.downloadInvoiceURL) {
        formData.append('downloadInvoiceURL', req.downloadInvoiceURL)
    }
    return HttpV2.post<RfqInvoice>(
        `accounts/${req.warehouseId}/warehouse/orders/${req.orderKey}/invoice`,
        formData,
    )
}

export const rfqApi = {
    uploadOrderInvoice,
}
