import React, { FC } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { tcn } from '@/utils/tcn'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { Avatar, AvatarFallback } from '@/shared/ui/Avatar'

export const ChatSelector: FC = () => {
    const availableChats = useAppSelector((state) => state.orderChat.availableChats)
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const dispatch = useAppDispatch()

    console.log(availableChats)
    return (
        <div className=" left-0 top-0 border-r-gray-1 min-w-[250px] flex-1">
            {availableChats &&
                availableChats.map((chat) => (
                    <div
                        key={chat.id ?? chat.messagingMode}
                        className={tcn(
                            'flex flex-col h-auto p-2  justify-center border-b-cyan-light-1 border-b hover:text-primary cursor-pointer',
                            selectedChat === (chat.id ?? chat.messagingMode) && 'text-primary',
                        )}
                        onClick={() => dispatch(orderChatActions.selectChat(chat.id ?? chat.messagingMode))}
                    >
                        <h4 className="text-accent-medium text-black">{chat.accountName}</h4>
                        <span>
                            {chat.messagingMode === 'DEMAND_PSM'
                                ? 'Demand'
                                : chat.messagingMode === 'SUPPLY_PSM'
                                ? 'Supply'
                                : 'All'}
                        </span>
                        <div className="flex">
                            {chat.participants &&
                                chat.participants.map((p) => (
                                    <Avatar key={p.id} className="h-6 w-6">
                                        <AvatarFallback className="text-[12px]">{p.nameShort}</AvatarFallback>
                                    </Avatar>
                                ))}
                        </div>
                    </div>
                ))}
        </div>
    )
}
