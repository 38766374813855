import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import appleIcon from 'images/apple_icon.svg'
import facebookIcon from 'images/facebook-icon.svg'
import googlePlayIcon from 'images/google_play_icon.svg'
import instagramIcon from 'images/instagram-icon.svg'
import linkedinIcon from 'images/linked_in-icon.svg'
import { getOLIMPLandingURL } from '@/utils/url'
import dayjs from 'dayjs'
import './Footer.scss'
import { useLocation } from 'react-router'
import { navigateToWpPage } from '@/pages/RFQPortal/helpers/rfqHelper'
import { isIframe } from '@/pages/RFQPortal/helpers/rfqHelper'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import { useCallRailTracking } from '@/pages/RFQPortal/utils/userActivityTracking'
import useNavHelper from '@/hooks/useNavHelper'

interface IFooter {
    info: {
        phone: string
        email: string
    }
}

const landingLink = getOLIMPLandingURL()

const nativeWebView = (window as any).ReactNativeWebView || window.parent !== window.self

// eslint-disable-next-line complexity
export const Footer: FC<IFooter> = ({ info }) => {
    const { pages } = useNavHelper()
    const { sendMenuClick, sendCustomEvent } = useCustomAnalyticsEvent()
    const location = useLocation()
    const [uiVersion, setUiVersion] = useState<string>(process.env.REACT_APP_VERSION ?? '')
    useCallRailTracking()
    const { data: micrositeData } = useMicrosite()
    const { olimpDistributionEmail = 'partnersupport@olimpwarehousing.com', olimpSupportPhone = '773-570-3481' } =
        micrositeData || {}

    useEffect(() => {
        if (!uiVersion) {
            try {
                import('../../../../package.json').then((pkg) => {
                    setUiVersion(pkg.default.version)
                })
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
                console.log('no version found')
            }
        }
    }, [])

    const openWPPage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        e.preventDefault()
        navigateToWpPage(link)
    }

    // const isRfqPage = location.pathname.startsWith('/ms/') || location.pathname.startsWith('/rfq')

    const socialLinks = [
        {
            href: 'https://www.facebook.com/OLIMPWarehousing/?ref=py_c',
            imgSrc: facebookIcon,
            alt: 'Facebook',
        },
        {
            href: 'https://www.linkedin.com/company/olimpwarehousing/',
            imgSrc: linkedinIcon,
            alt: 'LinkedIn',
        },
        {
            href: 'https://www.instagram.com/olimpwarehousing/',
            imgSrc: instagramIcon,
            alt: 'Instagram',
        },
    ]

    const footerLinks = {
        company: [
            {
                href: `${landingLink}/sustainability`,
                text: 'Sustainability',
            },
            {
                href: `${landingLink}/news-room`,
                text: 'In the Media',
            },
            {
                href: `${landingLink}/contact-us`,
                text: 'Contact Us',
            },
            {
                href: `${landingLink}/about-us`,
                text: 'About Us',
            },
            {
                href: `${landingLink}/careers`,
                text: 'Careers',
            },
        ],

        services: [
            {
                href: `${landingLink}/transloading-and-drayage`,
                text: 'Transloading & Drayage',
            },
            {
                href: `${landingLink}/pallet-reworks`,
                text: 'Pallet-reworks',
            },
            {
                href: `${landingLink}/cross-docking`,
                text: 'Cross-docking',
            },
            {
                href: `${landingLink}/warehousing`,
                text: 'Warehousing',
            },
        ],
        whoWeServe: [
            {
                href: `${landingLink}/freight-forwarders`,
                text: 'Freight Forwarders',
            },
            {
                href: `${landingLink}/freight-brokers`,
                text: 'Freight Brokers',
            },
            {
                href: `${landingLink}/warehouses`,
                text: 'Warehouses',
            },
            {
                href: `${landingLink}/retailers`,
                text: 'Retailers',
            },
            {
                href: `${landingLink}/shippers`,
                text: 'Shippers',
            },
            {
                href: `${landingLink}/carriers`,
                text: 'Carriers',
            },
        ],
    }

    const contactEmail = {
        href: pages.isRfqPage ? `mailto:${olimpDistributionEmail}` : 'mailto:warehousing@olimpwarehousing.com',
        email: pages.isRfqPage ? `${olimpDistributionEmail}` : 'warehousing@olimpwarehousing.com',
    }

    const contactPhone = {
        href: pages.isRfqPage ? `tel:${olimpSupportPhone}` : 'tel:773-570-3481',
        phone: pages.isRfqPage ? olimpSupportPhone : '773-570-3481',
    }

    return !nativeWebView ? (
        <footer className={cn('footer', { footer_rfq: isIframe })}>
            <div className="footer__row">
                <div className="footer__column">
                    <nav className="footer__nav">
                        <p className="footer__title">Company</p>
                        <ul>
                            {footerLinks.company.map((link, index) => {
                                return (
                                    <li className="footer__item" key={index}>
                                        <a
                                            href={link.href}
                                            onClick={() =>
                                                sendMenuClick({
                                                    label: link.text,
                                                    link: link.href,
                                                    place: 'footer',
                                                })
                                            }
                                        >
                                            {link.text}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </div>
                <div className="footer__column">
                    <p className="footer__title">Services</p>
                    <ul>
                        {footerLinks.services.map((link, index) => {
                            return (
                                <li className="footer__item" key={index}>
                                    <a
                                        href={link.href}
                                        onClick={() =>
                                            sendMenuClick({
                                                label: link.text,
                                                link: link.href,
                                                place: 'footer',
                                            })
                                        }
                                    >
                                        {link.text}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="footer__column">
                    <p className="footer__title">Who we serve</p>
                    <ul>
                        {footerLinks.services.map((link, index) => {
                            return (
                                <li className="footer__item" key={index}>
                                    <a
                                        href={link.href}
                                        onClick={() =>
                                            sendMenuClick({
                                                label: link.text,
                                                link: link.href,
                                                place: 'footer',
                                            })
                                        }
                                    >
                                        {link.text}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="footer__column">
                    <p className="footer__title">Follow us</p>
                    <ul className="footer__social-links">
                        {socialLinks.map((link, index) => {
                            return (
                                <li className="footer__social-item" key={index}>
                                    <a
                                        href={link.href}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        onClick={() =>
                                            sendCustomEvent('social_media_clicked', {
                                                label: link.alt,
                                                link: link.href,
                                                place: 'footer',
                                            })
                                        }
                                    >
                                        <img src={link.imgSrc} alt={link.alt} className="footer__social-link-img" />
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className="footer__row footer__row_full-width">
                <div className="footer__column">
                    <div className="footer__support">
                        <p className="footer__title">Support</p>
                        <ul>
                            <li className="footer__item">
                                <a
                                    href={contactEmail.href}
                                    rel="nofollow noopener noreferrer"
                                    onClick={() =>
                                        sendCustomEvent('support_contacts_clicked', {
                                            label: 'email',
                                            link: contactEmail.email,
                                            place: 'footer',
                                        })
                                    }
                                >
                                    {contactEmail.email}
                                </a>
                            </li>
                            <li className="footer__item">
                                <a
                                    href={contactPhone.href}
                                    rel="nofollow noopener noreferrer"
                                    onClick={() =>
                                        sendCustomEvent('support_contacts_clicked', {
                                            label: 'phone',
                                            link: contactPhone.phone,
                                            place: 'footer',
                                        })
                                    }
                                >
                                    {contactPhone.phone}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__column">
                    <div className="footer__item_terms">
                        <div className="footer__item_terms-content">
                            <span className="footer__item_terms-copyright">© {dayjs().year()} OLIMP Corp</span>
                            <span className="footer__item_terms-copyright"> • </span>
                            <a
                                className="footer__item_terms-link"
                                href={`${landingLink}/terms-and-conditions`}
                                onClick={() =>
                                    sendCustomEvent('terms_and_conditions_clicked', {
                                        link: `${landingLink}/terms-and-conditions`,
                                        place: 'footer',
                                    })
                                }
                            >
                                Terms and Conditions
                            </a>
                            <span className="footer__item_terms-copyright"> • </span>
                            <a
                                className="footer__item_terms-link"
                                href={`${landingLink}/privacy-policy`}
                                onClick={() =>
                                    sendCustomEvent('privacy_policy_clicked', {
                                        link: `${landingLink}/privacy-policy`,
                                        place: 'footer',
                                    })
                                }
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                    {!isIframe && (
                        <span className="footer__item_terms-version">
                            App Version: <b>{uiVersion}</b>
                        </span>
                    )}
                </div>
            </div>
        </footer>
    ) : null
}
