import React, { FC, useEffect, useMemo, useState } from 'react'
import { ServicesSidebar } from '@/pages/Warehouses/Services/Sidebar'
import Services from '@/pages/Warehouses/Services/Services'
import ServiceOptions, { IServiceOption } from '@/pages/Warehouses/Services/ServiceOptions'
import './index.scss'
import CheckBoxNew from '@/components/CheckboxNew'
import InputLabel from '@/components/InputLabel'
import HazmatChecklist from '@/pages/Warehouses/Services/HazmatChecklist'
import { useServicesApi } from '@/pages/Warehouses/Services/useServicesApi'
import { useParams } from 'react-router-dom'
import EditButton from '@/pages/RFQPortal/components/EditBotton'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const DEFAULT_SERVICES: IServiceOption[] = [
    { id: 'cross-docking', name: 'Cross Docking' },
    { id: 'rework-restack', name: 'Rework / Restack' },
    { id: 'storage', name: 'Storage' },
    { id: 'transloading', name: 'Transloading' },
    { id: 'final-mile-local-delivery', name: 'Final Mile / Local Delivery' },
    { id: 'drayage', name: 'Drayage' },
    { id: 'disposal', name: 'Disposal' },
    { id: 'yard-storage-trailer-parking', name: 'Yard Storage / Trailer Parking' },
]

const DEFAULT_ENVS: IServiceOption[] = [
    { id: 'dry', name: 'Dry' },
    { id: 'temperature', name: 'Temperature' },
]
const DEFAULT_TEMPERATURES: IServiceOption[] = [
    { id: 'frozen-32-and-below', name: 'Frozen (32° and below)' },
    { id: 'chilled-refrigerated-33-48', name: 'Chilled/Refrigerated (33° to 48°)' },
    // { id: 'temp-controlled', name: 'Temp Controlled' },`
    { id: 'ambient', name: 'Ambient (65°+)' },
    { id: 'specific', name: 'Temp Controlled' },
]

const DEFAULT_FEATURES: IServiceOption[] = [
    { id: 'sprinkler', name: 'Sprinkler' },
    { id: 'gated-yard', name: 'Gated Yard' },
    { id: 'onsite-security', name: 'Onsite Security' },
    { id: 'ramp', name: 'Ramp' },
    { id: 'cameras', name: 'Cameras' },
    { id: 'fulfillment', name: 'Fulfillment' },
    { id: 'airport-access', name: 'Airport Access' },
    { id: 'rail-access', name: 'Rail Access' },
]

const DEFAULT_CERTS: IServiceOption[] = [
    { id: 'alcohol', name: 'Alcohol' },
    { id: 'food-grade', name: 'Food Grade' },
    { id: 'hazmat', name: 'Hazmat' },
    { id: 'food-aib', name: 'Food (AIB)' },
    { id: 'organic', name: 'Organic' },
    { id: 'medical', name: 'Medical' },
    { id: 'foreign-trade-zone-ftz', name: 'Foreign Trade Zone (FTZ)' },
    { id: 'tsa-approved', name: 'TSA Approved' },
    { id: 'military', name: 'Military' },
    { id: 'bonded', name: 'Bonded' },
    { id: 'gdp', name: 'GDP' },
]

const DEFAULT_EQUIPMENTS: IServiceOption[] = [
    { id: 'crane', name: 'Crane' },
    { id: 'crane-max-weight', name: 'Crane - Max Weight' },
    { id: 'slip-sheet', name: 'Slip Sheet' },
    { id: 'clamp', name: 'Clamp' },
    { id: 'heavy-duty-forklift', name: 'Heavy Duty Forklift' },
    { id: 'heavy-duty-forklift-max-weight', name: 'Heavy Duty Forklift - Max Weight' },
    { id: 'boom', name: 'Boom' },
]

const DEFAULT_VEHICLES: IServiceOption[] = [
    { id: 'dry-van-53ft', name: 'Dry Van (53ft)' },
    { id: 'dry-van-53ft-lifted-gate', name: 'Dry Van (53ft) - Lift Gate' },
    { id: 'sprinter-box-truck-less-6-pallets', name: 'Sprinter / Box Truck (<6 Pallets)' },
    {
        id: 'sprinter-box-truck-less-6-pallets-lift-gate',
        name: 'Sprinter / Box Truck (<6 Pallets) - Lift Gate',
        isSelected: true,
    },
    { id: 'flat-bed', name: 'Flat Bed' },
    { id: 'straight-truck-26ft2', name: 'Straight Truck (26ft)' },
    { id: 'straight-truck-26ft2-lift-gate', name: 'Straight Truck (26ft) - Lift Gate' },
    { id: 'container-20', name: 'Container 20ft' },
    { id: 'container-40', name: 'Container 40ft' },
    { id: 'container-40-high-cube-hc', name: 'Container 40ft - High Cube (HC)' },
    { id: 'reefer', name: 'Reefer' },
    { id: 'temp-controlled', name: 'Temp Controlled' },
]

const DEFAULT_HAZMATS = [
    { id: 'hazmat-class-1-explosives', name: 'Hazmat Class 1 - Explosives' },
    { id: 'hazmat-class-2-gasses', name: 'Hazmat Class 2 - Gases' },
    { id: 'hazmat-class-3-flammable-liquids', name: 'Hazmat Class 3 - Flammable Liquids' },
    { id: 'hazmat-class-4-flammable-solids', name: 'Hazmat Class 4 - Flammable Solids' },
    { id: 'hazmat-class-5-oxidizing-substances', name: 'Hazmat Class 5 - Oxidizing Substances' },
    { id: 'hazmat-class-6-toxic-and-infectious-substances', name: 'Hazmat Class 6 - Toxic and Infectious Substances' },
    { id: 'hazmat-class-7-radioactive-materials', name: 'Hazmat Class 7 - Radioactive Materials' },
    { id: 'hazmat-class-8-corrosive-substances', name: 'Hazmat Class 8 - Corrosive Substances' },
    { id: 'hazmat-class-9-miscellaneous-dangerous-goods', name: 'Hazmat Class 9 - Miscellaneous Dangerous Goods' },
]

const formatTemperature = (min?: number, max?: number) => {
    if (!min && !max) {
        return 'Unknown'
    }
    if (min && !max) {
        return `from ${min}°`
    }
    if (!min && max) {
        return `up to ${max}°`
    }
    return `${min}° to ${max}°`
}

const useOptions = (template: IServiceOption[], selectedValues: string[] = []) => {
    const [options, setOptions] = useState<IServiceOption[]>(
        template.map((option) => ({ ...option, isSelected: false })),
    )

    const handleOptionChange = (id: string, isSelected: boolean) => {
        setOptions((prev) => prev.map((option) => (option.id === id ? { ...option, isSelected } : option)))
    }

    return { options, handleOptionChange }
}

// eslint-disable-next-line complexity
const WarehousesServices: FC = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    const { id } = useParams()
    const { data, dataLoading, dataError, revalidate, updateServices, updateServicesLoading } = useServicesApi(id)

    const [editMode, setEditMode] = useState<boolean>(false)
    const [tempMin, setTempMin] = useState<number>(0)
    const [tempMax, setTempMax] = useState<number>(0)
    const { options: services, handleOptionChange: handleServiceChange } = useOptions(DEFAULT_SERVICES, [])
    const { options: temperatures, handleOptionChange: handleTemperaturesChange } = useOptions(DEFAULT_TEMPERATURES, [])
    const { options: environments, handleOptionChange: handleEnvChange } = useOptions(DEFAULT_ENVS, [])
    const { options: features, handleOptionChange: handleFeaturesChange } = useOptions(DEFAULT_FEATURES, [])
    const { options: certifications, handleOptionChange: handleCertChange } = useOptions(DEFAULT_CERTS, [])
    const { options: equipments, handleOptionChange: handleEquipChange } = useOptions(DEFAULT_EQUIPMENTS, [])
    const { options: vehicles, handleOptionChange: handleVehicleChange } = useOptions(DEFAULT_VEHICLES, [])
    const { options: hazmats, handleOptionChange: handleHazmatChange } = useOptions(DEFAULT_HAZMATS, [])

    const hasEditPermission = !dataLoading && data.permission > 1

    const setServerValues = () => {
        const {
            envs,
            temperatures: serverTemps,
            services: serverServices,
            features: serverFeatures,
            hazmatClasses: serverHazmatClasses,
            certs: serverCerts,
            equipments: serverEquipments,
            vehicles: serverVehicles,
            tempMin: serverTempMin,
            tempMax: serverTempMax,
        } = data
        // this is correct
        environments.forEach((env) => handleEnvChange(env.id, (envs ?? []).includes(env.id)))
        temperatures.forEach((temp) => handleTemperaturesChange(temp.id, (serverTemps ?? []).includes(temp.id)))
        services.forEach((service) => handleServiceChange(service.id, (serverServices ?? []).includes(service.id)))
        features.forEach((feature) => handleFeaturesChange(feature.id, (serverFeatures ?? []).includes(feature.id)))
        hazmats.forEach((hazmat) => handleHazmatChange(hazmat.id, (serverHazmatClasses ?? []).includes(hazmat.id)))
        certifications.forEach((cert) => handleCertChange(cert.id, (serverCerts ?? []).includes(cert.id)))
        equipments.forEach((equipment) =>
            handleEquipChange(equipment.id, (serverEquipments ?? []).includes(equipment.id)),
        )
        vehicles.forEach((vehicle) => handleVehicleChange(vehicle.id, (serverVehicles ?? []).includes(vehicle.id)))
        setTempMin(serverTempMin || 0)
        setTempMax(serverTempMax || 0)
    }
    useEffect(() => {
        if (!dataLoading) {
            // handle all changes
            setServerValues()
        }
    }, [dataLoading, id])

    const temperatureEnvSelected = environments.find((env) => env.isSelected && env.id === 'temperature')
    const hazmatCertSelected = certifications.find((h) => h.isSelected && h.id === 'hazmat')
    const specificVal = temperatures.find((temp) => temp.id === 'specific')?.isSelected || false
    const collectBodyAndUpdate = async () => {
        if (hazmatCertSelected && !hazmats.some((h) => h.isSelected)) {
            toast('Please select at least one Hazmat Class', { type: 'error' })
            return
        }

        if (temperatureEnvSelected && specificVal && (!tempMin || !tempMax)) {
            toast('Please provide specific temperature range', { type: 'error' })
            return
        }

        // check temp range max > min
        if (temperatureEnvSelected && specificVal && tempMax < tempMin) {
            toast('Max temperature must be greater than min temperature', { type: 'error' })
            return
        }

        if (temperatureEnvSelected && !temperatures.some((temp) => temp.isSelected)) {
            toast('Please select at least one Temperature Option', { type: 'error' })
            return
        }

        const body = {
            envs: environments.filter((env) => env.isSelected).map((env) => env.id),
            temperatures: temperatures.filter((temp) => temp.isSelected).map((temp) => temp.id),
            services: services.filter((service) => service.isSelected).map((service) => service.id),
            features: features.filter((feature) => feature.isSelected).map((feature) => feature.id),
            hazmatClasses: hazmats.filter((cert) => cert.isSelected).map((cert) => cert.id),
            certs: certifications.filter((cert) => cert.isSelected).map((cert) => cert.id),
            equipments: equipments.filter((equipment) => equipment.isSelected).map((equipment) => equipment.id),
            vehicles: vehicles.filter((vehicle) => vehicle.isSelected).map((vehicle) => vehicle.id),
            tempMin,
            tempMax,
        }
        await updateServices(body)
        setEditMode(false)
        sendCustomEvent('services_updated', body)
    }

    return (
        <div className="app-page-container_flex warehouses-services-page">
            <div className="app-page">
                <section className="app-card">
                    <div className={'app-card__header bordered-block'}>
                        <h2 className="app-card__title">Services</h2>
                        {!editMode && hasEditPermission && <EditButton action={() => setEditMode(true)} />}
                    </div>
                    <div className="warehouses-services-page__main-services">
                        <Services services={services} onChange={handleServiceChange} editMode={editMode} />
                    </div>
                </section>

                <section className="app-card">
                    <div className={'app-card__header bordered-block'}>
                        <h2 className="app-card__title">Additional Details</h2>
                        {!editMode && hasEditPermission && <EditButton action={() => setEditMode(true)} />}
                    </div>
                    <div className="warehouses-services-page__section">
                        <h2 className="app-card__subtitle">Environments</h2>
                        <ServiceOptions
                            options={environments}
                            onChange={handleEnvChange}
                            isStacked
                            editMode={editMode}
                        />
                        {temperatureEnvSelected && (
                            <>
                                <label className="app-card__label">
                                    Temperature Options{editMode ? '* (Select all applicable)' : ''}
                                </label>
                                <div className="warehouses-services-page__checkboxes">
                                    {temperatures
                                        .filter((temp) => temp.id !== 'specific' && (editMode || temp.isSelected))
                                        .map((temp) => (
                                            <CheckBoxNew
                                                key={temp.id}
                                                lightStyle
                                                checked={Boolean(temp.isSelected)}
                                                onChange={() => handleTemperaturesChange(temp.id, !temp.isSelected)}
                                                name={temp.id}
                                                readOnly={!editMode}
                                                label={temp.name}
                                            />
                                        ))}

                                    <div className={cn('warehouses-services-page__checkbox-with-addon', { editMode })}>
                                        {(editMode || specificVal) && (
                                            <CheckBoxNew
                                                lightStyle
                                                checked={specificVal}
                                                onChange={() => handleTemperaturesChange('specific', !specificVal)}
                                                name="specific"
                                                readOnly={!editMode}
                                                label={
                                                    editMode
                                                        ? 'Temp controlled'
                                                        : `Temp controlled (${formatTemperature(tempMin, tempMax)})`
                                                }
                                            />
                                        )}
                                        {editMode && (
                                            <InputLabel
                                                name="tempMin"
                                                label="Min °"
                                                className="warehouses-services-page__checkbox-with-addon-input"
                                                value={tempMin}
                                                disabled={!specificVal}
                                                onChange={(val) => {
                                                    const hasMinus = val.target.value.includes('-')
                                                    const valUpdated = hasMinus
                                                        ? '-' + val.target.value.replace('-', '')
                                                        : val.target.value
                                                    const intVal = parseInt(valUpdated)
                                                    if (!isNaN(intVal)) {
                                                        setTempMin(intVal)
                                                    }
                                                }}
                                                errorMessage={undefined}
                                            />
                                        )}
                                        {editMode && (
                                            <InputLabel
                                                name="tempMax"
                                                label="Max °"
                                                className="warehouses-services-page__checkbox-with-addon-input"
                                                value={tempMax}
                                                disabled={!specificVal}
                                                onChange={(val) => {
                                                    const hasMinus = val.target.value.includes('-')
                                                    const valUpdated = hasMinus
                                                        ? '-' + val.target.value.replace('-', '')
                                                        : val.target.value
                                                    const intVal = parseInt(valUpdated)
                                                    if (!isNaN(intVal)) {
                                                        setTempMax(intVal)
                                                    }
                                                }}
                                                errorMessage={undefined}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="warehouses-services-page__section">
                        <div className="warehouses-services-page__divider" />
                        <h2 className="app-card__subtitle">Features</h2>
                        <ServiceOptions
                            options={features}
                            onChange={handleFeaturesChange}
                            isStacked
                            editMode={editMode}
                        />
                    </div>
                    <div className="warehouses-services-page__section">
                        <div className="warehouses-services-page__divider" />
                        <h2 className="app-card__subtitle">Certifications</h2>
                        <ServiceOptions
                            options={certifications}
                            onChange={handleCertChange}
                            isStacked
                            editMode={editMode}
                        />
                        {hazmatCertSelected && (
                            <>
                                <label className="app-card__label">
                                    Hazmat Classes{editMode ? '* (Select all applicable)' : ''}
                                </label>
                                <HazmatChecklist options={hazmats} onChange={handleHazmatChange} editMode={editMode} />
                            </>
                        )}
                    </div>
                    <div className="warehouses-services-page__section">
                        <div className="warehouses-services-page__divider" />
                        <h2 className="app-card__subtitle">Equipment</h2>
                        <ServiceOptions
                            options={equipments}
                            onChange={handleEquipChange}
                            isStacked
                            editMode={editMode}
                        />
                    </div>
                    <div className="warehouses-services-page__section">
                        <div className="warehouses-services-page__divider" />
                        <h2 className="app-card__subtitle">Vehicles</h2>
                        <ServiceOptions
                            options={vehicles}
                            onChange={handleVehicleChange}
                            isStacked
                            editMode={editMode}
                        />
                    </div>
                </section>
            </div>
            <div className="app-sidebar">
                <ServicesSidebar
                    editMode={editMode}
                    loading={updateServicesLoading}
                    onSave={collectBodyAndUpdate}
                    onCancel={() => {
                        setServerValues()
                        setEditMode(false)
                    }}
                />
            </div>
        </div>
    )
}

export default WarehousesServices
