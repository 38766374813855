import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'

export const TransloadingFormComponent = ({ order, isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string, field: RfqServicePricing) => {
        if (service?.pricings[field]) {
            service!.pricings[field]!.price = value
        }
    }

    if (isFloorLoaded) {
        return (
            <div className="flex flex-col gap-5 w-full">
                <div className="font-medium">Floor Loaded</div>
                <div>
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyInput
                            label={RfqServicePricingLabel[RfqServicePricing.Unloading]}
                            defaultValue={service.pricings.unloading?.price}
                            onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.Unloading)}
                            allowNegative={false}
                            decimalScale={2}
                            prefix="$"
                            wrapperClassName="w-full md:w-[200px]"
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                            {order.unitType ?? 'Unit'}
                        </span>
                    </div>
                    {errors?.[RfqServicePricing.Unloading] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.Unloading]}</div>
                    ) : null}
                </div>
                <div>
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyInput
                            label={RfqServicePricingLabel[RfqServicePricing.Palletization]}
                            defaultValue={service.pricings.palletization?.price}
                            onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.Palletization)}
                            allowNegative={false}
                            decimalScale={2}
                            prefix="$"
                            wrapperClassName="w-full md:w-[200px]"
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <div className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                            <span>Pallet</span>
                            <span className="hidden sm:inline">&nbsp;(including materials)</span>
                        </div>
                    </div>
                    {errors?.[RfqServicePricing.Palletization] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.Palletization]}</div>
                    ) : null}
                </div>
                <div>
                    <CurrencyInput
                        label={RfqServicePricingLabel[RfqServicePricing.PalletOut]}
                        defaultValue={service.pricings.palletOut?.price}
                        onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.PalletOut)}
                        allowNegative={false}
                        decimalScale={2}
                        prefix="$"
                        wrapperClassName="w-full md:w-[200px]"
                    />
                    {errors?.[RfqServicePricing.PalletOut] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.PalletOut]}</div>
                    ) : null}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="font-medium">Palletized</div>
            <div>
                <CurrencyInput
                    label={
                        <div className="flex">
                            {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                            <HintRoot>
                                <HintTrigger asChild>
                                    <div className="ml-1 -mt-[1px]">
                                        <FaInfoCircle size={16} />
                                    </div>
                                </HintTrigger>
                                <HintContent sideOffset={-3}>
                                    <HintArrow /> Combined In and Out Charges per Unit
                                </HintContent>
                            </HintRoot>
                        </div>
                    }
                    defaultValue={service.pricings.handlingTotal?.price}
                    onValueChange={({ value }) => onChange(value ?? '', RfqServicePricing.HandlingTotal)}
                    allowNegative={false}
                    decimalScale={2}
                    prefix="$"
                    wrapperClassName="w-full md:w-[200px]"
                />
                {errors?.[RfqServicePricing.HandlingTotal] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingTotal]}</div>
                ) : null}
            </div>
        </div>
    )
}
