import React, { useState } from 'react'
import './UploadFile.scss'
import FileUpload from '@/components/FileUpload'
import Button from '@/components/Button'
import { showInsuranceUploadModal } from '@/parts/Modals/FileUploadModal'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/Tooltip'

interface UploadFileProps {
    title: string
    accept?: string
    children: React.ReactNode
    disabled?: boolean
    manager?: boolean
    multiple?: boolean
    hintDescription?: string
    loading?: boolean
    handleFileSelect: (file: FileList | null, expirationDate?: string) => void
}

export const UploadFile = ({
    title,
    children,
    handleFileSelect,
    disabled,
    loading,
    manager = true,
    accept,
    multiple,
    hintDescription,
}: UploadFileProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const handleUploadSelect = (file: FileList | null) => {
        handleFileSelect(file)
    }
    const [open, setOpen] = useState(false)

    const handleFileUploadClick = () => {
        showInsuranceUploadModal({
            props: { loading, onSubmit: handleFileSelect },
        })
        sendCustomEvent('upload_insurance_clicked')
    }

    return (
        <div className="upload-file">
            <div className={'app-card__header bordered-block'}>
                <div className="upload-file__title">
                    {title}
                    {hintDescription && (
                        <Tooltip open={open}>
                            <TooltipTrigger
                                asChild
                                onMouseEnter={() => {
                                    setOpen(true)
                                }}
                                onMouseLeave={() => {
                                    setOpen(false)
                                }}
                                onFocus={() => {
                                    setOpen(true)
                                }}
                                onBlur={() => {
                                    setOpen(false)
                                }}
                            >
                                <div className="hint right">
                                    <span className="icon">?</span>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent className="w-max max-w-[250px] font-normal">
                                {hintDescription}
                            </TooltipContent>
                        </Tooltip>
                    )}
                </div>
                {title === 'Insurance' && manager && (
                    <Button types={['bordered']} label="Upload File" onClick={handleFileUploadClick} />
                )}
                {title !== 'Insurance' && manager && (
                    <FileUpload
                        accept={accept}
                        disabled={disabled}
                        loading={loading}
                        multiple={multiple}
                        onFileSelect={(fileList: FileList | null) => {
                            handleUploadSelect(fileList)

                            if (!fileList) {
                                return
                            }

                            const filesArray = Array.from(fileList)
                            const sizes = filesArray.map((file: File) => file.size)

                            sendCustomEvent('file_uploaded', {
                                number_of_files: fileList.length,
                                size: sizes.join(', '),
                                type: filesArray.map((file: File) => file.type).join(', '),
                            })
                        }}
                    />
                )}
            </div>
            <div>{children}</div>
        </div>
    )
}
