import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { Select, SelectOption } from '@/shared/ui/Select'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

const RANGE_OPTIONS: SelectOption[] = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
]

export const StorageFormComponent = ({ order, isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onTypeChange = (newType: string) => {
        service.type = newType
    }

    const onPriceChange = (value: string) => {
        if (service.pricings?.storage) {
            service.pricings.storage.price = value
        }
    }

    const onModifierChange = (value: string) => {
        if (service.pricings?.storage) {
            service.pricings.storage.modifier = value
        }
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <TabsRoot defaultValue={service.type as string} onValueChange={onTypeChange}>
                <TabsList variant="filled" className="h-auto w-full md:w-fit items-stretch">
                    <TabsTrigger value="unit" className="basis-0 min-h-8 h-auto flex-grow md:basis-auto md:flex-grow-0">
                        {!isFloorLoaded ? 'Pallet' : order.unitType ?? 'Unit'}
                    </TabsTrigger>
                    <TabsTrigger value="sqft" className="basis-0 min-h-8 h-auto flex-grow md:basis-auto md:flex-grow-0">
                        Sqft
                    </TabsTrigger>
                </TabsList>
            </TabsRoot>
            <div>
                <div className="flex gap-2 items-center w-full">
                    <CurrencyInput
                        label={RfqServicePricingLabel[RfqServicePricing.Storage]}
                        defaultValue={service.pricings.storage?.price}
                        onValueChange={({ value }) => onPriceChange(value ?? '')}
                        allowNegative={false}
                        decimalScale={2}
                        prefix="$"
                        wrapperClassName="w-full md:w-[200px]"
                    />
                    <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                    <Select
                        defaultValue={service.pricings.storage?.modifier ?? ''}
                        options={RANGE_OPTIONS}
                        onValueChange={onModifierChange}
                        triggerClassName="w-[100px] sm:w-[170px] shrink-0"
                    />
                </div>
                {errors?.[RfqServicePricing.Storage] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.Storage]}</div>
                ) : null}
            </div>
        </div>
    )
}
