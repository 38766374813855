import React, { useState, useEffect, useRef, useCallback } from 'react'
import HintBox from '@/pages/RFQPortal/components/HintBox'
import cn from 'classnames'

import './MultiSelectDropdown.scss'
const CLASS_NAME = 'multi-select-dropdown'

export interface MultiSelectOption {
    label: string
    value: string
    info?: string | React.ReactNode
}

interface MultiSelectDropdownProps {
    options: MultiSelectOption[]
    label: string
    selectedOptions: string[] | undefined
    setSelectedOptions: (selected: string[]) => void
    renderCustomOptionView?: (
        option: MultiSelectOption,
        isSelected: boolean,
        toggleOption: () => void,
    ) => React.ReactNode
    renderCustomInputView?: (selectedOptions: string[]) => React.ReactNode
    dropdownClassName?: string
    errorMessage?: string
    isDropdownDisabled?: boolean
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
    label,
    options,
    selectedOptions = [],
    setSelectedOptions,
    renderCustomOptionView,
    renderCustomInputView,
    dropdownClassName,
    errorMessage,
    isDropdownDisabled,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const toggleOption = useCallback(
        (optionValue: string) => () => {
            if (selectedOptions.includes(optionValue)) {
                setSelectedOptions(selectedOptions.filter((option) => option !== optionValue))
            } else {
                setSelectedOptions([...selectedOptions, optionValue])
            }
        },
        [selectedOptions, setSelectedOptions],
    )

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isDropdownDisabled) {
            setSelectedOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownDisabled])

    return (
        <div
            className={cn(CLASS_NAME, dropdownClassName, { [`${CLASS_NAME}_disabled`]: isDropdownDisabled })}
            ref={dropdownRef}
        >
            <label className="select-label">{label}</label>
            <button type="button" className={`${CLASS_NAME}__button`} onClick={() => setIsOpen(!isOpen)}>
                {renderCustomInputView ? renderCustomInputView(selectedOptions) : `${selectedOptions.length} selected`}
            </button>
            {isOpen && !isDropdownDisabled && (
                <div className={`${CLASS_NAME}__content`}>
                    {options.map((option) => (
                        <React.Fragment key={option.value}>
                            {renderCustomOptionView ? (
                                renderCustomOptionView(option, selectedOptions.includes(option.value), () =>
                                    toggleOption(option.value),
                                )
                            ) : (
                                <>
                                    <input
                                        id={option.value}
                                        type="checkbox"
                                        checked={selectedOptions.includes(option.value)}
                                        onChange={toggleOption(option.value)}
                                        disabled={isDropdownDisabled}
                                    />
                                    <label htmlFor={option.value}>
                                        {option.label}
                                        {option.info && (
                                            <HintBox
                                                className={`${CLASS_NAME}__tooltip`}
                                                content={option.info}
                                            ></HintBox>
                                        )}
                                    </label>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {!isOpen && errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default MultiSelectDropdown
