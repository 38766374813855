import { ChatAction, ChatEvent } from '@/entities/chat/model/enums'
import { ChatMessageDto, MessageBoardFilters } from '@/entities/chat/model/dto'
import { IONamespace, useSocket, useSocketSubscriptions } from '@/hooks/useSocket'

export interface MessageBoardRequest {
    reference?: string
    skip?: number
    take?: number
    filters?: MessageBoardFilters
}

export interface ChatOptions {
    callbacks?: {
        onMessageReceived?: (message: ChatMessageDto) => void
        onChatReceived?: (chat: any) => void
        onMessageBoardReceived?: (response: any) => void
        onError?: (msg: string) => void
        onChatsReceived?: (chat: any[]) => void
        onMessageSeen?: (data: { messageIds: number[]; participantId: number }) => void
    }
}

export const useChat = ({ callbacks }: ChatOptions) => {
    const { socket, connected, error } = useSocket(IONamespace.Chat)
    const sendMessage = (body: { message: string; reference: string; attachments?: number[] }) => {
        if (socket?.connected) {
            socket!.emit(ChatAction.SendMessage, body)
        }
    }

    const seenMessage = (messageId: number) => {
        if (socket?.connected) {
            socket!.emit(ChatAction.SeenMessage, messageId)
        }
    }

    const getChat = (referenceId: string | number) => {
        if (socket?.connected) {
            socket!.emit(ChatAction.GetChat, referenceId)
        }
    }

    const getChats = (referenceId: string) => {
        if (socket?.connected) {
            socket!.emit(ChatAction.GetChats, referenceId)
        }
    }

    const getMessageBoard = (request: MessageBoardRequest) => {
        if (socket?.connected) {
            socket!.emit(ChatAction.GetMessageBoardChats, request)
        }
    }

    useSocketSubscriptions(socket, [
        { event: ChatEvent.MessageReceived, callback: callbacks?.onMessageReceived },
        { event: ChatEvent.ChatReceived, callback: callbacks?.onChatReceived },
        { event: ChatEvent.ChatsReceived, callback: callbacks?.onChatsReceived },
        { event: ChatEvent.MessageSeen, callback: callbacks?.onMessageSeen },
        { event: ChatEvent.MessageBoardReceived, callback: callbacks?.onMessageBoardReceived },
        { event: ChatEvent.UserError, callback: callbacks?.onError },
    ])

    return {
        getChat,
        getChats,
        getMessageBoard,
        sendMessage,
        seenMessage,
        connected,
        error,
        socket,
    }
}
