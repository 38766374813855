/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react'
import Progress from '@/components/ModernTable/Progress'
import Button from '@/components/Button'
import Search from '@/components/Search'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import useWarehousesColumns from './useWarehousesColumns'

import { HttpV2 } from '@shared/apiv2/api'
import { showAddWarehouseModal } from '@/parts/Modals/AddWarehouse'
import './Warehouses.scss'
import { toast } from 'react-toastify'
import useCustomPagination, { PaginationInfo } from '@/hooks/useCustomPagination'
import { PermissionLevel } from '@/parts/Modals/ManageStaffModal'
import { CONSTS } from '@/common/consts'
import { useNavigate } from 'react-router-dom'
import { useUserState } from '@/store/user'
import useMobileScreen from '@/hooks/useMobileScreen'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

type ContactInfo = {
    firstName: string
    lastName: string
    email: string
    phone: string
    id: string
}

export type WarehouseInfo = {
    id: string
    isPrimary: boolean
    isUserAcc: boolean
    isCmpAcc: boolean
    name: string
    location: string
    contact: ContactInfo
    permission: PermissionLevel
    coiStatus: null | 'Pending' | 'Confirmed' | 'Rejected' | 'Expired'
}

export type WarehousesInfo = {
    primary: WarehouseInfo | null
    warehouses: WarehouseInfo[]
}

const Warehouses = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const isMobile = useMobileScreen({ screenWidth: 1140 })
    const { state } = useUserState()
    const navigate = useNavigate()
    const [primaryUpdateLoading, setPrimaryUpdateLoading] = useState<false | string>(false)
    const [warehousesInfo, setWarehousesInfo] = useState<WarehousesInfo>({
        primary: {} as WarehouseInfo,
        warehouses: [],
    })
    const [pagination, setPagination] = useState<PaginationInfo>({ pageIndex: 0, pageSize: 10 })
    const { primary, warehouses } = warehousesInfo
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const filteredWarehousesList = useMemo(() => {
        const filtered = globalFilter
            ? warehouses.filter((warehouse) => {
                  const contactName = `${warehouse.contact?.firstName || ''} ${warehouse.contact?.lastName || ''}`

                  return (
                      warehouse.name.toLowerCase().trim().includes(globalFilter.toLowerCase().trim()) ||
                      warehouse.location.toLowerCase().trim().includes(globalFilter.toLowerCase().trim()) ||
                      (contactName && contactName.toLowerCase().trim().includes(globalFilter.toLowerCase().trim()))
                  )
              })
            : warehouses

        return filtered.map((warehouse) => ({
            ...warehouse,
            isPrimary: primary ? warehouse.name === primary.name : false,
        }))
    }, [warehouses, globalFilter, primary])

    const { paginatedItems } = useCustomPagination(filteredWarehousesList, pagination)

    const fetchWarehousesInfo = async () => {
        try {
            setIsLoading(true)

            const { data }: any = await HttpV2.get('accounts/related')

            const filterMainCompany = data.filter((item: any) => item.permission > 0)
            setWarehousesInfo({
                primary: filterMainCompany.find((item: any) => item.isPrimary),
                warehouses: filterMainCompany,
            })
        } catch (error) {
            console.error('Fetching warehouses data failed: ', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddWarehouse = async (success: boolean) => {
        if (success) {
            setIsLoading(true)
            await new Promise((resolve) => setTimeout(resolve, 1000))
            setWarehousesInfo((wh: any) => ({
                ...wh,
                warehouses: [],
            }))
            fetchWarehousesInfo()
        }
    }

    const setPrimaryWarehouse = async (warehouse: WarehouseInfo) => {
        let success = false
        try {
            const newPrimary = warehouse.isPrimary ? null : warehouse
            if (newPrimary) {
                setPrimaryUpdateLoading(newPrimary.id)
                await HttpV2.put(`accounts/${newPrimary.id}/warehouse/primary`)
                setWarehousesInfo((prevInfo) => ({
                    primary: newPrimary,
                    warehouses: prevInfo.warehouses.map((whData) => ({
                        ...whData,
                        isPrimary: whData.id === newPrimary?.id || false,
                    })),
                }))

                setPrimaryUpdateLoading(false)
            }
            success = true
        } catch (e: any) {
            setPrimaryUpdateLoading(false)

            console.error('Error in setting primary warehouse: ', e)
            toast.error('Could not set primary warehouse: ' + e.message)
        } finally {
            sendCustomEvent('primary_warehouse_changed', { warehouse_id: warehouse.id, success })
        }
    }

    const columns: any = useWarehousesColumns({
        setPrimaryWarehouse,
        primaryUpdateLoading,
        fetchWarehousesInfo,
        admin: state.userInfo!.admin,
    })

    useEffect(() => {
        fetchWarehousesInfo()
    }, [])

    const page = 'app-page'
    const card = 'app-card'
    const warehousesPage = 'warehouses'
    const primaryWh = warehousesPage + '__primary'

    const handleGlobalFilter = (searchQuery: string) => {
        setGlobalFilter(searchQuery)
        setPagination({ ...pagination, pageIndex: 0 })
    }

    const navigateToPrimary = () => {
        if (primary) {
            localStorage.setItem(CONSTS.warehouseId, primary.id)
            navigate(`/warehouses/${primary.id}`)
        }
    }

    const addWarehouseLabel = isMobile ? 'Add' : 'Add Warehouse'

    return (
        <div className={`${page}-container ${page}-container_wrapper`}>
            <div className={`${page}`}>
                <h1 className={`${page}__title`}>Warehouses</h1>
                <div className={`${card}`}>
                    <div className={`${card}__header`}>
                        <p className={`${card}__title`}>Primary Warehouse</p>
                    </div>
                    {isLoading ? (
                        <div className="olimp-table__loading">
                            <Progress />
                        </div>
                    ) : primary ? (
                        <div className={`${primaryWh}`}>
                            <div className={`${primaryWh}__info`}>
                                <div>
                                    <p className={`${primaryWh}__label`}>Name</p>
                                    <p>{primary.name || '—'}</p>
                                </div>
                                <div>
                                    <p className={`${primaryWh}__label`}>Location</p>
                                    <p>{primary.location || '—'}</p>
                                </div>
                                <div>
                                    <p className={`${primaryWh}__label`}>Primary Contact</p>
                                    <p>
                                        {primary.contact?.firstName} {primary.contact?.lastName || '—'}
                                    </p>
                                </div>
                            </div>
                            <Button
                                className={`${primaryWh}__details-btn`}
                                type="button"
                                types={['blue']}
                                label="Details"
                                onClick={() => {
                                    navigateToPrimary()
                                    sendCustomEvent('primary_warehouse_details_clicked')
                                }}
                            />
                        </div>
                    ) : (
                        <p className={`${primaryWh}`}>No primary warehouse has been selected</p>
                    )}
                </div>
                <div className={`${card}`}>
                    <div className={`${card}__header`}>
                        <p className={`${card}__title`}>All Warehouses</p>
                        {state.userInfo!.admin && (
                            <Button
                                type="button"
                                types={['bordered']}
                                label={addWarehouseLabel}
                                onClick={() => {
                                    showAddWarehouseModal({
                                        props: {
                                            onClose: handleAddWarehouse,
                                        },
                                    })
                                    sendCustomEvent('add_warehouse_clicked')
                                }}
                            />
                        )}
                    </div>
                    <Search
                        className={`${warehousesPage}__wh-search`}
                        onSearch={handleGlobalFilter}
                        filterValue={globalFilter}
                        label="Search"
                        onBlur={() => {
                            if (globalFilter) {
                                sendCustomEvent('warehouses_tables_search', globalFilter)
                            }
                        }}
                    />
                    <ModernTable
                        columns={columns}
                        data={paginatedItems}
                        globalFilter={globalFilter}
                        total={filteredWarehousesList.length}
                        pagination={pagination}
                        onPaginationChange={(pag) => {
                            setPagination(pag)
                            return pag
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Warehouses
