import React, { useRef, useState } from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Account } from '@/entities/accounts/model'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { Separator } from '@/shared/ui/Separator'
import { Textarea } from '@/shared/ui/Textarea'
import { useFormik } from 'formik'
import { Button } from '@/shared/ui/Button'
import { toast } from 'react-toastify'
import { isFloorLoaded } from '@/pages/Order/ui/OrderQuote/lib'
import { StandardQuoteErrors, StandardQuoteValues } from '@/pages/Order/ui/OrderQuote/model'
import {
    getSQValuesFromQuote,
    getServicesFromOrder,
    getDefaultSQValues,
    standardQuoteService,
} from '@/pages/Order/ui/OrderQuote/lib'
import { validateSQServices, mapSQValuesToSend } from '../lib'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { RfqService } from '@/entities/rfq/model'
import { InInfoCircle } from '@/shared/ui/Icons/InInfoCircle'
import { QuoteSubmitModal } from './QuoteSubmitModal'
import { reviseQuote, submitQuote, updateQuote, setQuoteMode } from '../model'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

type OrderQuoteStandardSubmitProps = {
    mode?: undefined | 'default'
    submittedQuote?: never
}

type OrderQuoteStandardEditProps = {
    mode: 'edit' | 'revise'
    submittedQuote: Quote
}

type OrderQuoteStandardProps = OrderQuoteStandardSubmitProps | OrderQuoteStandardEditProps

export const QuoteStandard = ({ mode, submittedQuote }: OrderQuoteStandardProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const warehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse) as Account

    const isEditMode = mode === 'edit' || mode === 'revise'

    const services = getServicesFromOrder(order)
    const isUnitFloorLoaded = isFloorLoaded(order.unitType)

    const [errors, setErrors] = useState<StandardQuoteErrors>({})
    const [globalError, setGlobalError] = useState<string | null>(null)

    /** Form data. Contain fields based on services from order. */
    const servicesValuesRef = useRef<StandardQuoteValues>(
        isEditMode
            ? getSQValuesFromQuote({
                  order,
                  quote: submittedQuote!,
                  userType: 'supply',
                  mode: 'write',
              })
            : getDefaultSQValues(order),
    )

    const validate = () => {
        let isError = false

        if (form.values.price) {
            if (isNaN(Number(form.values.price)) || Number(form.values.price) < 0) {
                form.setFieldError('price', 'Price must be a positive number or an empty field')
                isError = true
            }
            if (Number(form.values.price) > 99_999_999.99) {
                form.setFieldError('price', 'Price exceeds the maximum allowed value')
                isError = true
            }
        }

        const servicesToCheck = mapSQValuesToSend(servicesValuesRef.current)

        const result = validateSQServices(servicesToCheck)

        if (!result.isValid) {
            if (result.genericError) {
                setGlobalError(result.genericError)
                toast(result.genericError, {
                    type: 'error',
                })
            } else {
                setErrors(result.errors!)
                toast('You have errors. Please check again.', {
                    type: 'error',
                })
            }
            isError = true
        }

        return !isError
    }

    const form = useFormik({
        initialValues: isEditMode
            ? {
                  notes: submittedQuote?.notes ?? '',
                  price: submittedQuote?.price?.toString() ?? '',
              }
            : {
                  price: '',
                  notes: '',
              },
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const isValid = validate()
                if (!isValid) return

                const servicesToSubmit = mapSQValuesToSend(servicesValuesRef.current)

                if (mode === 'edit' || mode === 'revise') {
                    const newQuote = {
                        orderKey: order.id,
                        quoteId: submittedQuote.id,
                        price: values.price.trim() ? Number(values.price) : undefined,
                        notes: values.notes.trim(),
                        services: servicesToSubmit,
                    }

                    if (mode === 'edit') {
                        await dispatch(updateQuote(newQuote)).unwrap()
                        sendCustomEvent('standard_quote_updated', newQuote)
                    } else {
                        await dispatch(reviseQuote(newQuote)).unwrap()
                        sendCustomEvent('standard_quote_revised', newQuote)
                    }
                } else {
                    const newQuote = {
                        orderKey: order.id,
                        warehouseId: warehouse.id,
                        type: QuoteType.STANDARD,
                        price: values.price.trim() ? Number(values.price) : undefined,
                        notes: values.notes.trim(),
                        services: servicesToSubmit,
                    }

                    await dispatch(submitQuote(newQuote)).unwrap()

                    sendCustomEvent('standard_quote_created', newQuote)
                }

                toast(
                    `Your quote is successfully ${
                        mode === 'edit' ? 'updated' : mode === 'revise' ? 'revised' : 'submitted'
                    }.`,
                    { type: 'success' },
                )

                setConfirmDialogOpen(false)
            } catch (error: any) {
                console.error(error)
                let errorMessage = ''
                if (error?.message) {
                    errorMessage = error?.message
                } else {
                    errorMessage = `We could not ${
                        mode === 'edit' ? 'update' : mode === 'revise' ? 'revise' : 'submit'
                    } your bid. We are sorry!`
                }
                toast(errorMessage, {
                    type: 'error',
                })
            }
        },
    })

    /** Confirm dialog controlled state */
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const onConfirmDialogOpenChange = async (open: boolean) => {
        form.setFieldError('price', '')
        setGlobalError(null)
        setErrors({})

        if (!open) {
            setConfirmDialogOpen(open)
            return
        }

        const isValid = validate()
        if (isValid) {
            setConfirmDialogOpen(open)
        }
    }

    return (
        <div className="space-y-5">
            <form
                onSubmit={form.handleSubmit}
                className="border border-border p-0 sm:p-3 flex flex-col sm:gap-4 rounded-xl"
            >
                <div className="hidden sm:flex gap-5 text-foreground-secondary items-center">
                    <div className="sm:w-[100px] lg:w-[170px] text-sm font-medium">Service Requested</div>
                    <Separator orientation="vertical" className="h-auto self-stretch" />
                    <div className="text-sm font-medium">Pricing</div>
                </div>

                {services.map((s, index) => {
                    const Comp = standardQuoteService[s as RfqService]?.formComponent
                    const service = servicesValuesRef.current[s as RfqService]

                    if (!Comp || !service) return null

                    const localErrors = errors[s]

                    return (
                        <React.Fragment key={index}>
                            <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                                    {s}
                                </div>
                                <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                                <Comp
                                    order={order}
                                    isFloorLoaded={isUnitFloorLoaded}
                                    service={service}
                                    errors={localErrors}
                                />
                            </div>
                            <Separator />
                        </React.Fragment>
                    )
                })}

                <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                    <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                        Minimum Charge
                    </div>
                    <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                    <div className="w-full">
                        <CurrencyInput
                            label="Minimum (if applicable)"
                            id="price"
                            name="price"
                            value={form.values.price}
                            onValueChange={({ value }) => form.setFieldValue('price', value)}
                            allowNegative={false}
                            decimalScale={2}
                            className="w-full md:w-[200px]"
                            prefix="$"
                        />
                        {form.errors.price ? <div className="mt-1 text-sm text-danger">{form.errors.price}</div> : null}
                    </div>
                </div>

                <Separator />

                <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                    <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                        Notes
                    </div>
                    <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                    <Textarea
                        className="resize-none w-full h-36 sm:h-24"
                        placeholder="Add your notes here..."
                        name="notes"
                        value={form.values.notes}
                        onChange={form.handleChange}
                    />
                </div>
            </form>

            <div className="flex flex-col-reverse sm:flex-row gap-x-5 gap-y-2 justify-end items-end sm:items-center">
                {globalError ? (
                    <div className="flex items-center gap-1 text-sm text-danger font-medium">
                        <InInfoCircle size={16} />
                        <div>{globalError}</div>
                    </div>
                ) : null}

                <div className="flex gap-3 w-full sm:w-fit">
                    {isEditMode ? (
                        <Button
                            variant="outlined"
                            type="button"
                            onClick={() => dispatch(setQuoteMode('default'))}
                            className="basis-0 flex-grow sm:basis-auto sm:flex-grow-0"
                        >
                            Cancel
                        </Button>
                    ) : null}

                    <QuoteSubmitModal
                        open={confirmDialogOpen}
                        onOpenChange={onConfirmDialogOpenChange}
                        isLoading={form.isSubmitting}
                        onSubmit={form.submitForm}
                    />
                </div>
            </div>
        </div>
    )
}
