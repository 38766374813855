import React, { FC, memo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { Toggle } from '@/shared/ui/Toggle'
import { Switch } from '@/shared/ui/Switch'
import { Label } from '@/shared/ui/Label'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'

import './NotificationSettingsModal.scss'

const NotificationSettingsModalId = 50

interface ChangeNotificationSettingsForm {
    isMessages: boolean
    IsOrders: boolean
    IsLoadBoard: boolean
    IsEmail: boolean
    IsSMS: boolean
}

interface ChangeNotificationSettingsModalProps {}

const NotificationSettingsModal: FC<ChangeNotificationSettingsModalProps> = () => {
    const { state: userState } = useUserState()
    const isDemandAccount = userState.userInfo?.role === UserRole.FREIGHT_BROKER_MANAGER
    const [errorMessage, setErrorMessage] = useState<string>('')
    const formik = useFormik<ChangeNotificationSettingsForm>({
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true,
        initialValues: {
            isMessages: false,
            IsOrders: false,
            IsLoadBoard: false,
            IsEmail: false,
            IsSMS: false,
        },
        onSubmit: async (values) => {
            const {
                isMessages,
                IsOrders,
                IsLoadBoard,
                IsEmail,
                IsSMS,
            } = values
            try {
                formik.setSubmitting(true)
                setErrorMessage('')

                await HttpV2.put('/sso/notification_settings',
                    {
                        isMessages,
                        IsOrders,
                        IsLoadBoard,
                        IsEmail,
                        IsSMS,
                    })

                ModalRegistry.get().close(NotificationSettingsModalId)
            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                formik.setSubmitting(false)
            }
        },
    })

    return (
        <div className="modal-notification-settings">
            <form onSubmit={formik.handleSubmit} className="notification-settings-form">
                <div className="">
                    <div className="text-sm">Select applicable:</div>

                    <div className="flex items-center gap-2 mt-1">
                        <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="isMessages"
                            defaultPressed={formik.values.isMessages}
                            onPressedChange={(pressed) => formik.setFieldValue('isMessages', pressed)}
                        >
                            Messages
                        </Toggle>
                        <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="IsOrders"
                            defaultPressed={formik.values.IsOrders}
                            onPressedChange={(pressed) => formik.setFieldValue('IsOrders', pressed)}
                        >
                            Orders
                        </Toggle>
                        {!isDemandAccount ? <Toggle
                            variant="outlined"
                            className="w-[140px] bg-background-tertiary"
                            name="IsLoadBoard"
                            defaultPressed={formik.values.IsLoadBoard}
                            onPressedChange={(pressed) => formik.setFieldValue('IsLoadBoard', pressed)}
                        >
                            Load Board
                        </Toggle> : null}
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <Switch
                        id="email-notifications"
                        name="IsEmail"
                        defaultChecked={formik.values.IsEmail}
                        onChange={(checked) => formik.setFieldValue('IsEmail', checked)}
                    />
                    <Label htmlFor="email-notifications" className="font-normal text-sm">Email Notifications</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <Switch
                        id="sms-notifications"
                        name="IsSMS"
                        defaultChecked={formik.values.IsSMS}
                        onChange={(checked) => formik.setFieldValue('IsSMS', checked)}
                    />
                    <Label htmlFor="sms-notifications" className="font-normal text-sm">SMS Notifications</Label>
                </div>


                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(NotificationSettingsModalId)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    textSubmit="Save"
                    stylesSubmit={{ width: '80px' }}
                    buttonsPosition="right"
                    errorMessage={errorMessage}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, {
    id: 'ChangeNotificationSettingsModalProps',
    className: 'modal-notification-settings',
    size: 'medium',
    Component: memo(NotificationSettingsModal),
    title: 'Notification Settings',
})

export const showNotificationSettingsModal = (props: ModalPartialProps<ChangeNotificationSettingsModalProps>): void =>
    ModalRegistry.get().show<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, props)

export default memo(NotificationSettingsModal)
