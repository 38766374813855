import React, { FC, memo, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { useFormik } from 'formik'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import Input from '@/components/InputLabel'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ROUTES from '@/router'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './ChangePasswordModal.scss'
interface ChangePasswordForm {
    password: string
    password_repeat: string
}

const ChangePasswordFormSchema = Yup.object().shape({
    password: Yup.string()
        .min(7)
        .max(50, 'Password must be 50 characters or less')
        .notOneOf([Yup.ref('old_password'), undefined], 'New password must be different from old')
        .required(),
    password_repeat: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
})

interface ChangePasswordModalProps {}

const ChangePasswordModal: FC<ChangePasswordModalProps> = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const navigate = useNavigate()

    const [errorMessage, setErrorMessage] = useState<string>('')
    const formik = useFormik<ChangePasswordForm>({
        validateOnBlur: true,
        validateOnChange: false,
        initialValues: { password: '', password_repeat: '' },
        validationSchema: ChangePasswordFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (values.password !== values.password_repeat) {
                formik.setErrors({
                    password_repeat: 'Passwords do not match',
                })
                formik.setSubmitting(false)
                return
            }

            try {
                formik.setSubmitting(true)
                setErrorMessage('')

                await HttpV2.put('/sso/password', { password: values.password })

                ModalRegistry.get().close(Modals.ChangePasswordModal)
                redirectToSSOSignIn()
            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                formik.setSubmitting(false)
                sendCustomEvent('password_changed', {
                    success: errorMessage === '',
                })
            }
        },
    })

    const redirectToSSOSignIn = () => {
        toast.warn('You will be logged out and need to sign in again after your password is changed.', {
            autoClose: 3000,
        })

        setTimeout(() => {
            toast.success('Password successfully changed!', {
                autoClose: 3000,
            })

            setTimeout(() => {
                navigate(ROUTES.public.logout)
                navigate(ROUTES.public.login)
            }, 3000)
        }, 4000)
    }

    return (
        <div className="modal-change-password">
            <form onSubmit={formik.handleSubmit} className="change-password-form">
                <Input
                    label="New Password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.password}
                    autoComplete="new-password"
                />

                <Input
                    label="Confirm New Password"
                    name="password_repeat"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password_repeat}
                    errorMessage={formik.errors.password_repeat}
                    onBlur={formik.handleBlur}
                    autoComplete="new-password"
                />

                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(Modals.ChangePasswordModal)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    textSubmit="Save"
                    stylesSubmit={{ width: '80px' }}
                    buttonsPosition="right"
                    errorMessage={errorMessage}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<ChangePasswordModalProps>(Modals.ChangePasswordModal, {
    id: 'ChangePasswordModalProps',
    className: 'modal-change-password',
    size: 'medium',
    Component: memo(ChangePasswordModal),
    title: 'Change Password',
})

export const showChangePasswordModal = (props: ModalPartialProps<ChangePasswordModalProps>): void =>
    ModalRegistry.get().show<ChangePasswordModalProps>(Modals.ChangePasswordModal, props)

export default memo(ChangePasswordModal)
