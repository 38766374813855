import React, { FC } from 'react'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { Section } from '@/components/Section'
import Button from '@/components/Button'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const HelpLinks: FC = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const contactSupport = () => {
        showFeedbackModal({
            props: {},
        })
    }

    const handleContactSupportClick = () => {
        sendCustomEvent('contact_support_clicked')
        contactSupport()
    }

    return (
        <Section className="flex flex-col gap-3">
            <h3 className="text-xl">Help Links</h3>
            <ul>
                <li>
                    <Button
                        className="!h-auto p-0 text-primary underline !font-normal"
                        onClick={handleContactSupportClick}
                    >
                        Contact Support
                    </Button>
                </li>
            </ul>
        </Section>
    )
}

export default HelpLinks
