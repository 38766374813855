import React, { FC, memo, useEffect, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { toast } from 'react-toastify'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { useNavigate } from 'react-router-dom'
import ROUTES from '@/router'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './DeleteStaffModal.scss'

interface DeleteStaffModalProps {
    modalTitle?: string
    displayData?: {
        [key: string]: any
    }
    children?: React.ReactNode
    deleteApiLink?: string
    refreshWarehouses?: any
    confirmButtonTitle?: string
    confirmButtonClassName?: string
}

const DeleteStaffClass = 'deleteStaffModal'

const DeleteStaffModal: FC<DeleteStaffModalProps> = ({
    modalTitle,
    displayData,
    children,
    deleteApiLink,
    refreshWarehouses,
    confirmButtonTitle = 'Delete',
    confirmButtonClassName = '',
}: DeleteStaffModalProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const deleteAction = async () => {
        setLoading(true)
        // setErrorMessage('')

        let success = false
        //To do: implement delete account API
        await HttpV2.delete(deleteApiLink!)
            .then((data) => {
                // console.log('deleted data: ', data)
                toast('Your account was successfully deleted!', {
                    type: 'success',
                })
                refreshWarehouses()
                ModalRegistry.get().close(Modals.DeleteStaffModal)
                success = true
            })
            .catch((err: any) => {
                // setErrorMessage('DELETE ACCOUNT API IS NOT IMPLEMENTED!')
                toast(err?.response?.data?.message, { type: 'error' })
                console.log(`Error: ${err?.response?.data}`)
            })
            .finally(() => {
                setLoading(false)
                sendCustomEvent('staff_deleted', {
                    success,
                })
            })

        // navigate(ROUTES.public.logout)
    }

    return (
        <div className={`${DeleteStaffClass}`}>
            {modalTitle && <div className="modal-header">{modalTitle}</div>}

            {displayData && (
                <div className={`${DeleteStaffClass}__content`}>
                    {Object.entries(displayData).map(([key, value]) => (
                        <p key={key}>{value}</p>
                    ))}
                </div>
            )}

            {children && <div className={`${DeleteStaffClass}__content`}>{children}</div>}

            <div className="modal-btns-wrapper">
                <Button
                    types={['inverse']}
                    label="Cancel"
                    onClick={() => {
                        ModalRegistry.get().close(Modals.DeleteStaffModal)
                        sendCustomEvent('staff_delete_cancelled')
                    }}
                />
                <Button
                    className={`${DeleteStaffClass}__btn-delete ${confirmButtonClassName}`}
                    types={['red']}
                    label={confirmButtonTitle}
                    onClick={deleteAction}
                    loading={loading}
                    disabled={loading}
                />
            </div>

            {errorMessage && <div className="error-message-general">{errorMessage}</div>}
        </div>
    )
}

ModalRegistry.get().register<DeleteStaffModalProps>(Modals.DeleteStaffModal, {
    id: 'DeleteStaffModal',
    className: 'modal-delete-staff',
    size: 'medium',
    Component: memo(DeleteStaffModal),
})

export const showDeleteStaffModal = (props: ModalPartialProps<DeleteStaffModalProps>): void =>
    ModalRegistry.get().show<DeleteStaffModalProps>(Modals.DeleteStaffModal, props)

export default memo(DeleteStaffModal)
