import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { InClose2 } from '@/shared/ui/Icons/InClose2'
import { cn } from '@/shared/utils/cn'
import { Loader } from '@/shared/ui/Loader'
import React, { FC } from 'react'
import { UploadingMedia } from '@/pages/Order/ui/OrderChat/model/types'

interface FilePreviewProps {
    index: number
    media: UploadingMedia
    onRemove?: (index: number) => void
}

export const ChatFileUploadPreview: FC<FilePreviewProps> = ({ index, media, onRemove }) => {
    return (
        <div key={`media-${index}`} className={'flex gap-2 items-center relative'}>
            {!media.loading && (
                <div
                    className="hover:cursor-pointer right-0 -top-1 absolute z-10"
                    onClick={() => {
                        if (onRemove) {
                            onRemove(index)
                        }
                    }}
                >
                    <div className="text-red-500 hover:text-red-600">
                        <InClose2 size={14} />
                    </div>
                </div>
            )}
            <div className="w-[100px] h-[80px] rounded border border-gray-3 flex justify-start flex-col relative">
                {!media.previewLoading && (
                    <img
                        src={media.preview!}
                        alt="File Preview"
                        className={cn('w-auto max-h-[60px] margin-b-2 rounded-tr rounded-tl object-contain', {
                            'opacity-50': media.loading,
                        })}
                    />
                )}
                {(media.loading || media.previewLoading) && (
                    <Loader
                        wrapperClassName="absolute left-0 right-0 top-0 bottom-0 margin-auto"
                        className="h-4 w-4 border-2 border-accent-medium border-t-primary"
                    />
                )}
                <div className="text-[10px] pb-1 px-1 text-black whitespace-nowrap pr-2 truncate max-w-full">
                    {media.name}
                </div>
            </div>
        </div>
    )
}
