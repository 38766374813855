import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'

export const HandlingBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium flex">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                    <HintRoot>
                        <HintTrigger asChild>
                            <div className="ml-1 -mt-[1px]">
                                <FaInfoCircle size={16} />
                            </div>
                        </HintTrigger>
                        <HintContent sideOffset={-3}>
                            <HintArrow /> Combined In and Out Charges per Unit
                        </HintContent>
                    </HintRoot>
                </div>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <CurrencyDisplay
                        value={service.pricings[RfqServicePricing.HandlingTotal]!.price}
                        className="text-xl leading-6 text-primary font-semibold"
                    />
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        </div>
    )
}
