import React, { useState } from 'react'
import { Select, SelectOption } from '@/shared/ui/Select'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { cn } from '@/shared/utils/cn'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { toast } from 'react-toastify'
import { warehouseOrdersApi } from '@/entities/warehouse-orders/api'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { orderPageActions } from '@/pages/Order/model'
import { Badge } from '@/shared/ui/Badge'
import { RfqStatus } from '@/entities/rfq/model'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'

const STAGE_OPTIONS: SelectOption[] = [
    {
        value: RfqStatus.Booked.toString(),
        label: RfqStatus[RfqStatus.Booked],
    },
    {
        value: RfqStatus.Dropped.toString(),
        label: RfqStatus[RfqStatus.Dropped],
    },
    {
        value: RfqStatus.Finished.toString(),
        label: RfqStatus[RfqStatus.Finished],
    },
]

export const OrderStageSelect = () => {
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const activeQuote = useAppSelector((state) => state.orderQuoteSupply.activeQuote) as Quote

    const [isLoading, setIsLoading] = useState(false)

    const onStageChange = async (status: string) => {
        const numericStatus = +status
        if (
            numericStatus !== RfqStatus.Booked &&
            numericStatus !== RfqStatus.Dropped &&
            numericStatus !== RfqStatus.Finished
        ) {
            return
        }

        setIsLoading(true)

        try {
            await warehouseOrdersApi.updateOrderStatus({
                warehouseId: activeQuote.warehouseId,
                orderKey: order.id,
                status: numericStatus,
            })

            dispatch(orderPageActions.setOrder({ ...order, rfqStatus: numericStatus }))

            toast(`Status has been changed to ${RfqStatus[numericStatus]}.`, { type: 'success' })
        } catch (error: any) {
            toast(error.response?.data?.message ?? 'Could not update order status', { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    if (activeQuote.status !== QuoteStatus.ACCEPTED || !order.rfqStatus || order.rfqStatus >= RfqStatus.Cancelled) {
        return null
    }

    if (order.rfqStatus === RfqStatus.Finished && order.paid) {
        return (
            <Badge className="shrink-0 justify-center w-full md:w-[210px] rounded-lg h-9 bg-success hover:bg-success/90 text-white text-base font-medium">
                Paid
            </Badge>
        )
    }

    return (
        <Select
            triggerClassName={cn(
                'shrink-0 w-full md:w-[210px] text-white border-0 items-center',
                order.rfqStatus === RfqStatus.Booked && 'bg-warning hover:bg-warning/90',
                order.rfqStatus === RfqStatus.Dropped && 'bg-primary hover:bg-primary/90',
                order.rfqStatus === RfqStatus.Finished && 'bg-success hover:bg-success/90',
            )}
            options={STAGE_OPTIONS}
            value={order.rfqStatus!.toString()}
            onValueChange={onStageChange}
            disabled={isLoading}
            iconWrapperClassName="!opacity-100 text-white"
            icon={
                isLoading ? (
                    <div>
                        <ImSpinner8 size={14} className="mr-2.5 animate-spin text-white" />
                    </div>
                ) : undefined
            }
        />
    )
}
