import { AxiosRequestConfig } from 'axios'
import {
    Quote,
    AcceptQuoteRequest,
    RejectRequest,
    SubmitQuoteRequest,
    UpdateQuoteRequest,
    CancelQuoteRequest,
} from '@/entities/quotes/model'
import { HttpV2 } from '@shared/apiv2/api'

const submitQuote = async (body: SubmitQuoteRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post<Quote>(
        `/load-board/${body.orderKey}/bid`,
        {
            warehouseId: body.warehouseId,
            type: body.type,
            price: body.price,
            notes: body.notes,
            services: body.services,
        },
        config,
    )
    return response
}

const updateQuote = async (body: UpdateQuoteRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put<Quote>(
        `/load-board/${body.orderKey}/bid/${body.quoteId}`,
        {
            price: body.price,
            notes: body.notes,
            services: body.services,
        },
        config,
    )
    return response
}

const reviseQuote = async (body: UpdateQuoteRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put<[Quote, Quote]>(
        `/load-board/${body.orderKey}/bid/${body.quoteId}/revise`,
        {
            price: body.price,
            notes: body.notes,
            services: body.services,
        },
        config,
    )
    return response
}

const acceptQuote = async (body: AcceptQuoteRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put(`/load-board/${body.orderKey}/bid/${body.quoteId}/accept`, config)
    return response
}

const rejectQuote = async (body: RejectRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put(
        `/load-board/${body.orderKey}/bid/${body.quoteId}/reject`,
        { reason: body.reason, reasonComment: body.reasonComment },
        config,
    )
    return response
}

const requote = async (body: RejectRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put(
        `/load-board/${body.orderKey}/bid/${body.quoteId}/requote`,
        { reason: body.reason, reasonComment: body.reasonComment },
        config,
    )
    return response
}

const cancelQuote = async (body: CancelQuoteRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.put(`/load-board/${body.orderKey}/bid/${body.quoteId}/cancel`, config)
    return response
}

export const quotesApi = {
    submitQuote,
    updateQuote,
    reviseQuote,
    acceptQuote,
    rejectQuote,
    cancelQuote,
    requote,
}
