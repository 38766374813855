import React, { FC, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'

import Button from '@/components/Button'
import useWarehousesStaffColumns from './useWarehousesStaffColumns'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import Search from '@/components/Search'
import { showAddStaffModal } from '@/parts/Modals/AddStaff'
import useCustomPagination, { PaginationInfo } from '@/hooks/useCustomPagination'
import HelpLinks from '@/parts/Cards/HelpLinks'
import { useUserState } from '@/store/user'
import { PermissionLevel } from '@/parts/Modals/ManageStaffModal'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './WarehousesStaff.scss'

export interface WarehouseStaff {
    id: string
    firstName: string
    lastName: string
    email: string
    permission: number
    permissionId: number
    phone: string
    title: string
}

const WarehousesStaff: FC = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const location = useLocation()
    const warehouseAcc = location.pathname.split('/')[2]

    const { state } = useUserState()
    const { userInfo } = state

    const [warehouseStaffList, setWarehouseStaffList] = useState<WarehouseStaff[]>([])
    const loggedInUser = warehouseStaffList.find((contact: any) => contact.id === userInfo?.sfContactId)

    const [updateWHStaffList, setUpdateWHStaffList] = useState<boolean>(false)

    const triggerUpdateWHStaffList = useCallback(() => {
        setUpdateWHStaffList((prev) => !prev)
    }, [])

    const isAdmin = userInfo?.type === 'admin'
    const staffColumns = useWarehousesStaffColumns(loggedInUser, warehouseAcc, triggerUpdateWHStaffList, isAdmin)

    const [isStaffListLoading, setIsStaffListLoading] = useState<boolean>()
    const [pagination, setPagination] = useState<PaginationInfo>({ pageIndex: 0, pageSize: 10 })
    const [filter, setFilter] = useState('')

    const { paginatedItems } = useCustomPagination(warehouseStaffList, pagination)

    const handleFilter = (searchQuery: string) => {
        setFilter(searchQuery)
        setPagination({ ...pagination, pageIndex: 0 })
    }

    const fetchStaff = async (searchFilter: string) => {
        const updatedSearchFilter = searchFilter.trim().startsWith('+') ? searchFilter.substring(1) : searchFilter

        try {
            setIsStaffListLoading(true)

            const response: any = await HttpV2.get(
                `accounts/${warehouseAcc}/contacts/permissions?search=${updatedSearchFilter}`,
            )
            const { data } = response

            setWarehouseStaffList(data)

            return { status: 'success', data: data }
        } catch (error: any) {
            return { status: 'error', message: error.message }
        } finally {
            setIsStaffListLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff(filter)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, updateWHStaffList, warehouseAcc])

    return (
        <div className="app-page-container app-page-container_flex">
            <div className="app-page">
                <div className="app-card">
                    <div className="olimp-table__header">
                        <h2>Staff</h2>
                        <div className="olimp-table__search-wrapper">
                            <Search
                                placeholder="Search by Name, Title, Phone, Email"
                                onSearch={handleFilter}
                                filterValue={filter}
                                label="Search"
                                onBlur={() =>
                                    filter &&
                                    sendCustomEvent('warehouses_staff_search', {
                                        searchQuery: filter,
                                    })
                                }
                            />
                        </div>
                        {(isAdmin || (loggedInUser && loggedInUser?.permission > PermissionLevel.User)) && (
                            <Button
                                types={['bordered']}
                                onClick={() => {
                                    showAddStaffModal({
                                        props: {},
                                    })

                                    sendCustomEvent('add_staff_clicked')
                                }}
                            >
                                Add Staff
                            </Button>
                        )}
                    </div>
                    <ModernTable
                        columns={staffColumns}
                        data={paginatedItems}
                        loading={isStaffListLoading}
                        total={warehouseStaffList.length}
                        pagination={pagination}
                        onPaginationChange={(pag) => {
                            setPagination(pag)
                            return pag
                        }}
                    />
                </div>
            </div>
            <div className="app-sidebar">
                <HelpLinks />
            </div>
        </div>
    )
}

export default WarehousesStaff
