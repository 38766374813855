import React from 'react'
import { useAppSelector } from '@/app/store'
import { Quote, QuoteStatus, RequoteReason, RequoteReasonText } from '@/entities/quotes/model'
import { InClose } from '@/shared/ui/Icons/InClose'
import { InInfoCircle } from '@/shared/ui/Icons/InInfoCircle'
import { InCheckmark2 } from '@/shared/ui/Icons/InCheckmark2'

export const QuoteNotification = () => {
    const quote = useAppSelector((state) => state.orderQuoteSupply.activeQuote) as Quote

    const quoteMode = useAppSelector((state) => state.orderQuoteSupply.quoteMode)
    const isEditMode = quoteMode === 'edit' || quoteMode === 'revise'

    if (
        quote.status === QuoteStatus.CREATED ||
        quote.status === QuoteStatus.QUOTED ||
        (quote.status === QuoteStatus.REQUOTE && quote.rejectReason === RequoteReason.Price)
    ) {
        if (isEditMode) return null

        return (
            <div className="bg-background-tertiary border border-success rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-success">
                    <InCheckmark2 size={16} />
                    <p className="font-medium">Quote submitted</p>
                </div>
                <p>Your quote has been successfully submitted.</p>
            </div>
        )
    }

    if (quote.status === QuoteStatus.REQUOTE) {
        return (
            <div className="bg-background-tertiary border border-warning rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-warning">
                    <InInfoCircle size={20} />
                    <p className="font-medium">Requote Requested</p>
                </div>
                <p>
                    Customer has requested requote
                    {!quote.rejectReason ? (
                        '.'
                    ) : (
                        <span>
                            &nbsp;with the reason: <b>{RequoteReasonText[quote.rejectReason as RequoteReason]}</b>
                        </span>
                    )}
                </p>
                {quote.rejectReasonComment && (
                    <p className="break-word">
                        <div>
                            <b>Comment:</b>
                        </div>
                        {quote.rejectReasonComment}
                    </p>
                )}
            </div>
        )
    }

    if (quote.status === QuoteStatus.DECLINED) {
        return (
            <div className="bg-background-tertiary border border-danger rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-danger">
                    <InClose size={16} />
                    <p className="font-medium">Quote Rejected</p>
                </div>
                <p>
                    This quote has been rejected
                    {!quote.rejectReason ? (
                        '.'
                    ) : (
                        <span>
                            &nbsp;with the reason: <b>{quote.rejectReason}</b>
                        </span>
                    )}
                </p>
                {quote.rejectReasonComment && (
                    <p className="break-word">
                        <div>
                            <b>Comment:</b>
                        </div>
                        {quote.rejectReasonComment}
                    </p>
                )}
            </div>
        )
    }

    return null
}
