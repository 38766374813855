import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InDownload = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 11H10.8281L9.41406 12.4141C9.0375 12.7906 8.53438 13 8 13C7.46562 13 6.96375 12.792 6.58594 12.4141L5.17188 11H1C0.447812 11 0 11.4478 0 12V15C0 15.5522 0.447812 16 1 16H15C15.5522 16 16 15.5522 16 15V12C16 11.4469 15.5531 11 15 11ZM13.5 14.25C13.0875 14.25 12.75 13.9125 12.75 13.5C12.75 13.0875 13.0875 12.75 13.5 12.75C13.9125 12.75 14.25 13.0875 14.25 13.5C14.25 13.9125 13.9125 14.25 13.5 14.25ZM7.29375 11.7063C7.4875 11.9031 7.74375 12 8 12C8.25625 12 8.51188 11.9023 8.70687 11.707L12.7069 7.70703C13.0972 7.31641 13.0972 6.68359 12.7069 6.29297C12.3162 5.90234 11.6831 5.90234 11.2928 6.29297L9 8.5875V1C9 0.447812 8.55219 0 8 0C7.44688 0 7 0.447812 7 1V8.5875L4.70625 6.29375C4.31594 5.90312 3.68281 5.90312 3.29219 6.29375C2.90188 6.68437 2.90188 7.31719 3.29219 7.70781L7.29375 11.7063Z" />
            </svg>
        </IconBase>
    )
}
