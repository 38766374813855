import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { cn } from '@/shared/utils/cn'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { AccordionMultipleProps, AccordionSingleProps } from '@radix-ui/react-accordion'
import { SelectItem, SelectOption } from '@/shared/ui/Select'

const AccordionRoot = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item ref={ref} className={cn('border-b border-border', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

type AccordionTriggerProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    iconClassName?: string
    icon?: React.ReactNode
}

const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, AccordionTriggerProps>(
    ({ className, children, iconClassName, icon, ...props }, ref) => (
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'flex w-full items-center justify-between h-9 px-0 !text-sm !font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:hover:no-underline',
                className,
            )}
            {...props}
        >
            {children}
            {icon !== undefined ? (
                icon
            ) : (
                <InChevronDown
                    className={cn(
                        'h-3 w-3 mr-1 shrink-0 transition-transform duration-200 text-foreground-secondary/70',
                        iconClassName,
                    )}
                />
            )}
        </AccordionPrimitive.Trigger>
    ),
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
        {...props}
    >
        <div className={cn('pb-2 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export type AccordionOption = {
    value: string
    trigger: React.ReactNode
    content: React.ReactNode
}

type AccordionProps = (AccordionSingleProps | AccordionMultipleProps) & {
    items: AccordionOption[]

    itemClassName?: string
    itemPropsBuilder?: (
        option: AccordionOption,
        index: number,
    ) => Omit<React.ComponentProps<typeof AccordionItem>, 'value'>

    triggerClassName?: string
    triggerPropsBuilder?: (option: AccordionOption, index: number) => React.ComponentProps<typeof AccordionTrigger>

    contentClassName?: string
    contentPropsBuilder?: (option: AccordionOption, index: number) => React.ComponentProps<typeof AccordionContent>
}

const Accordion = ({
    items,
    className,

    itemClassName,
    itemPropsBuilder,

    triggerClassName,
    triggerPropsBuilder,

    contentClassName,
    contentPropsBuilder,
    ...rest
}: AccordionProps) => (
    <AccordionRoot {...rest} className={className}>
        {items.map((item, index) => {
            const itemProps = itemPropsBuilder?.(item, index)
            const triggerProps = triggerPropsBuilder?.(item, index)
            const contentProps = contentPropsBuilder?.(item, index)

            return (
                <AccordionItem
                    key={item.value}
                    value={item.value}
                    {...itemProps}
                    className={cn(itemClassName, itemProps?.className)}
                >
                    <AccordionTrigger {...triggerProps} className={cn(triggerClassName, triggerProps?.className)}>
                        {item.trigger}
                    </AccordionTrigger>
                    <AccordionContent {...contentProps} className={cn(contentClassName, contentProps?.className)}>
                        {item.content}
                    </AccordionContent>
                </AccordionItem>
            )
        })}
    </AccordionRoot>
)

export { AccordionRoot, AccordionItem, AccordionTrigger, AccordionContent, Accordion }
