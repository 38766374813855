import { Badge } from '@/shared/ui/Badge'
import { Avatar, AvatarFallback, AvatarImage } from '@/shared/ui/Avatar'
import { InStorage } from '@/shared/ui/Icons/InStorage'
import React, { FC, useMemo } from 'react'
import { Loader, LoaderProps } from '@/shared/ui/Loader'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { cn } from '@/shared/utils/cn'

export const OrdersChatsSidebar: FC<{ reference: string; chats: any; demand: any; loaderProps?: LoaderProps }> = ({
    reference,
    chats,
    demand,
    loaderProps,
}) => {
    const appDispatch = useAppDispatch()
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)

    const supplyChats = useMemo(
        () =>
            chats &&
            chats
                .filter((c: any) => c.messaging_mode === 'SUPPLY_PSM')
                .map((chat: any) => {
                    const active =
                        (typeof selectedChat === 'number' && chat.id === selectedChat) ||
                        chat.messaging_mode === selectedChat
                    return (
                        <div
                            key={chat.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                appDispatch(orderChatActions.selectChat(chat.id ?? chat.messagingMode))
                                appDispatch(orderChatActions.setActiveMessageBoardReference(reference))
                            }}
                            className={cn(
                                'flex flex-col py-2 px-2  hover:cursor-pointer hover:font-semibold rounded transition-colors',
                                {
                                    'font-semibold bg-blue-50': active,
                                },
                            )}
                        >
                            <div className="flex text-black  items-center">
                                <span className="flex-grow">{chat.account_name}</span>
                                {chat.unseen_messages_count > 0 && (
                                    <Badge className="bg-red-400 circle w-5 h-5 text-[11px] p-0 inline-flex justify-center self-end">
                                        {chat.unseen_messages_count}
                                    </Badge>
                                )}
                            </div>
                        </div>
                    )
                }),
        [appDispatch, chats, reference, selectedChat],
    )

    const demandChats = useMemo(
        () =>
            chats &&
            chats
                .filter((c: any) => c.messaging_mode === 'DEMAND_PSM')
                .map((chat: any, index: number) => {
                    const active =
                        (typeof selectedChat === 'number' && chat.id === selectedChat) ||
                        (chat.messaging_mode === selectedChat && reference === chat.entity_id)
                    return (
                        <div
                            className={cn(
                                'flex flex-col py-2 px-2  hover:cursor-pointer hover:font-semibold rounded transition-colors',
                                {
                                    'font-semibold bg-blue-50': active,
                                },
                            )}
                            key={chat.id ?? chat.messaging_mode + index}
                            onClick={(e) => {
                                e.stopPropagation()
                                appDispatch(orderChatActions.selectChat(chat.id ?? chat.messaging_mode))
                                appDispatch(orderChatActions.setActiveMessageBoardReference(reference))
                            }}
                        >
                            <div className="flex text-black  items-center">
                                <span className="flex-grow">{chat.account_name}</span>
                                {chat.unseen_messages_count > 0 && (
                                    <Badge className="bg-red-400 circle w-5 h-5 text-[11px] p-0 inline-flex justify-center self-end">
                                        {chat.unseen_messages_count}
                                    </Badge>
                                )}
                            </div>
                            <span className="flex text-black-3 text-[11px]">
                                {demand.firstName} {demand.lastName} ({demand.email})
                            </span>
                            <div className="flex mt-1">
                                {chat.participants &&
                                    chat.participants.map((participant: any, idx: number) => (
                                        <Avatar
                                            className={
                                                'border shadow border-gray-2 bg-white h-5 w-5' +
                                                (idx > 0 ? ' ml-[-8px]' : '')
                                            }
                                            key={participant.id}
                                        >
                                            <AvatarImage
                                                className="object-cover bg-white"
                                                // src should be random color
                                                alt={participant.name}
                                            />
                                            <AvatarFallback className="text-[10px] bg-white text-black inline-flex items-center justify-center">
                                                {participant.name.charAt(0)}
                                            </AvatarFallback>
                                        </Avatar>
                                    ))}
                            </div>
                        </div>
                    )
                }),
        [appDispatch, chats, reference, selectedChat],
    )
    if (!chats) {
        return <Loader {...(loaderProps ?? {})} />
    }
    return (
        <div>
            <small className="text-black-3 font-light flex items-center  border-b border-gray-1 pl-2">Customer</small>{' '}
            {demandChats.length > 0 ? demandChats : <div className="text-gray-400 pl-2">No customer chats</div>}
            <small className="text-black-3 font-light flex items-center  border-b border-gray-1 pl-2">
                <InStorage className="text-black-3 w-3 h-3 mr-2" /> Warehouses
            </small>
            {supplyChats.length > 0 ? supplyChats : <div className="text-gray-400 pl-2">No warehouse chats</div>}
        </div>
    )
}
