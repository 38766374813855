import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InFilter = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.6531 3.94641L15 14.3812V21.375C15 22.29 13.9702 22.8141 13.2305 22.2966L9.48048 19.6725C9.17813 19.4625 9.00001 19.1156 9.00001 18.75V14.3812L0.345717 3.94641C-0.467096 2.96625 0.244373 1.5 1.53283 1.5H22.4672C23.7562 1.5 24.4266 2.96625 23.6531 3.94641Z" />
            </svg>
        </IconBase>
    )
}
