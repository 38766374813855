import React, { useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import ROUTES from '@/router'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { getOLIMPLandingURL } from '@/utils/url'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'

const useNavHelper = () => {
    const {
        state: { userInfo },
    } = useUserState()
    const { data: micrositeData, slug } = useMicrosite()

    const isBroker = userInfo?.role === UserRole.FREIGHT_BROKER_MANAGER
    const isWH = userInfo?.role === UserRole.WAREHOUSE_MANAGER

    const hasMicrosite = micrositeData && micrositeData.statusCode !== 404

    const matchGeneralRfq = useMatch('/rfq')
    const matchOnDemandRfq = useMatch('/ms/on-demand-warehousing/rfq')
    const matchMsRfq = useMatch(`/ms/${slug}/rfq`)
    const matchRfqList = useMatch('/rfq/list/*')
    const matchMsRfqList = useMatch(`/ms/${slug}/rfq/list/*`)
    const matchHowItWorks = useMatch(ROUTES.more['how-it-works'])
    const matchFaq = useMatch(ROUTES.more.faq)
    const matchOrders = useMatch(ROUTES.order.base + '/*')
    const matchLoadBoard = useMatch(ROUTES.loadboard.base + '/*')
    const matchWarehouses = useMatch(ROUTES.warehouses.base + '/*')
    const matchAdmin = useMatch(ROUTES.admin.base + '/*')

    const isRfqPage = !!matchGeneralRfq || !!matchMsRfq
    const isOrdersPage = !!matchRfqList || !!matchMsRfqList
    const isHowItWorksPage = !!matchHowItWorks
    const isFaqPage = !!matchFaq
    const isLoadBoardPage = !!matchLoadBoard || !!matchOrders
    const isWarehousesPage = !!matchWarehouses
    const isAdminPage = !!matchAdmin
    const isGeneralRfqPage = !!matchGeneralRfq
    const isMicrositePage = !!hasMicrosite
    const isOnDemandRfqPage = !!matchOnDemandRfq

    const pages = {
        isRfqPage,
        isMicrositePage,
        isGeneralRfqPage,
        isOnDemandRfqPage,
        isOrdersPage,
        isHowItWorksPage,
        isFaqPage,
        isLoadBoardPage,
        isWarehousesPage,
        isAdminPage,
    }
    const currentPage = Object.entries(pages).find((el) => el[1] === true)?.[0]
    const userTypes = { isBroker, isWH }

    return {
        pages,
        userTypes,
        currentPage,
    }
}

export default useNavHelper
