import { flattenObject } from '@shared/utils/flattenObject'

const localSSOPort = process.env.REACT_APP_LOCAL_SSO_PORT

export const ssoUrl = (() => {
    const origin = window.location.origin
    const hostname = window.location.hostname

    // Check if the hostname is localhost or a local IP address
    const isLocalHostOrIP =
        hostname === 'localhost' ||
        /^192\.168\.\d{1,3}\.\d{1,3}$/.test(hostname) ||
        /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname) ||
        /^172\.1[6-9]\.\d{1,3}\.\d{1,3}$/.test(hostname) ||
        /^172\.2[0-9]\.\d{1,3}\.\d{1,3}$/.test(hostname) ||
        /^172\.3[0-1]\.\d{1,3}\.\d{1,3}$/.test(hostname)

    return isLocalHostOrIP
        ? localSSOPort
            ? `${window.location.protocol}//${hostname}:${localSSOPort}`
            : 'https://auth.dev.olimpcorp.com'
        : origin.replace('app.', 'auth.')
})()

const ROUTES = {
    index: '/',
    test: '/test',
    dashboard: '/dashboard',
    psm: {
        base: '/psm',
        chat: '/chat',
    },
    rfq: {
        old: '/rfq',
        base: '/ms/:slug/rfq',
        baseNoRFQ: '/ms/:slug',
        // slug: '/:slug',
        thankYou: '/thank-you',
        list: '/list',
        projects: '/list/projects',
    },
    more: {
        faq: '/faq',
        'how-it-works': '/how-it-works',
    },
    public: {
        search: '/search',
        booking: '/booking/:hash',
        warehousesList: '/search/warehouses/:filters',
        login: '/login',
        auth: '/auth/:token',
        verifyEmail: '/verify-email',
        resetPassword: '/reset-password',
        onboarding: '/onboarding',
        invites: '/invites/:key',
        logout: '/logout',
        register: '/register',
    },
    user: {
        base: '/user',
        settings: '/settings',
        tos: '/tos',
    },
    account: {
        base: '/account',
        settings: '/settings',
    },
    company: {
        base: '/company',
        index: '/:registerType',
        managers: '/managers',
    },
    carrier: {
        base: '/carrier',
        booking: '/warehouse/:warehouseId/:filters',
        bookingNoFilters: '/warehouse/:warehouseId',
        ordersList: '/orders/:filters',
        ordersListNoFilters: '/orders',
        order: '/order/:orderId',
        orderConfirmation: '/order/:orderId/confirmation/:confirmationId',
        quote: '/warehouse/:warehouseId/quote/:filters',
        quoteNoFilters: '/warehouse/:warehouseId/quote/:filters',
        warehouseSearch: '/warehouses/:filters',
        warehouseSearchNoFilters: '/warehouses',
        selectBeforeOrder: '/:carrierId/order/:orderId',
        selectBeforeBooking: '/:carrierId/booking/:bookingWh/:booking',
        settings: '/settings',
        search: '/search',
    },
    loadboard: {
        base: '/loadboard',
    },
    order: {
        base: '/order',
    },
    chatTest: {
        base: '/chattest',
    },
    warehouses: {
        base: '/warehouses',
        general: '/warehouses/general',
        services: '/warehouses/services',
        orders: '/warehouses/orders',
        staff: '/warehouses/staff',
    },
    admin: {
        base: '/admin',
        general: '/admin/general',
        warehouses: '/admin/warehouses',
        staff: '/admin/staff',
        invoices: '/warehouses/invoices',
    },
    warehouse: {
        base: '/warehouse',
        calendar: '/calendar',
        ordersList: '/orders/:filters',
        ordersListNoFilters: '/orders',
        order: '/order/:orderId',
        selectBeforeOrder: '/:warehouseId/order/:orderId',
        settings: {
            base: '/settings',
            warehouse: '/settings/warehouse',
            prices: '/settings/prices',
            payment: '/settings/payment',
            rating: '/settings/rating',
        },
    },
    service: {
        base: '/service',
        emailConfirm: '/email/:token',
        passwordChange: '/password/:token',
        logout: '/logout',
    },
    auth: {
        index: '/auth',
    },
    signUp: {
        index: '/sign-up',
        managerDetails: '/sign-up/intro',
        companyInfo: '/sign-up/company',
        verification: '/sign-up/verification',
        ready: '/sign-up/ready',
        carrier: {
            index: '/sign-up/carrier',
            ready: '/sign-up/carrier/ready',
        },
        warehouse: {
            index: '/sign-up/warehouse',
            address: '/sign-up/warehouse/address',
            hours: '/sign-up/warehouse/hours',
            delivery: '/sign-up/warehouse/delivery',
            USDOT: '/sign-up/warehouse/usdot',
            tax: '/sign-up/warehouse/tax',
            ready: '/sign-up/warehouse/ready',
        },
    },
    registerCarrier: '/register/carrier',
    registerWarehouse: '/register/warehouse',
    page: {
        base: '/page',
        index: '/page/:alias',
    },
} as const

export const DEFAULT_ROUTES = {
    user: ROUTES.user.settings,
    company: ROUTES.company.base,
} as const

export const DEPRECATED_ROUTES = {
    company: {
        settings: '/company/settings',
    },
} as const

const getRoutesWithBase = (base: string, paths: string[]): string[] =>
    paths.map((path) => (path === base ? path : `${base}${path}`))

export const WAREHOUSE_ROUTES: string[] = getRoutesWithBase(
    ROUTES.warehouse.base,
    Object.values(flattenObject(ROUTES.warehouse)),
)

export const CARRIER_ROUTES: string[] = getRoutesWithBase(
    ROUTES.carrier.base,
    Object.values(flattenObject(ROUTES.carrier)),
)

export const GUEST_ROUTES: string[] = [ROUTES.public.login]

export default ROUTES
