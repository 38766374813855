export enum ChatAction {
    GetChat = 'GetChat',
    GetChats = 'GetChats',
    SendMessage = 'SendMessage',
    SeenMessage = 'SeenMessage',
    GetMessageBoardChats = 'GetMessageBoardChats',
}

export enum ChatEvent {
    ChatReceived = 'ChatReceived',
    ChatsReceived = 'ChatsReceived',
    MessageReceived = 'MessageReceived',
    MessageSeen = 'MessageSeen',
    MessageBoardReceived = 'MessageBoardReceived',
    UserError = 'UserError',
}
