import React, { useState } from 'react'
import { DialogClose, DialogFooter, DialogHeader, DialogTitle } from '@/shared/ui/Dialog'
import { useFormik } from 'formik'
import { createFileList } from '@/utils/helper'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import { Input } from '@/shared/ui/Input'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { ModalExtraProps } from '@/shared/utils/modalExtraProps'
import { RfqInvoice } from '@/entities/rfq/model/types'
import { rfqApi } from '@/entities/rfq/api/rfq-api'
import { Separator } from '@/shared/ui/Separator'

type UploadInvoiceModalProps = {
    warehouseId: string
    orderKey: string
} & ModalExtraProps<RfqInvoice>

type UploadInvoiceForm = {
    file: File | null
    invoiceUrl: string
}

const UploadInvoiceFormSchema = Yup.object().shape(
    {
        file: Yup.mixed().when('invoiceUrl', {
            is: (invoiceUrl: string) => !invoiceUrl,
            then: Yup.mixed().required('Please upload file or enter invoice url.'),
        }),
        invoiceUrl: Yup.mixed().when('file', {
            is: (file: File | null) => !file,
            then: Yup.string().required('Please upload file or enter invoice url.'),
        }),
    },
    [['invoiceUrl', 'file']],
)

export const UploadInvoiceModal = ({
    warehouseId,
    orderKey,

    isLoading,
    setIsLoading,

    setOpen,

    onSuccess,
    onError,
    onFinally,
}: UploadInvoiceModalProps) => {
    const [submissionAttempted, setSubmissionAttempted] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const form = useFormik<UploadInvoiceForm>({
        initialValues: {
            file: null,
            invoiceUrl: '',
        },
        validateOnChange: submissionAttempted,
        validateOnMount: false,
        validationSchema: UploadInvoiceFormSchema,
        onSubmit: async (values) => {
            setErrorMessage('')
            setIsLoading?.(true)

            try {
                let fileList
                if (values.file) {
                    fileList = createFileList([values.file])
                }

                let result: AxiosResponse<RfqInvoice>
                if (fileList) {
                    result = await rfqApi.uploadOrderInvoice({ warehouseId, orderKey, file: fileList[0] })
                } else {
                    result = await rfqApi.uploadOrderInvoice({
                        warehouseId,
                        orderKey,
                        downloadInvoiceURL: values.invoiceUrl,
                    })
                }

                await onSuccess?.(result.data)

                toast('Invoice has been successfully uploaded.', { type: 'success' })

                setOpen?.(false)
            } catch (error: any) {
                console.error(error)
                await onError?.(error)
                if (error && error?.response) {
                    setErrorMessage(error?.response?.data?.message)
                }
                toast(error.response?.data?.message ?? 'We could not upload your invoice.', { type: 'error' })
            } finally {
                await onFinally?.()
                setIsLoading?.(false)
            }
        },
    })

    const isActuallyLoading = isLoading ?? form.isSubmitting

    return (
        <div>
            <form onSubmit={form.handleSubmit} className="space-y-3">
                <DialogHeader>
                    <DialogTitle>Upload Your Invoice</DialogTitle>
                </DialogHeader>
                <div className="relative">
                    <DropZoneArea
                        filesCollection={form.values.file ? [form.values.file] : []}
                        changeFormData={(path, files) => form.setFieldValue('file', files[0])}
                        allowedFormats={['image/jpeg', 'application/pdf']}
                        maxFiles={1}
                    />
                    {form.errors.file ? <div className="mt-1 text-sm text-danger">{form.errors.file}</div> : null}
                </div>
                <div className="flex gap-3 items-center">
                    <Separator className="shrink w-full" />
                    <div className="font-medium text-foreground-secondary/70 -mt-1">or</div>
                    <Separator className="shrink w-full" />
                </div>
                <div className="flex flex-col gap-3">
                    <div>
                        <Input
                            placeholder="Invoice URL"
                            value={form.values.invoiceUrl}
                            name="invoiceUrl"
                            onChange={form.handleChange}
                        />
                        {form.errors.invoiceUrl ? (
                            <div className="mt-1 text-sm text-danger">{form.errors.invoiceUrl}</div>
                        ) : null}
                    </div>
                </div>
                <DialogFooter className="flex-col gap-1 items-end">
                    <div className="w-full flex flex-row justify-end gap-3">
                        <DialogClose asChild>
                            <Button
                                variant="outlined"
                                disabled={isActuallyLoading}
                                className="basis-0 flex-grow sm:flex-grow-0 sm:basis-auto"
                            >
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            disabled={isActuallyLoading}
                            className="basis-0 flex-grow sm:flex-grow-0 sm:basis-auto"
                            onClick={() => !submissionAttempted && setSubmissionAttempted(true)}
                        >
                            Submit {isActuallyLoading ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                        </Button>
                    </div>
                    {errorMessage ? <div className="mt-1 text-sm text-danger">{errorMessage}</div> : null}
                </DialogFooter>
            </form>
        </div>
    )
}
