import React from 'react'
import { MultiSelectOption } from '@/components/MultiSelectDropdown'

interface FreightEnvironmentTypes {
    label: string
    value: string
}

// export const freightEnvironment: string[] = ['Dry', 'Freeze', 'Temp Controlled']
export const freightEnvironment: FreightEnvironmentTypes[] = [
    {
        label: 'Dry',
        value: 'Dry',
    },
    {
        label: 'Frozen',
        value: 'Freeze',
    },
    {
        label: 'Temp Controlled',
        value: 'Specific',
    },
]

export const certifications: string[] = ['Bonded', 'Food Grade', 'Hazmat']

interface UnitTypes {
    value: string
    label: string
    numberLabel: string
}

export const unitTypes: UnitTypes[] = [
    { label: 'Pallet - Standard', value: 'Pallet - Standard', numberLabel: '# of Pallets*' },
    { label: 'Pallet - Oversized', value: 'Pallet - Oversized', numberLabel: '# of Pallets*' },
    { label: 'Floor loaded Units', value: 'Floor loaded Unit', numberLabel: ' # of Units*' },
    { label: 'Crates', value: 'Crate', numberLabel: '# of Crates*' },
    { label: 'Drum/Barrel', value: 'Drum/Barrel', numberLabel: '# of Units*' },
    { label: 'Paper Roll (Clamp)', value: 'Paper Roll', numberLabel: '# of Paper Rolls*' },
    { label: 'Carpet Roll (Boom)', value: 'Carpet Roll', numberLabel: '# of Carpet Rolls*' },
    { label: 'Tires', value: 'Tires', numberLabel: '# of Tires*' },
    { label: 'Super Sack', value: 'Super Sack', numberLabel: '# of Super Sacks*' },
    { label: 'Gaylord', value: 'Gaylord', numberLabel: '# of Gaylords*' },
    { label: 'Bale', value: 'Freight Bale', numberLabel: '# of Bales*' },
    { label: 'Slip Sheets', value: 'Slip Sheet', numberLabel: '# of Slip Sheets*' },
    { label: 'Non-Standard Freight', value: 'Non-Standard Freight', numberLabel: '# of Units*' },
]

export const servicesNeededInitial: string[] = [
    'Cross-Docking',
    'Rework / Restack',
    'Storage',
    'Transloading',
    'Final Mile / Local Delivery',
    'Drayage',
    'Disposal',
    'Yard Storage / Trailer Parking',
]

export const hazmatClasses: MultiSelectOption[] = [
    {
        label: 'Class 1 (Explosives)',
        value: 'Hazmat 1',
        info: (
            <div>
                <p>Division 1.1 Explosives which have a mass explosion hazard</p>
                <p>Division 1.2 Explosives which have a projection hazard but not a mass explosion hazard</p>
                <p>
                    Division 1.3 Explosives which have a fire hazard and either a minor blast hazard or a minor
                    projection hazard or both, but not a mass explosion hazard
                </p>
                <p>Division 1.4 Explosives which present no significant blast hazard</p>
                <p>Division 1.5 Very insensitive explosives with a mass explosion hazard</p>
                <p>Division 1.6 Extremely insensitive articles which do not have a mass explosion hazard</p>
            </div>
        ),
    },
    {
        label: 'Class 2 (Gasses)',
        value: 'Hazmat 2',
        info: (
            <div>
                <p>Division 2.1 Flammable gases</p>
                <p>Division 2.2 Non-flammable, non-toxic* gases</p>
                <p>Division 2.3 Toxic* gases</p>
            </div>
        ),
    },
    {
        label: 'Class 3 (Flammable Liquids)',
        value: 'Hazmat 3',
        info: 'Flammable liquids (and Combustible liquids [U.S.])',
    },
    {
        label: 'Class 4 (Flammable Solids)',
        value: 'Hazmat 4',
        info: (
            <div>
                <p>Division 4.1 Flammable solids, self-reactive substances and solid desensitized explosives</p>
                <p>Division 4.2 Substances liable to spontaneous combustion</p>
                <p>Division 4.3 Substances which in contact with water emit flammable gases</p>
            </div>
        ),
    },
    {
        label: 'Class 5 (Oxidizing)',
        value: 'Hazmat 5',
        info: (
            <div>
                <p>Division 5.1 Oxidizing substances</p>
                <p>Division 5.2 Organic peroxides</p>
            </div>
        ),
    },
    {
        label: 'Class 6 (Poisonous)',
        value: 'Hazmat 6',
        info: (
            <div>
                <p>Division 6.1 Toxic* substances</p>
                <p>Division 6.2 Infectious substances</p>
            </div>
        ),
    },
    {
        label: 'Class 7 (Radioactive)',
        value: 'Hazmat 7',
        info: 'Radioactive materials',
    },
    {
        label: 'Class 8 (Corrosive)',
        value: 'Hazmat 8',
        info: 'Corrosive substances',
    },
    {
        label: 'Class 9 (Misc.)',
        value: 'Hazmat 9',
        info: 'Miscellaneous dangerous goods/hazardous materials and articles',
    },
]
