import * as React from 'react'
import { CaptionLabelProps } from 'react-day-picker'
import { enUS } from 'date-fns/locale'
import { Select } from '@/shared/ui/Select'
import { CalendarProps } from '@/shared/ui/Calendar'
import { generateMonths } from '@/shared/ui/Calendar/lib/generateMonths'
import { generateYears } from '@/shared/ui/Calendar/lib/generateYears'

type CalendarMonthYearPickerProps = CaptionLabelProps & Pick<CalendarProps, 'onMonthChange' | 'yearRange'>

export const CalendarMonthYearPicker = ({
    displayMonth,
    onMonthChange,
    yearRange = 30,
}: CalendarMonthYearPickerProps) => {
    const MONTHS = React.useMemo(() => generateMonths(enUS), [])
    const YEARS = React.useMemo(() => generateYears(yearRange), [yearRange])

    return (
        <div className="inline-flex">
            <Select
                defaultValue={displayMonth.getMonth().toString()}
                onValueChange={(value) => {
                    const newDate = new Date(displayMonth)
                    newDate.setMonth(Number.parseInt(value, 10))
                    onMonthChange?.(newDate)
                }}
                options={MONTHS}
                triggerClassName="border-0 mr-5 h-auto"
                iconWrapperClassName="pointer-events-none"
            />
            <Select
                defaultValue={displayMonth.getFullYear().toString()}
                onValueChange={(value) => {
                    const newDate = new Date(displayMonth)
                    newDate.setFullYear(Number.parseInt(value, 10))
                    onMonthChange?.(newDate)
                }}
                options={YEARS}
                triggerClassName="border-0 mr-5 h-auto"
                iconWrapperClassName="pointer-events-none"
            />
        </div>
    )
}
