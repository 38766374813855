import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { Select, SelectOption } from '@/shared/ui/Select'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

const RANGE_OPTIONS: SelectOption[] = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
]

export const YardStorageFormComponent = ({ service, errors }: ServiceFormComponentProps) => {
    const onPriceChange = (value: string) => {
        if (service.pricings?.storage) {
            service.pricings.storage.price = value
        }
    }

    const onModifierChange = (value: string) => {
        if (service.pricings?.storage) {
            service.pricings.storage.modifier = value
        }
    }

    return (
        <div>
            <div className="flex gap-2 items-center w-full">
                <CurrencyInput
                    label={RfqServicePricingLabel[RfqServicePricing.Storage]}
                    defaultValue={service.pricings.storage?.price}
                    onValueChange={({ value }) => onPriceChange(value ?? '')}
                    allowNegative={false}
                    decimalScale={2}
                    prefix="$"
                    wrapperClassName="w-full md:w-[200px]"
                />
                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">Spot</span>
                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                <Select
                    defaultValue={service.pricings.storage?.modifier ?? ''}
                    options={RANGE_OPTIONS}
                    onValueChange={onModifierChange}
                    triggerClassName="w-[100px] sm:w-[170px] shrink-0"
                />
            </div>
            {errors?.[RfqServicePricing.Storage] ? (
                <div className="text-sm text-danger">{errors[RfqServicePricing.Storage]}</div>
            ) : null}
        </div>
    )
}
