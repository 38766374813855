import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Accordion } from '@/shared/ui/Accordion'
import { Badge } from '@/shared/ui/Badge'
import { OrderChat } from '@/pages/Order/ui/OrderChat'
import dayjs from 'dayjs'
import { useChat } from '@/entities/chat/api/useChat'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { OrdersChatsSidebar } from '@/pages/PSM/MessageBoard/OrdersChatsSidebar'
import { DrawerContent, DrawerHandle, DrawerRoot, DrawerTrigger } from '@/shared/ui/Drawer'
import { Button } from '@/shared/ui/Button'
import { IoMenu } from '@react-icons/all-files/io5/IoMenu'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { MessageBoardFilterPanel } from '@/pages/PSM/MessageBoard/MessageBoardFilters'
import { useUserState } from '@/store/user'
import { IoOpenOutline } from '@react-icons/all-files/io5/IoOpenOutline'
import { MessageBoardFilters } from '@/entities/chat/model/dto'
import { Input } from '@/shared/ui/Input'
import { debounce } from 'lodash'
import { Loader } from '@/shared/ui/Loader'
import { cn } from '@/shared/utils/cn'

export const MessageBoard: FC = () => {
    const debounceFuncRef = useRef<ReturnType<typeof debounce>>()
    const { state: userState } = useUserState()
    const appDispatch = useAppDispatch()
    const {
        setChatMessageBoard,
        setChatMessageBoardFilters,
        setMessageBoardLoading,
        setChatMessageBoardSearch,
        selectChat,
    } = orderChatActions
    const filters = useAppSelector((state) => state.orderChat.messageBoardFilters)
    const orderChats = useAppSelector((state) => state.orderChat.messageBoard)
    const isLoading = useAppSelector((state) => state.orderChat.messageBoardLoading)
    const activeReference = useAppSelector((state) => state.orderChat.messageBoardActiveReference)
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const messageBoard = useChat({
        callbacks: {
            onMessageReceived: (message) => {
                fetchMessageBoard()
            },
            onChatReceived: ({ chat }) => {
                const exists = orderChats.find((orderChat) => orderChat.chats.find((c) => c.id === chat.id))
                if (!exists) {
                    if (chat.messaging_mode === selectedChat && activeReference === chat.reference) {
                        appDispatch(selectChat(chat.id))
                    }
                    fetchMessageBoard()
                }
            },
            onMessageSeen: (data) => {},
            onMessageBoardReceived: (response) => {
                appDispatch(setChatMessageBoard(response.messageBoard))
                appDispatch(setMessageBoardLoading(false))
            },
            onChatsReceived: (chats) => {},
        },
    })

    const fetchMessageBoard = useCallback(
        (filtersFromComponent?: MessageBoardFilters) => {
            if (messageBoard.connected) {
                appDispatch(setMessageBoardLoading(true))
                messageBoard.getMessageBoard({
                    skip: 0,
                    take: 100,
                    filters: {
                        ...filters,
                        ...(filtersFromComponent ?? {}),
                    },
                })
            }
        },
        [messageBoard, appDispatch, setMessageBoardLoading, filters],
    )

    useEffect(() => {
        fetchMessageBoard()
    }, [messageBoard.connected])

    const debouncedFetchMessageBoard = (f: any) => {
        if (debounceFuncRef.current) {
            debounceFuncRef.current.cancel()
        }
        debounceFuncRef.current = debounce(fetchMessageBoard, 500)
        debounceFuncRef.current(f)
    }

    const sidebar = (
        <div className="flex flex-col p-4">
            {/*<a href={'/'}>*/}
            {/*    <IoChatbox /> All Messages*/}
            {/*</a>*/}
            {/*<a href={'/'}>*/}
            {/*    <IoStar /> Following*/}
            {/*</a>*/}
            <div className="flex justify-between items-center mt-4">
                <h2 className="text-black-3 font-semibold">Orders</h2>
                <MessageBoardFilterPanel
                    filters={filters}
                    onApply={(filtersFromComponent) => {
                        appDispatch(setChatMessageBoardFilters(filtersFromComponent))
                        debouncedFetchMessageBoard(filtersFromComponent)
                    }}
                />
            </div>
            <div className="mt-2 mb-1">
                <Input
                    label={'Search by Order / Customer'}
                    placeholder="Start typing to search..."
                    value={filters.search}
                    onChange={(event) => {
                        appDispatch(setChatMessageBoardSearch(event.target.value))
                        debouncedFetchMessageBoard({ search: event.target.value })
                    }}
                />
            </div>

            <div className="border-separate border-b border-gray-100 my-2"></div>
            {orderChats.length > 0 && (
                <ScrollArea
                    viewportClassName={'pr-2 h-[470px]'}
                    className={cn({
                        hidden: isLoading,
                    })}
                >
                    <Accordion
                        collapsible
                        triggerClassName="data-[state=open]:text-primary hover:no-underline h-12 w-full items-start pt-2"
                        itemClassName="border-gray-200 last:border-b-0"
                        type="single"
                        triggerPropsBuilder={(option) => ({
                            onClick: (e) => {
                                if (activeReference) {
                                    appDispatch(orderChatActions.selectChat(undefined))
                                    appDispatch(orderChatActions.setActiveMessageBoardReference(undefined))
                                    appDispatch(orderChatActions.setCurrentMessage(''))
                                }
                            },
                        })}
                        items={orderChats.map((orderChat) => ({
                            value: orderChat.rfq_key,
                            trigger: (
                                <div className="flex flex-col justify-start px-2 no-underline">
                                    <div className="flex">
                                        # {orderChat.rfq_key}{' '}
                                        {orderChat.unseen > 0 && (
                                            <Badge className="ml-2 bg-primary px-2 circle  h-5 text-[11px] inline-flex  self-end">
                                                {orderChat.unseen} unread
                                            </Badge>
                                        )}
                                    </div>
                                    <div className="text-gray-400 text-xs font-light flex justify-start items-center">
                                        {/* put dot in middle vertical */}
                                        {orderChat.message_created_at
                                            ? dayjs(orderChat.message_created_at).fromNow()
                                            : 'No messages'}
                                    </div>
                                </div>
                            ),
                            content: (
                                <OrdersChatsSidebar
                                    reference={orderChat.rfq_key}
                                    chats={orderChat.chats}
                                    demand={{
                                        firstName: orderChat.demand_first_name,
                                        lastName: orderChat.demand_last_name,
                                        email: orderChat.demand_email,
                                    }}
                                />
                            ),
                        }))}
                    />
                </ScrollArea>
            )}
            {isLoading && <Loader className="w-5 h-5 border-2 mt-1 mb-1" />}
            {!isLoading && orderChats.length === 0 && (
                <div className="text-gray-400 text-center mt-1 mb-1">No orders found</div>
            )}
        </div>
    )
    return (
        <div>
            <div className="container m-4 mx-auto">
                <h1 className="mb-3">
                    Welcome, {userState.userInfo?.firstName} {userState.userInfo?.lastName}{' '}
                </h1>

                <div className="flex flex-col rounded shadow bg-white">
                    <div className="flex flex-row">
                        <aside className="w-1/4 border-r border-r-gray-2 min-w-[350px] hidden sm:block">
                            {sidebar}
                        </aside>
                        <div className="w-full sm:w-3/4">
                            <DrawerRoot direction="left">
                                <DrawerTrigger asChild>
                                    <Button className="w-[120px] sm:hidden ml-2 mt-2">
                                        <IoMenu />
                                    </Button>
                                </DrawerTrigger>
                                <DrawerContent className="w-full">
                                    <DrawerHandle />
                                    {sidebar}
                                </DrawerContent>
                            </DrawerRoot>
                            <OrderChat
                                addons={
                                    <div className="flex items-center">
                                        <Button
                                            className="ml-2"
                                            variant="plain"
                                            disabled={!window.parent}
                                            onClick={() => {
                                                const msg = {
                                                    action: 'navigateToOrder',
                                                    id: orderChats.find((o) => o.rfq_key === activeReference)
                                                        ?.sf_opportunity_id,
                                                }
                                                if (window.parent) {
                                                    window.parent.postMessage(msg, '*')
                                                }
                                            }}
                                        >
                                            <IoOpenOutline className="mr-2 text-primary" /> Open order
                                        </Button>
                                    </div>
                                }
                                messageBoard
                                reference={activeReference}
                                className="w-full !shadow-none 2xl:w-full h-full border-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
