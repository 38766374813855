import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { cn } from '@/shared/utils/cn'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            'z-50 rounded-md border bg-background-secondary px-3 py-1.5 text-sm shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
        )}
        {...props}
    />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const TooltipArrow = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Arrow>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({ className, ...props }, ref) => (
    <TooltipPrimitive.Arrow
        className={cn(
            'h-4 w-4 rotate-45 -translate-y-[calc(50%)] bg-white fill-white border border-border border-t-transparent border-l-transparent rounded-br',
            className,
        )}
        asChild
        {...props}
    >
        <div />
    </TooltipPrimitive.Arrow>
))
TooltipArrow.displayName = TooltipPrimitive.Arrow.displayName

export type TooltipProps = React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider> &
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>

const Tooltip = ({ delayDuration = 300, skipDelayDuration = 300, disableHoverableContent, ...props }: TooltipProps) => (
    <TooltipProvider
        delayDuration={delayDuration}
        skipDelayDuration={skipDelayDuration}
        disableHoverableContent={disableHoverableContent}
    >
        <TooltipRoot {...props} />
    </TooltipProvider>
)

export { TooltipRoot, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow, Tooltip }
