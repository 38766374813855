import React, { useMemo } from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { cn } from '@/shared/utils/cn'
import { useMediaQuery } from '@/shared/hooks/useMediaQuery'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/Tooltip'
import { PopoverContent, PopoverRoot, PopoverTrigger } from '@/shared/ui/Popover'
import { RfqStatus } from '@/entities/rfq/model'

type OrderDemandStatusProps = {
    order: LoadboardOrder
}

const STAGES = ['Booked', 'Dropped', 'Payment', 'Done']

export const OrderProgress = ({ order }: OrderDemandStatusProps) => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)')

    const activeStage = useMemo(() => {
        if (order.rfqStatus === RfqStatus.Cancelled) return -1
        if (order.paid) return 4
        if (order.rfqStatus === RfqStatus.Finished) return 3
        if (order.rfqStatus === RfqStatus.Dropped) return 2
        if (order.rfqStatus === RfqStatus.Booked) return 1
        return 0
    }, [order])

    if (!order.rfqStatus || order.rfqStatus < RfqStatus.Booked) return null

    return (
        <div className="flex gap-3 items-center px-3 sm:px-0">
            {STAGES.map((stage, index) => (
                <div
                    key={index}
                    className={cn(
                        'flex gap-3 items-center flex-grow shrink-0 basis-0',
                        index === 0 && 'flex-grow-0 md:flex-grow',
                        activeStage === -1 && 'opacity-70',
                    )}
                >
                    <hr
                        className={cn(
                            'h-0 border-2 rounded-xl border-accent-medium flex-grow',
                            activeStage >= index + 1 && 'border-primary',
                            index === 0 && 'hidden md:block',
                            activeStage === -1 && index === STAGES.length - 1 && 'border-danger',
                        )}
                    />
                    <span
                        className={cn(
                            'text-xl md:text-base lg:text-[20px] font-semibold text-foreground-secondary shrink-0',
                            activeStage >= index + 1 && 'text-foreground-primary',
                            activeStage === -1 && index === STAGES.length - 1 && 'text-danger',
                        )}
                    >
                        {isSmallScreen ? (
                            <Tooltip>
                                <PopoverRoot>
                                    <PopoverTrigger asChild>
                                        <TooltipTrigger asChild>
                                            <div
                                                className={cn(
                                                    'h-8 w-8 flex items-center justify-center border-2 border-accent-medium rounded-full',
                                                    activeStage >= index + 1 && 'border-primary bg-primary text-white',
                                                    activeStage === -1 && index === STAGES.length - 1 && 'border-danger',
                                                )}
                                            >
                                                {index === STAGES.length - 1 && activeStage === -1
                                                    ? 'C'
                                                    : stage.charAt(0)}
                                            </div>
                                        </TooltipTrigger>
                                    </PopoverTrigger>
                                    <TooltipContent>
                                        {index === STAGES.length - 1 && activeStage === -1 ? 'Canceled' : stage}
                                    </TooltipContent>
                                    <PopoverContent>
                                        {index === STAGES.length - 1 && activeStage === -1 ? 'Canceled' : stage}
                                    </PopoverContent>
                                </PopoverRoot>
                            </Tooltip>
                        ) : index === STAGES.length - 1 && activeStage === -1 ? (
                            'Canceled'
                        ) : (
                            stage
                        )}
                    </span>
                </div>
            ))}
        </div>
    )
}
