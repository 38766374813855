import React, { useEffect, useState } from 'react'

import './ClickableList.scss'

type ListItem = {
    id: string
    value: string
    label?: string
    icon: React.ReactNode
}

type ClickableListProps = {
    items: ListItem[]
    onItemSelect: (value: string) => void
    multiSelect?: boolean
    resetTrigger?: boolean
}

const ClickableList: React.FC<ClickableListProps> = ({ items, onItemSelect, multiSelect = false, resetTrigger }) => {
    const [selectedIds, setSelectedIds] = useState<string[]>([])

    useEffect(() => {
        setSelectedIds([''])
        onItemSelect('')
    }, [resetTrigger])

    const handleItemClick = (item: ListItem) => {
        let updatedSelectedIds: string[]

        if (multiSelect) {
            if (selectedIds.includes(item.id)) {
                updatedSelectedIds = selectedIds.filter((id) => id !== item.id)
            } else {
                updatedSelectedIds = [...selectedIds, item.id]
            }
        } else {
            updatedSelectedIds = [item.id]
        }

        setSelectedIds(updatedSelectedIds)
        const selectedValues = items.filter((it) => updatedSelectedIds.includes(it.id)).map((it) => it.id)
        onItemSelect(selectedValues.join(',')) // here we return arrays to string
    }

    return (
        <ul>
            {items.map((item) => (
                <li
                    key={item.id}
                    onClick={() => handleItemClick(item)}
                    style={{
                        cursor: 'pointer',
                        // backgroundColor: selectedIds.includes(item.id) ? 'lightgray' : 'transparent',
                    }}
                    className={selectedIds.includes(item.id) ? 'clickableList clickableList__active' : 'clickableList'}
                >
                    <span className={item?.icon ? 'clickableList__icon' : ''}>{item.icon}</span>
                    <span>{item.label || item.value}</span>
                </li>
            ))}
        </ul>
    )
}

export default ClickableList
