import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InAttach = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.3694207,7.97591398 C7.61253561,7.72979127 7.61253561,7.32984187 7.3694207,7.08371917 C7.12630579,6.83759646 6.73124406,6.83759646 6.48812915,7.08371917 L3.19088319,10.4217584 C2.62867996,10.9909171 2.32478632,11.7292853 2.32478632,12.5291841 C2.32478632,13.3290829 2.62867996,14.067451 3.19088319,14.6366097 C4.34567901,15.8056926 6.21462488,15.8056926 7.35422602,14.6366097 L14.7996201,7.09910183 C15.5745489,6.31458571 16,5.2685642 16,4.16101202 C16,3.05345984 15.5745489,2.00743833 14.7996201,1.2229222 C13.1889839,-0.407640734 10.5906933,-0.407640734 8.98005698,1.2229222 L1.54985755,8.74504744 C0.547008547,9.76030361 0,11.0985958 0,12.5291841 C0,13.9597723 0.547008547,15.3134472 1.54985755,16.3133207 C2.55270655,17.3285769 3.87464387,17.8823529 5.28774929,17.8823529 C6.7008547,17.8823529 8.0379867,17.3285769 9.02564103,16.3133207 L12.322887,12.9752815 C12.5660019,12.7291588 12.5660019,12.3292094 12.322887,12.0830867 C12.0797721,11.8369639 11.6847104,11.8369639 11.4415954,12.0830867 L8.14434948,15.4211259 C7.38461538,16.1902593 6.3665717,16.6209741 5.28774929,16.6209741 C4.20892688,16.6209741 3.19088319,16.1902593 2.4311491,15.4211259 C1.671415,14.6519924 1.24596391,13.6213536 1.24596391,12.5291841 C1.24596391,11.4370145 1.671415,10.4063757 2.4311491,9.63724225 L9.86134853,2.11511701 C10.4083571,1.56134092 11.1225071,1.26907021 11.8822412,1.26907021 C12.6419753,1.26907021 13.37132,1.57672359 13.9031339,2.11511701 C14.4501425,2.66889311 14.7388414,3.39187856 14.7388414,4.16101202 C14.7388414,4.93014548 14.4349478,5.6685136 13.9031339,6.20690702 L6.45773979,13.7444149 C5.80436847,14.4058697 4.72554606,14.4058697 4.05698006,13.7444149 C3.73789174,13.4213789 3.55555556,12.9906641 3.55555556,12.5291841 C3.55555556,12.067704 3.73789174,11.6369892 4.05698006,11.3139532 L7.3694207,7.97591398 Z" />
            </svg>
        </IconBase>
    )
}
