import { cn } from '@/shared/utils/cn'
import React, { FC, useCallback } from 'react'
import { ChatMessageAttachmentDto } from '@/entities/chat/model/dto'
import { InFile } from '@/shared/ui/Icons/InFile'
import { InDownload } from '@/shared/ui/Icons/InDownload'
import { ImageFile } from '@/pages/Order/ui/OrderDetails/ui/OrderDetailsFiles'
import { Button } from '@/shared/ui/Button'
import { getAttachmentSignedURL } from '@/entities/chat/api/attachments'
import { toast } from 'react-toastify'
import { Loader } from '@/shared/ui/Loader'

interface MessageFilePreviewProps extends ChatMessageAttachmentDto {
    index: number
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export const ChatMessageImgAttachment: FC<MessageFilePreviewProps> = ({ thumb, name, id }) => {
    return (
        <ImageFile
            attachment={{ filename: name, link: '' }}
            getSignedURL={async () => {
                return getAttachmentSignedURL(id)
            }}
            trigger={
                <Button
                    variant="plain"
                    className="border border-border rounded-lg overflow-hidden flex flex-col items-start"
                >
                    <img
                        src={`data:image/jpeg;base64,${arrayBufferToBase64(thumb!)}`}
                        alt="File Preview"
                        className={cn('w-[200px] h-[100px] margin-b-2 rounded object-cover')}
                    />
                </Button>
            }
        />
    )
}

export const ChatMessageFileAttachment: FC<MessageFilePreviewProps> = ({ name, id }) => {
    const [loading, setLoading] = React.useState(false)
    const onDocumentClick = useCallback(async () => {
        if (loading) {
            return
        }
        try {
            setLoading(true)
            window.open(await getAttachmentSignedURL(id), '_blank')
        } catch (e) {
            console.error(e)

            toast('Failed to download document', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }, [id, loading])
    return (
        //     simple rounded rectangle with background and filename clamp overflow ellipsis
        <div
            className={cn(
                'bg-white border border-gray-3  p-2 rounded-[8px] flex flex-row justify-center items-center',
                {
                    'cursor-pointer': !loading,
                    'cursor-wait': loading,
                },
            )}
            title={name}
            onClick={onDocumentClick}
        >
            <InFile className="w-4 h-4 mr-2" />
            <div className=" text-black w-[180px] overflow-hidden text-ellipsis whitespace-nowrap underline">
                {name}
            </div>{' '}
            {loading ? (
                <Loader className="w-4 h-4 border-2 border-white border-t-primary ml-2" wrapperClassName="w-6 h-5" />
            ) : (
                <InDownload className="w-4 h-4 ml-2" />
            )}
        </div>
    )
}
