export const htmlifyMessage = (message: string) => {
    // Replace \n with <br>
    const messageWithLineBreaks = message.replace(/\n/g, '<br>')

    // Regex to find URLs in the text
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi

    // Replace URLs with anchor tags
    return messageWithLineBreaks.replace(urlPattern, function (url) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    })
}
