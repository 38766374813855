import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { cn } from '@/shared/utils/cn'
import { buttonVariants } from '@/shared/ui/Button'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { useState } from 'react'
import { CalendarMonthYearPicker } from '@/shared/ui/Calendar/plugins'

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
    yearRange?: number
}

function Calendar({
    className,
    classNames,

    showOutsideDays = true,
    components,

    month,
    onMonthChange,
    yearRange,

    ...props
}: CalendarProps) {
    const [innerMonth, setInnerMonth] = useState(month)
    const onInnerMonthChange = (changedMonth: Date) => {
        if (month === undefined) {
            setInnerMonth(changedMonth)
        }
        onMonthChange?.(changedMonth)
    }
    const actualMonth = month !== undefined ? month : innerMonth

    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center',
                nav_button: cn(
                    buttonVariants({ variant: 'ghost' }),
                    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 focus-visible:opacity-100 border border-solid border-border transition-all',
                ),
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head: '!table-header-group',
                head_row: 'flex',
                head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
                row: 'flex w-full mt-2',
                cell: 'group h-9 w-9 text-center text-sm p-0 relative transition-colors duration-75 [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-range-start)]:rounded-l-md [&:has([aria-selected].day-outside)]:bg-accent-light/60 [&:has([aria-selected].day-outside)]:hover:bg-accent-light [&:has([aria-selected])]:bg-accent-light/60 [&:has([aria-selected])]:hover:bg-accent-light first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
                day: cn(
                    buttonVariants({ variant: 'ghost' }),
                    'h-9 w-9 p-0 !text-sm !font-normal rounded-md aria-selected:opacity-100',
                ),
                day_selected: 'bg-primary text-white hover:bg-primary/90',
                day_range_start:
                    'day-range-start bg-primary text-white rounded-r-none group-has-[.day-range-end.day-range-start]:rounded-r-md',
                day_range_end:
                    'day-range-end bg-primary text-white rounded-l-none group-has-[.day-range-end.day-range-start]:rounded-l-md',
                day_range_middle: 'aria-selected:bg-transparent !text-foreground-primary',
                day_today: 'bg-accent-dark/30',
                day_outside:
                    'day-outside opacity-50 aria-selected:bg-primary aria-selected:text-white aria-selected:opacity-50',
                day_disabled: 'opacity-50',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                IconLeft: () => <InChevronDown size={12} className="rotate-90" />,
                IconRight: () => <InChevronDown size={12} className="-rotate-90" />,
                CaptionLabel: (captionLabelProps) => (
                    <CalendarMonthYearPicker onMonthChange={onInnerMonthChange} {...captionLabelProps} />
                ),
                ...components,
            }}
            month={actualMonth}
            onMonthChange={onInnerMonthChange}
            {...props}
        />
    )
}

Calendar.displayName = 'Calendar'

export { Calendar }
