import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InFile = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 12 17" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 0.5H7.29289C7.55811 0.5 7.81246 0.605357 8 0.792893L11.7071 4.5C11.8946 4.68754 12 4.94189 12 5.20711V14.5C12 15.6046 11.1046 16.5 10 16.5H2C0.89543 16.5 0 15.6046 0 14.5V2.5C0 1.39543 0.89543 0.5 2 0.5ZM7.5 2V4C7.5 4.55228 7.94772 5 8.5 5H10.5L7.5 2Z" />
            </svg>
        </IconBase>
    )
}
