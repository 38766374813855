import React from 'react'
import {
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/shared/ui/AlertDialog'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { cancelQuote } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/model'
import { toast } from 'react-toastify'
import { ModalExtraProps } from '@/shared/utils/modalExtraProps'
import { useAppDispatch } from '@/app/store'

export type OrderQuoteSubmitDialogProps = ModalExtraProps

export const QuoteCancelModal = ({ isLoading, setIsLoading }: OrderQuoteSubmitDialogProps) => {
    const dispatch = useAppDispatch()

    const onConfirm = async () => {
        try {
            setIsLoading?.(true)
            await dispatch(cancelQuote()).unwrap()
            toast('Your quote has been cancelled', { type: 'success' })
        } catch (error: any) {
            console.error(error)
            let errorMessage = ''
            if (error?.message) {
                errorMessage = error?.message
            } else {
                errorMessage = 'We could not cancel your quote. We are sorry!'
            }

            toast(errorMessage, {
                type: 'error',
            })
        } finally {
            setIsLoading?.(false)
        }
    }

    return (
        <AlertDialogRoot>
            <AlertDialogTrigger asChild>
                <Button
                    type="button"
                    variant="outlined"
                    className="border-danger hover:bg-danger/10 text-danger w-full"
                    disabled={isLoading}
                >
                    Cancel
                    <span className="hidden sm:inline">&nbsp;Quote</span>
                    {isLoading && <ImSpinner8 className="shrink-0 ml-2 animate-spin" />}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Cancel Quote</AlertDialogTitle>
                    </AlertDialogHeader>
                    <p>By clicking {'"Cancel Quote"'} your Quote will be canceled.</p>
                    <p>
                        After canceling, you will have the option to resubmit a new bid if needed. Please confirm your decision before proceeding.
                    </p>
                    <AlertDialogFooter>
                        <AlertDialogCancel type="button" disabled={isLoading}>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction onClick={onConfirm} disabled={isLoading} variant="danger">
                            Cancel Quote
                            {isLoading ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialogRoot>
    )
}
