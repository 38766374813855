import React, { useEffect, useState } from 'react'
import { PopoverArrow, PopoverContent, PopoverRoot, PopoverTrigger, PopoverTriggerWrapper } from '@/shared/ui/Popover'
import { Button } from '@/shared/ui/Button'
import { InFilter } from '@/shared/ui/Icons/InFilter'
import { Checkbox } from '@/shared/ui/Checkbox'
import { DatePicker } from '@/shared/ui/DatePicker'
import { MessageBoardFilters } from '@/entities/chat/model/dto'
import { Label } from '@/shared/ui/Label'
import { CheckedState } from '@radix-ui/react-checkbox'
import { cn } from '@/shared/utils/cn'

export interface MessageBoardFiltersProps {
    filters?: MessageBoardFilters
    onApply: (filters: MessageBoardFiltersProps['filters']) => void
    onClose?: () => void
}

const initial: MessageBoardFilters = { hasChats: true, search: '' }

export const MessageBoardFilterPanel: React.FC<MessageBoardFiltersProps> = ({
    filters = {
        hasChats: true,
        myChats: false,
        serviceDateOut: undefined,
        search: '',
    },
    onApply,
    onClose,
}) => {
    const [open, setOpen] = useState(false)
    const [calendarOpen, setCalendarOpen] = useState(false)

    // Local state to manage filter values within the component
    const [localFilters, setLocalFilters] = useState<MessageBoardFilters>(filters)

    const anyFiltersSelected =
        localFilters.hasChats !== initial.hasChats ||
        localFilters.myChats !== initial.myChats ||
        localFilters.serviceDateOut !== initial.serviceDateOut

    const toggleMyOrders = (checked: CheckedState) => {
        setLocalFilters({
            ...localFilters,
            myChats: checked === true,
        })
    }

    const toggleHasChats = (checked: CheckedState) => {
        setLocalFilters({
            ...localFilters,
            hasChats: checked === true,
        })
    }

    const handleDateSelect = (date?: Date | null) => {
        setLocalFilters({
            ...localFilters,
            serviceDateOut: date?.toISOString?.() ?? undefined,
        })
        setCalendarOpen(false)
    }

    const reset = () => {
        setLocalFilters(initial)
        setOpen(false)
        onApply(initial)
        onClose?.()
    }

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
        if (!newOpen) {
            onClose?.()
            setLocalFilters(filters)
        }
    }

    const applyFilters = () => {
        onApply(localFilters)
        setOpen(false)
        onClose?.()
    }

    return (
        <div>
            <PopoverRoot onOpenChange={handleOpenChange} open={open}>
                <PopoverTriggerWrapper>
                    <PopoverTrigger asChild>
                        <Button variant="ghost" className="w-10">
                            <InFilter
                                className={cn({
                                    'text-primary': anyFiltersSelected,
                                    'text-gray-500': !anyFiltersSelected,
                                })}
                            />
                        </Button>
                    </PopoverTrigger>
                </PopoverTriggerWrapper>
                <PopoverContent className="max-w-[30vw] min-w-[20vw] p-0 border-gray-2">
                    <PopoverArrow />
                    <div className={'pl-4 pr-4 pt-4 pb-3'}>
                        <div>
                            <div className="flex items-center gap-2">
                                <Checkbox
                                    id="haschatscheckbox"
                                    checked={localFilters.hasChats}
                                    onCheckedChange={toggleHasChats}
                                />
                                <Label htmlFor="haschatscheckbox" className="cursor-pointer">
                                    Has at least 1 active chat
                                </Label>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-2 mt-2">
                                <Checkbox
                                    id="mychatscheckbox"
                                    checked={localFilters.myChats}
                                    onCheckedChange={toggleMyOrders}
                                />
                                <Label htmlFor="mychatscheckbox" className="cursor-pointer">
                                    Only my orders
                                </Label>
                            </div>
                        </div>
                        <div className="mt-4">
                            <DatePicker
                                mode={'single'}
                                open={calendarOpen}
                                onOpenChange={setCalendarOpen}
                                label={'Service Date Out'}
                                selected={
                                    localFilters.serviceDateOut ? new Date(localFilters.serviceDateOut) : undefined
                                }
                                onSelect={handleDateSelect}
                            />
                        </div>

                        <div className="flex justify-end gap-2 mt-4">
                            <Button variant="ghost" onClick={reset}>
                                Reset
                            </Button>
                            <Button onClick={applyFilters}>Apply</Button>
                        </div>
                    </div>
                </PopoverContent>
            </PopoverRoot>
        </div>
    )
}
