import React, { useState } from 'react'
import { Quote, QuoteStatus, QuoteType } from '@/entities/quotes/model'
import { QuoteAllIn } from './QuoteAllIn'
import { QuoteStandard } from './QuoteStandard'
import { OrderQuoteBreakdown } from '../../OrderQuoteBreakdown'
import { Button } from '@/shared/ui/Button'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { setQuoteMode } from '../model'
import { OrderInvoice } from './OrderInvoice'
import { QuoteNotification } from './QuoteNotification'
import { QuoteCancelModal } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/ui/QuoteCancelModal'
import { Separator } from '@/shared/ui/Separator'

export const OrderQuoteSubmitted = () => {
    const dispatch = useAppDispatch()

    const activeQuote = useAppSelector((state) => state.orderQuoteSupply.activeQuote) as Quote
    const isStandardQuote = activeQuote.type === QuoteType.STANDARD

    const quoteMode = useAppSelector((state) => state.orderQuoteSupply.quoteMode)
    const isEditMode = quoteMode === 'edit' || quoteMode === 'revise'

    const isCancellationAllowed =
        activeQuote.status !== QuoteStatus.ACCEPTED && activeQuote.status !== QuoteStatus.DECLINED
    const isUpdateAllowed = activeQuote.status === QuoteStatus.CREATED
    const isRevisionAllowed = activeQuote.status === QuoteStatus.QUOTED || activeQuote.status === QuoteStatus.REQUOTE

    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className="space-y-5">
            <OrderInvoice />
            <QuoteNotification />

            {!isEditMode ? (
                <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3 space-y-3">
                    <div className="flex items-start sm:items-center justify-between">
                        <div className="flex flex-col">
                            <div className="text-2xl font-semibold">
                                Submitted Quote
                            </div>
                            {activeQuote.submittedBy ? (
                                <div className="text-sm text-foreground-secondary">
                                    By <span className="font-semibold">{activeQuote.submittedBy}</span>
                                </div>
                            ) : null}
                        </div>
                        <div className="flex gap-3 flex-col-reverse w-[120px] sm:w-auto sm:flex-row">
                            {isCancellationAllowed && (
                                <QuoteCancelModal isLoading={isLoading} setIsLoading={setIsLoading} />
                            )}
                            {isUpdateAllowed && (
                                <Button
                                    variant="outlined"
                                    onClick={() => dispatch(setQuoteMode('edit'))}
                                    className="w-full"
                                >
                                    Update
                                    <span className="hidden sm:inline">
                                        &nbsp;Quote
                                    </span>
                                </Button>
                            )}
                            {isRevisionAllowed && (
                                <Button
                                    variant="outlined"
                                    onClick={() => dispatch(setQuoteMode('revise'))}
                                    disabled={isLoading}
                                    className="w-full"
                                >
                                    Revise
                                    <span className="hidden sm:inline">
                                        &nbsp;Quote
                                    </span>
                                </Button>
                            )}
                        </div>
                    </div>
                    <Separator className="block sm:hidden" />
                    <OrderQuoteBreakdown quote={activeQuote} />
                </div>
            ) : isStandardQuote ? (
                <QuoteStandard mode={quoteMode} submittedQuote={activeQuote} />
            ) : (
                <QuoteAllIn mode={quoteMode} submittedQuote={activeQuote} />
            )}
        </div>
    )
}
