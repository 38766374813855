import React, { FC } from 'react'
import { InArrowLeft } from '@/shared/ui/Icons/InArrowLeft'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { OrderChat } from '@/pages/Order/ui/OrderChat'
import { ChatSelector } from '@/pages/PSM/OrderChat/ChatSelector'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/app/store'

export const PSMOrderChat: FC = () => {
    const params = useParams()
    console.log(params)
    const rfqId = params.key as string
    const appDispatch = useAppDispatch()
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const currentChat = useAppSelector((state) => state.orderChat.currentChat)
    console.log(selectedChat, currentChat)
    return (
        <>
            {(currentChat || selectedChat) && (
                <div
                    className="flex justify-center items-center h-12 bg-primary text-white cursor-pointer hover:bg-primary/90"
                    onClick={() => {
                        appDispatch(orderChatActions.selectChat(undefined))
                        appDispatch(orderChatActions.setCurrentChat(undefined))
                    }}
                >
                    {/*make back icon*/}
                    <InArrowLeft className="w-6 h-6 mr-2" />
                    Chat with {currentChat?.account?.name ?? (selectedChat === 'DEMAND_PSM' ? 'Demand' : 'Supply')}
                </div>
            )}

            <div className={'flex justify-start align-center relative h-[550px] border-t border-t-gray-3'}>
                {!selectedChat && <ChatSelector />}

                {rfqId && (
                    <OrderChat
                        psmMode
                        reference={rfqId}
                        className="border-0 border-l rounded-none !shadow-none"
                        hide={!selectedChat}
                    />
                )}
            </div>
        </>
    )
}
