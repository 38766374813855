import React from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { useAppSelector } from '@/app/store'
import { UserRole } from '@/store/user/user'
import { useUserState } from '@/store/user'
import { Badge } from '@/shared/ui/Badge'
import { OrderNotInterestedModal } from '@/pages/Order/ui/OrderDetails/ui/OrderNotInterestedModal'
import { OrderStatusCode } from '@shared/types/order'
import { capitalize } from 'lodash'

export const OrderDetailsStatus = () => {
    const { state: userState } = useUserState()

    const components: Partial<Record<UserRole, any>> = {
        [UserRole.WAREHOUSE_MANAGER]: <OrderDetailsSupplyStatus />,
        [UserRole.FREIGHT_BROKER_MANAGER]: <OrderDetailsDemandStatus />,
    }

    return components[userState.userInfo?.role ?? UserRole.UNKNOWN] ?? null
}

export const OrderDetailsDemandStatus = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const status = order.status as OrderStatusCode

    if (
        status === OrderStatusCode.BOOKED ||
        status === OrderStatusCode.CONFIRMED ||
        status === OrderStatusCode.DROPPED
    ) {
        return <Badge>{capitalize(OrderStatusCode[status])}</Badge>
    }

    if (status === OrderStatusCode.LTW_CANCELED) {
        return <Badge variant="danger">Canceled</Badge>
    }

    if (status === OrderStatusCode.PENDING) {
        return <Badge variant="warning">{capitalize(OrderStatusCode[status])}</Badge>
    }

    if (status === OrderStatusCode.FINISHED || OrderStatusCode.COMPLETED) {
        return <Badge variant="success">{capitalize(OrderStatusCode[status])}</Badge>
    }

    return <Badge variant="secondary">Unknown</Badge>
}

export const OrderDetailsSupplyStatus = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    if (order.status < LoadboardOrderStatus.CLOSED) {
        return (
            <div className="flex flex-col sm:flex-row gap-3 items-end sm:items-center">
                {order.status === LoadboardOrderStatus.ACTIVE && !order.notInterested && <OrderNotInterestedModal />}
                <Badge variant="success">Open</Badge>
            </div>
        )
    }

    return <Badge variant="secondary">Closed</Badge>
}
