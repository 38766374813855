export enum RfqCertification {
    FoodGrade = 'Food Grade',
    FoodAIB = 'Food (AIB)',
    Organic = 'Organic',
    Medical = 'Medical',
    FTZ = 'Foreign Trade Zone (FTZ)',
    TSAApproved = 'TSA Approved',
    Military = 'MIlitary',
    Hazmat = 'Hazmat',
    Bonded = 'Bonded',
    GDP = 'GDP',
    Alcohol = 'Alcohol',
}

export const RfqCertificationLabel = {
    [RfqCertification.FoodGrade]: 'Food Grade',
    [RfqCertification.FoodAIB]: 'Food (AIB)',
    [RfqCertification.Organic]: 'Organic',
    [RfqCertification.Medical]: 'Medical',
    [RfqCertification.FTZ]: 'Foreign Trade Zone (FTZ)',
    [RfqCertification.TSAApproved]: 'TSA Approved',
    [RfqCertification.Military]: 'Military',
    [RfqCertification.Hazmat]: 'Hazmat',
    [RfqCertification.Bonded]: 'Bonded',
    [RfqCertification.GDP]: 'GDP',
    [RfqCertification.Alcohol]: 'Alcohol',
}
