import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Section } from '@/components/Section'
import Search from '@/components/Search'
import Select from '@/pages/RFQPortal/components/Select'
import { useWHOrders, WarehouseOrderDto, RFQStatus } from '@shared/apiv2/wh-orders'
import { getOrdersColumns } from '@/pages/Warehouses/Orders/columns'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import { useParams } from 'react-router-dom'
import { Loader } from '@/components/Loader'
import { SortingState } from '@tanstack/react-table'
import { getEnumValueByKeyForRFQStatus } from '@/utils/getEnumValueByKey'
import useDebounce from '@shared/hooks/useDebounce'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const STATUS_OPTIONS = {
    0: 'All',
    [RFQStatus.Booked]: RFQStatus[RFQStatus.Booked],
    [RFQStatus.Dropped]: RFQStatus[RFQStatus.Dropped],
    [RFQStatus.Finished]: RFQStatus[RFQStatus.Finished],
}

const OrdersMain = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const { id } = useParams()

    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStatus, setSelectedStatus] = useState<RFQStatus | undefined>()
    const debouncedSearchValue = useDebounce(searchValue, 300) as string

    const onSelectedStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === 'All') {
            setSelectedStatus(undefined)
        }
        const status = getEnumValueByKeyForRFQStatus(e.target.value) as RFQStatus
        setSelectedStatus(status)

        sendCustomEvent('orders_status_changed', {
            from: selectedStatus,
            to: status,
        })
    }

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const [sorting, setSorting] = useState<SortingState>([])

    const onSortingChange = (s: SortingState) => {
        setPagination({ ...pagination, pageIndex: 0 })
        setSorting(s)
    }

    const {
        data: orders,
        mutate,
        isValidating,
    } = useWHOrders(id ?? '', {
        pagination: pagination,
        filters: {
            search: debouncedSearchValue,
            status: selectedStatus,
        },
        sorting: sorting[0],
    })

    const columns = getOrdersColumns(id, mutate, sendCustomEvent)

    return (
        <main className="flex-grow">
            <Section className="flex flex-col gap-3">
                <div className="flex gap-5 border-b pb-2">
                    <h2 className="font-bold text-2xl shrink-0">Orders</h2>
                    <Search
                        label="Search"
                        filterValue={searchValue}
                        onSearch={(v) => setSearchValue(v)}
                        wrapperClassName="flex-grow basis-0"
                        onBlur={() =>
                            debouncedSearchValue &&
                            sendCustomEvent('orders_invoices_search', { search_param: debouncedSearchValue })
                        }
                    />
                    <Select
                        id="ordersSelect"
                        label="Status"
                        options={STATUS_OPTIONS}
                        value={selectedStatus && RFQStatus[selectedStatus]}
                        onChange={onSelectedStatusChange}
                        wrapperClassName="flex-grow basis-0"
                    />
                </div>

                {isValidating || !orders ? (
                    <Loader />
                ) : (
                    <ModernTable<WarehouseOrderDto>
                        data={orders.data}
                        total={orders.count}
                        columns={columns}
                        pagination={pagination}
                        onPaginationChange={(updater) => setPagination(updater)}
                        sorting={sorting}
                        setSorting={onSortingChange}
                    />
                )}
            </Section>
        </main>
    )
}

export default OrdersMain
