import { NotificationDto } from '@/entities/notifications/model/dto'
import { NotificationActions, NotificationEvents } from '@/entities/notifications/model/enums'
import { IONamespace, useSocket, useSocketSubscriptions } from '@/hooks/useSocket'

type OnNotificationsLoadedResponse = { notifications: NotificationDto[]; hasMore: boolean; unread: number | null }

export interface NotificationOptions {
    callbacks?: {
        onNotificationReceived: (notification: NotificationDto) => void
        onNotificationsLoaded: (data: OnNotificationsLoadedResponse) => void
    }
}

export const useNotifications = ({ callbacks }: NotificationOptions) => {
    const { socket, connected, error } = useSocket(IONamespace.Notifications)

    const markSeen = (notificationId: number) => {
        if (connected) {
            socket!.emit(NotificationActions.NotificationSeen, notificationId)
        }
    }
    const markAllSeen = () => {
        if (connected) {
            socket!.emit(NotificationActions.NotificationAllSeen)
        }
    }

    const getNotifications = (skip = 0) => {
        if (connected) {
            socket!.emit(NotificationActions.GetNotifications, skip)
        }
    }

    useSocketSubscriptions(socket, [
        { event: NotificationEvents.NotificationReceived, callback: callbacks?.onNotificationReceived },
        { event: NotificationEvents.GetNotifications, callback: callbacks?.onNotificationsLoaded },
    ])

    return {
        getNotifications,
        markSeen,
        markAllSeen,
        connected,
        error,
    }
}
